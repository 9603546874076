import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
    EventRegistrationSerializer
} from "types/api";

export type EventRegistrationsState = Record<number, EventRegistrationSerializer[]>

const initialState: EventRegistrationsState = {};

export type EventRegistrationsSetPayload = {
    id: number;
    data: EventRegistrationSerializer[];
}

export const eventRegistrationsStateSlice = createSlice({
    name: 'eventRegistrations',
    initialState,
    reducers: {
        set: (state,  action: PayloadAction<EventRegistrationsSetPayload>) => {
            state[action.payload.id] = action.payload.data;

            return state;
        },
    },
})

// Action creators are generated for each case reducer function
export const { set } = eventRegistrationsStateSlice.actions

export default eventRegistrationsStateSlice.reducer