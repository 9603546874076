import ReactMarkdown from 'react-markdown'
import {useParams} from "react-router-dom";

import {data} from 'components/NewsArticles/data'
import {NotFound} from "pages/NotFound/NotFound";
import styles from './Article.module.scss';
type Params = {
    id: string;
}

export const Article = () => {
    const {id} = useParams<Params>();

    if (!id) {
        return null;
    }

    const article = data[Number(id)];

    if (!article) {
        return <NotFound />;
    }

    return (
        <div className={styles.back}>
            <div className={styles.block}>
                <div className={styles.header}>
                    {article.title}
                </div>

                <div className={styles.date}>
                    {article.date}
                </div>

                {(article.photo && (
                    <img
                        src={article.photo}
                        className={styles.photo}
                        alt=""
                    />
                ))}

                <ReactMarkdown className={styles.content}>
                    {article.full}
                </ReactMarkdown>
            </div>
        </div>
    );
}