export const data = [
    {
        title: 'Мероприятия на Главной сцене',
        date: '27.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-011f.jpg',
        link: 'https://disk.yandex.ru/d/jOo3MIjoOeH7CQ/27%20августа/Все%20фото/Сцена',
    },
    {
        title: 'Экскурсии по территории ВДНХ',
        date: '27.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-010f.jpg',
        link: 'https://disk.yandex.ru/d/jOo3MIjoOeH7CQ/27%20августа/Все%20фото/Экскурсии',
    },
    {
        title: 'Мероприятия и активности в павильоне 57',
        date: '27.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-009f.jpg',
        link: 'https://disk.yandex.ru/d/jOo3MIjoOeH7CQ/27%20августа/Все%20фото/57%20пав',
    },
    {
        title: 'Мероприятия и активности в ДК ВДНХ',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-008f.jpg',
        link: 'https://disk.yandex.ru/d/eo5iBAMPkOhjgg',
    },
    {
        title: 'Двусторонняя встреча с делегацией Ирана',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-007f.jpg',
        link: 'https://disk.yandex.ru/d/jO5t4L9hyFPj9w',
    },
    {
        title: 'Мероприятия на Главной сцене. День 2.',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-006f.jpg',
        link: 'https://disk.yandex.ru/d/c3C1LbT3nHqHDg',
    },
    {
        title: 'Мероприятия и активности в павильоне 57. День 2.',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-005f.jpg',
        link: 'https://disk.yandex.ru/d/o6XqnXXDQnUqJw',
    },
    {
        title: 'Мероприятия на Главной сцене форума',
        date: '25.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-001f.jpg',
        link: 'https://disk.yandex.ru/d/Zy1uaXvv4M0BGg',
    },
    {
        title: 'Мероприятия и активности в павильоне 57',
        date: '25.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-004f.jpg',
        link: 'https://disk.yandex.ru/d/7s6tuHFO-UPeFw',
    },
    {
        title: 'Мастер-классы на Киностудии Горького',
        date: '25.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-003f.jpg',
        link: 'https://disk.yandex.ru/d/oNB6fnAGJLCxig',
    },
    {
        title: 'Мастер-классы и мероприятия в ДК ВДНХ',
        date: '25.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-002f.jpg',
        link: ' https://disk.yandex.ru/d/6UeOZ6xpt7xz1w',
    },

];