import styles from './StreamSchedule.module.scss';
import {data} from './data';
import back from './back.svg';
import cx from "classnames";

export const StreamSchedule = () => {
    return (
        <div className={styles.block}>
            <div className={styles.header}>
                Расписание зала # 1
            </div>

            <div className={styles.days}>
                {data.map((day, i) => {
                    return (
                        <div
                            key={i}
                            className={cx(styles.day, {[styles.finished]: day.finished})}
                        >
                            <div className={styles.date}>
                                {day.date}
                            </div>

                            <div className={styles.events}>
                                {day.items.map((event, j) => {
                                    return (
                                        <div
                                            className={styles.event}
                                            key={j}
                                        >
                                            <div className={styles.time}>
                                                {event.time}
                                            </div>
                                            <div className={styles.title}>
                                                {event.title}
                                            </div>
                                            <div className={styles.text}>
                                                {event.text}
                                            </div>

                                            {Boolean(event.link) && (
                                                <a
                                                    target="_blank"
                                                    href={event.link}
                                                    className={styles.link}
                                                >
                                                    Смотреть
                                                </a>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>

            <img
                alt=""
                src={back}
                className={styles.back}
            />
        </div>
    );
}