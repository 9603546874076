import {useMemo} from "react";

import {EventRegistrationSerializer, ProfileSerializer} from "types/api";
import {UserState} from "reducers/user";

type data = {
    registrationsMap: Record<number, EventRegistrationSerializer>;
    usersMap: Record<number, ProfileSerializer>;
}

export const usePrepareData = (user:UserState, users?: ProfileSerializer[], registrations?: EventRegistrationSerializer[]) => {
    return useMemo(() => {
        const result: data = {
            registrationsMap: {},
            usersMap: {},
        };

        if (!user.id || !users || !registrations) {
            return result;
        }

        users.forEach((usersObject) => {
            if (usersObject.id) {
                result.usersMap[usersObject.id] = usersObject;
            }
        });

        registrations.forEach((registration) => {
            if (registration.user) {
                result.registrationsMap[registration.user] = registration;
            }
        });

        return result;
    }, [user, users, registrations]);
}