import {useEffect, useMemo} from "react";
import {useSelector} from "react-redux";

import {useRegistrations} from "../../hooks/useRegistarations";
import {RootState} from "../../containers/Wrappers";
import {Content} from "../../components/Content/Content";
import {ProfileSerializer} from "../../types/api";

import {Region} from "./Region";
import styles from './Registrations.module.scss';

export const Registrations = () => {
    const loadData = useRegistrations();

    useEffect(() => {
        loadData();
    }, []);

    const users = useSelector(
        (state: RootState) => state.users[0]
    );

    const registrations = useSelector(
        (state: RootState) => state.eventRegistrations[0]
    );

    const registrationsByUser = useMemo(() => {
        const result = {};

        if (!registrations) {
            return result;
        }

        registrations.forEach((registration) => {
            // @ts-ignore
            result[registration.user] = registration;
        })

        return result;
    }, [users, registrations]);

    const userById = useMemo(() => {
        const result: Record<number, ProfileSerializer> = {};

        if (!users) {
            return result;
        }

        users.forEach((user) => {
            result[user.id || 0] = user;
        })

        return result;
    }, [users, registrations]);

    const usersByManager = useMemo(() => {
        const result: Partial<Record<number | 'null', number[]>> = {};

        if (!users) {
            return result;
        }

        users.forEach((user) => {
            // @ts-ignore
            if (!result[user.supervisor]) {
                // @ts-ignore
                result[user.supervisor] = [];
            }
            // @ts-ignore
            result[user.supervisor].push(user.id);
        })

        if (result['null']) {
            result['null'].sort((a,b) => {
                return (userById[a].organization?.external_id || 1000) - (userById[b].organization?.external_id || 1000)
            });
        }

        return result;
    }, [users, registrations]);

    if (!users || !registrations) {
        return null;
    }

    const managers = usersByManager['null'] || [];

    return (
        <Content>
            <table className={styles.block}>
                <thead>
                    <tr>
                        <th>
                            Группа
                        </th>
                        <th>
                            userId
                        </th>
                        <th>
                            registrationId
                        </th>
                        <th>
                            Регион
                        </th>
                        <th>
                            Лимит
                        </th>
                        <th>
                            Заявок
                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody>
                {managers.map((user, i) => {
                    return (
                        <Region
                            key={i}
                            managerId={user}
                            registrationsByUser={registrationsByUser}
                            userById={userById}
                            userIds={usersByManager[user] || []}
                        />
                    );
                })}
                </tbody>
            </table>
        </Content>
    );
}