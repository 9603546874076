import cx from "classnames";
import {useTranslation} from "react-i18next";

import styles from './Map.module.scss';
import desktopMap from './desktop.svg';

export const Map = () => {
    const { t } = useTranslation();
    const items = t('program.items', { returnObjects: true });

    return (
        <div className={styles.block} id="schedule">
            <img src={desktopMap} className={cx(styles.map, styles.desktopMap)} alt="map" />

            <div className={styles.header}>
                {t('program.header')}
            </div>

            <a
                className={styles.download}
                href="https://disk.yandex.ru/d/Nqgvqv0Qx1G0gA"
                target="_blank"
            >
                Скачать программу
            </a>

            {/*@ts-ignore*/}
            {items.map((item, i) => {
                return (
                    <div className={styles[`item${i + 1}`]} key={i}>
                        <div className={styles.title}>{item.title}</div>
                        <div className={styles.text}>{item.text}</div>
                    </div>
                );
            })}
        </div>
    );
}