import {FC} from "react";
import cx from "classnames";

import {Highlight} from "components/Highlight/Highlight";

import styles from './Star.module.scss';

type Props = {
    header: string;
    title: string;
    subtitle?: string;
    className?: string;
    type?: 'small' | 'mixed' | 'normal';
}

export const Star: FC<Props> = ({header, title, subtitle, className, type = 'normal'}) => {
    return (
        <div
            className={cx(styles.block, className, styles[type])}
        >
            <Highlight>
                {header}
            </Highlight>

            <div className={styles.title}>
                {title}
            </div>

            <div className={styles.subtitle}>
                {subtitle}
            </div>
        </div>
    );
}