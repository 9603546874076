export const data = [
    {
        title: 'Газета Культура',
        date: '27.08.2022  20:14',
        logo: '',
        text: 'Урок русского жестового языка прошел на Детском культурном форуме',
        link: 'https://portal-kultura.ru/articles/news/344306-urok-russkogo-zhestovogo-yazyka-proshel-na-detskom-kulturnom-forume/',
    },
    {
        title: 'Российская газета',
        date: '27.08.2022  14:57',
        logo: '',
        text: 'Можно ли будет купить по «Пушкинской карте» и книги?',
        link: 'https://rg.ru/2022/08/27/mozhno-li-budet-kupit-po-pushkinskoj-karte-i-knigi.html',
    },
    {
        title: 'ТАСС',
        date: '27.08.2022  14:18',
        logo: '',
        text: 'В России разрабатывают аналогичную Пушкинской карте программу покупки книг',
        link: 'https://tass.ru/kultura/15577867',
    },
    {
        title: 'Коммерсантъ',
        date: '27.08.2022  13:55',
        logo: '',
        text: '«Пушкинскую карту» оформили половина россиян в возрасте от 14 до 22 лет',
        link: 'https://www.kommersant.ru/doc/5535133',
    },
    {
        title: 'ТАСС',
        date: '26.08.2022  18:30',
        logo: '',
        text: '«Союзмультфильм» разрабатывает два сериала для подростков',
        link: 'https://tass.ru/kultura/15573563',
    },
    {
        title: 'ТАСС',
        date: '26.08.2022  16:30',
        logo: '',
        text: 'Ректор Гнесинки назвал гобой, валторну и фагот исчезающими инструментами в регионах',
        link: 'https://tass.ru/kultura/15572139',
    },
    {
        title: 'Газета.ru',
        date: '26.08.2022  15:55',
        logo: '',
        text: 'Башмет назвал базовое музыкальное образование необходимым для всех',
        link: 'https://www.gazeta.ru/culture/news/2022/08/26/18418196.shtml',
    },
    {
        title: 'Газета Культура',
        date: '26.08.2022  14:26',
        logo: '',
        text: 'В Минкультуры подвели итоги первого дня Детского культурного форума',
        link: 'https://portal-kultura.ru/articles/news/344273-v-minkultury-podvedi-itogi-pervogo-dnya-detskogo-kulturnogo-foruma/',
    },
    {
        title: 'Love radio',
        date: '25.08.2022  22:35',
        logo: '',
        text: 'Первый Детский культурный форум пройдет в Москве',
        link: 'https://www.loveradio.ru/news/news/pervyj-detskij-kulturnyj-forum-projdet-v-moskve',
    },
    {
        title: 'Телеканал Вместе-рф',
        date: '25.08.2022  18:09',
        logo: '',
        text: 'Международный Детский культурный форум открылся в Москве',
        link: 'https://vmeste-rf.tv/news/mezhdunarodnyy-detskiy-kulturnyy-forum-otkrylsya-v-moskve-/',
    },
    {
        title: 'НТВ',
        date: '25.08.2022  17:36',
        logo: '',
        text: 'На ВДНХ открылся Детский культурный форум',
        link: 'https://www.ntv.ru/msk/novosti/2720820/',
    },
    {
        title: 'Телеканал «Россия.Культура»',
        date: '25.08.2022  19:45',
        logo: '',
        text: 'В Москве впервые проходит международный детский культурный форум',
        link: 'https://smotrim.ru/article/2909692?utm_source=yxnews&utm_medium=desktop',
    },
    {
        title: 'Телеканал «Карусель»',
        date: '25.08.2022  16:50',
        logo: '',
        text: 'В Москве открылся Международный детский культурный форум',
        link: 'https://www.karusel-tv.ru/news/8899',
    },
    {
        title: 'АГН «Москва»',
        date: '25.08.2022',
        logo: '',
        text: 'Международный детский культурный форум станет ежегодным',
        link: 'https://www.mskagency.ru/materials/3237947?utm_source=yxnews&utm_medium=desktop',
    },
    {
        title: 'Интерфакс',
        date: '25.08.2022',
        logo: '',
        text: 'В Москве открылся Международный детский культурный форум',
        link: 'https://www.interfax-russia.ru/moscow/news/v-moskve-otkrylsya-mezhdunarodnyy-detskiy-kulturnyy-forum?utm_source=yxnews&utm_medium=desktop',
    },
    {
        title: 'Sputnik. Узбекистан',
        date: '25.08.2022',
        logo: '',
        text: 'В Москве открылся Детский культурный форум с участием Узбекистана',
        link: 'https://uz.sputniknews.ru/20220825/uzbekistanskaya-delegatsiya-uchastvuet-v-detskom-kulturnom-forume-v-moskve-27469638.html?utm_source=yxnews&utm_medium=desktop',
    },
    {
        title: 'ОТР',
        date: '25.08.2022',
        logo: '',
        text: 'Международный детский культурный форум открылся в Москве',
        link: 'https://otr-online.ru/news/mezhdunarodnyy-detskiy-kulturnyy-forum-otkrylsya-v-moskve-203175.html?utm_source=yxnews&utm_medium=desktop',
    },
    {
        title: '1 Канал',
        date: '25.08.2022',
        logo: '',
        text: 'В Москве на ВДНХ открылся первый детский культурный форум',
        link: 'https://www.1tv.ru/news/2022-08-25/436465-v_moskve_na_vdnh_otkrylsya_pervyy_detskiy_kulturnyy_forum?utm_source=yxnews&utm_medium=desktop',
    },
    {
        title: 'Газета «Культура»',
        date: '25.08.2022',
        logo: '',
        text: 'У детей появился свой культурный форум',
        link: 'https://portal-kultura.ru/articles/kulturnaya-politika/344233-u-detey-poyavilsya-svoy-kulturnyy-forum/?utm_source=yxnews&utm_medium=desktop&utm_referrer=https%3A%2F%2Fyandex.ru%2Fnews%2Fsearch%3Ftext%3D',
    },
    {
        title: 'ТАСС',
        date: '25.08.2022  11:00',
        logo: '',
        text: 'Детский культурный форум открылся в Москве',
        link: 'https://tass.ru/obschestvo/15559417?utm_source=yxnews&utm_medium=desktop&utm_referrer=https%3A%2F%2Fyandex.ru%2Fnews%2Fsearch%3Ftext%3D',
    },
    {
        title: 'ТАСС',
        date: '25.08.2022',
        logo: '',
        text: 'В Москве более 1,5 тыс. одаренных детей соберутся на Детском культурном форуме',
        link: 'https://tass.ru/kultura/15556987',
    },
    {
        title: 'Радио классической музыки «Орфей»',
        date: '25.08.2022',
        logo: '',
        text: 'Детский культурный форум начинает работу в Москве',
        link: 'https://orpheusradio.ru/news/news/37980/detskiy-kulturnyy-forum-nachinaet-rabotu-v-moskve',
    },
    {
        title: 'Вечерняя Москва',
        date: '24.08.2022  20:52',
        logo: '',
        text: 'Культурное воспитание детям: Москва стала местом притяжения юных творческих талантов со всей страны и из-за рубежа',
        link: 'https://vm.ru/society/991051-kulturnoe-vospitanie-detyam-moskva-stala-mestom-prityazheniya-yunyh-tvorcheskih-talantov-so-vsej-strany-i-iz-za-rubezha',
    },
    {
        title: 'Радио 1',
        date: '24.08.2022  15:00',
        logo: '',
        text: 'Диалог с министром культуры и арт-школы. Чем ещё удивит Детский культурный форум',
        link: 'https://radio1.news/article/dialog-s-ministrom-kultury-i-art-shkoly-chem-eshchye-udivit-detskiy-kulturnyy-forum/',
    },
    {
        title: 'Телеканал Россия Культура',
        date: '23.08.2022  19:58',
        logo: '',
        text: 'Беседа с Анатолием Кицурой',
        link: 'https://smotrim.ru/video/2465462',
    },
    {
        title: 'ТАСС',
        date: '23.08.2022  19:38',
        logo: '',
        text: 'В Москве пройдет детский культурный форум',
        link: 'https://tass.ru/obschestvo/15543887',
    },
    {
        title: 'Телеканал 360',
        date: '23.08.2022  11:20',
        logo: '',
        text: 'Подмосковье отправит 7 участников на Детский культурный форум в августе',
        link: 'https://360tv.ru/news/mosobl/podmoskove-otpravit-7-uchastnikov-na-detskij-kulturnyj-forum-v-avguste/',
    },
    {
        title: 'Телеканал Россия Культура',
        date: '23.08.2022  9:24',
        logo: '',
        text: 'Международный детский культурный форум состоится в Москве',
        link: 'https://smotrim.ru/article/2904394',
    },
    {
        title: 'Газета Культура',
        date: '22.08.2022  17:43',
        logo: '',
        text: 'Детский культурный форум пройдет в Москве с 25 по 27 августа',
        link: 'https://portal-kultura.ru/articles/news/344130-luchshiy-proekt-na-detskom-kulturnom-forume-poluchit-do-3-mln-rubley-podderzhki/',
    },
    {
        title: 'Вечерняя Москва',
        date: '22.08.2022  17:33',
        logo: '',
        text: 'Детский культурный форум пройдет в Москве с 25 по 27 августа',
        link: 'https://vm.ru/news/990431-luchshij-proekt-na-detskom-kulturnom-forume-poluchit-gospodderzhku-do-treh-millionov-rublej',
    },
    {
        title: 'Культура.рф',
        date: '22.08.2022  15:51',
        logo: '',
        text: '25 августа в Москве откроется Детский культурный форум',
        link: 'https://www.culture.ru/news/257323/25-avgusta-v-moskve-otkroetsya-detskii-kulturnyi-forum',
    },
    {
        title: 'Интерфакс – Россия',
        date: '22.08.2022  15:34',
        logo: '',
        text: 'Победитель первого Детского культурного форума получит господдержку до 3 млн рублей',
        link: 'https://www.interfax-russia.ru/moscow/news/pobeditel-pervogo-detskogo-kulturnogo-foruma-poluchit-gospodderzhku-do-3-mln-rubley',
    },
    {
        title: 'ТАСС',
        date: '22.08.2022  15:26',
        logo: '',
        text: 'Лучший проект на детском культурном форуме получит до 3 млн рублей господдержки',
        link: 'https://tass.ru/kultura/15532151',
    },
    {
        title: 'Газета Культура',
        date: '11.08.2022  21:07',
        logo: '',
        text: 'На Детском культурном форуме выступят Нонна Гришаева и Константин Хабенский',
        link: 'https://portal-kultura.ru/articles/news/343853-na-detskom-kulturnom-forume-vystupyat-nonna-grishaeva-i-konstantin-khabenskiy',
    },
    {
        title: 'ТАСС',
        date: '11.08.2022  20:01',
        logo: '',
        text: 'Хабенский, Гришаева, Олешко и Безруков станут спикерами Детского культурного форума',
        link: 'https://tass.ru/kultura/15452219',
    },
    {
        title: 'Интерфакс - Россия',
        date: '10.08.2022 16:33',
        logo: '',
        text: 'Минкультуры: более 60 мероприятий пройдет в рамках Детского культурного форума в Москве',
        link: 'https://www.interfax-russia.ru/moscow/news/minkultury-bolee-60-meropriyatiy-proydet-v-ramkah-detskogo-kulturnogo-foruma-v-moskve',
    },
    {
        title: 'ТАСС',
        date: '10.08.2022 11:44',
        logo: '',
        text: '«Союзмультфильм» и Киностудия Горького представят на Детском форуме тематические программы',
        link: 'https://tass.ru/kultura/15438051',
    },
    {
        title: 'Агентство Москва',
        date: '21.07.2022 20:58',
        logo: '',
        text: 'Более 60 мероприятий пройдет в рамках Детского культурного форума в столице',
        link: 'https://www.mskagency.ru/materials/3229459',
    },
];