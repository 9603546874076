import cx from "classnames";
import {useTranslation} from "react-i18next";

import styles from './Schedule.module.scss';

export const Schedule = () => {
    const {i18n } = useTranslation();
    const isRu = i18n.language == 'ru';

    if (!isRu) {
        return null;
    }

    return (
        <div className={styles.block}>
            <div className={styles.header}>
                Архитектура форума
            </div>

            <div className={cx(styles.day, styles.day1)}>
                <div className={styles.dayNumber}>
                    24
                </div>
                <div className={styles.dayMonth}>
                    августа
                </div>
                <div className={styles.dayInWeek}>
                    среда
                </div>
            </div>

            <div className={cx(styles.day, styles.dayBig, styles.day2)}>
                <div className={styles.dayNumber}>
                    25
                </div>
                <div className={styles.dayMonth}>
                    августа
                </div>
                <div className={styles.dayInWeek}>
                    четверг
                </div>
            </div>

            <div className={cx(styles.day, styles.dayBig, styles.day3)}>
                <div className={styles.dayNumber}>
                    26
                </div>
                <div className={styles.dayMonth}>
                    августа
                </div>
                <div className={styles.dayInWeek}>
                    пятница
                </div>
            </div>

            <div className={cx(styles.day, styles.dayBig, styles.day4)}>
                <div className={styles.dayNumber}>
                    27
                </div>
                <div className={styles.dayMonth}>
                    августа
                </div>
                <div className={styles.dayInWeek}>
                    суббота
                </div>
            </div>

            <div className={cx(styles.day, styles.day5)}>
                <div className={styles.dayNumber}>
                    28
                </div>
                <div className={styles.dayMonth}>
                    августа
                </div>
                <div className={styles.dayInWeek}>
                    воскресенье
                </div>
            </div>

            <div className={cx(styles.box, styles.in)}>
                Приезд участников из субъектов РФ, заселение в гостиницу
            </div>

            <div className={cx(styles.box, styles.out)}>
                Отъезд участников
            </div>

            <div className={cx(styles.box, styles.boxTitle, styles.learning)}>
                Просветительская программа
            </div>

            <div className={cx(styles.box, styles.boxLearning, styles.opening)}>
                Открывающая сессия
            </div>

            <div className={cx(styles.box, styles.boxLearning, styles.dialog)}>
                Открытый диалог с Министром культуры РФ
            </div>

            <div className={cx(styles.box, styles.boxLearning, styles.learningSchedule1)}>
                <ul>
                    <li>
                        Творческие встречи
                    </li>
                    <li>
                        Мастер-классы
                    </li>
                    <li>
                        Диалоги с деятелями культуры
                    </li>
                    <li>
                        Работа Детского пресс-центра
                    </li>
                </ul>
            </div>

            <div className={cx(styles.box, styles.boxLearning, styles.learningSchedule2)}>
                <ul>
                    <li>
                        Творческие встречи
                    </li>

                    <li>
                        Диалоги с деятелями культуры
                    </li>

                    <li>
                        Мастер-классы
                    </li>

                    <li>
                        Работа Детского<br/>пресс-центра
                    </li>
                </ul>
            </div>

            <div className={cx(styles.box, styles.boxFestival, styles.festivalSchedule1)}>
                <ul>
                    <li>
                        Арт-школы по творческим направлениям
                    </li>
                    <li>
                        Объединяющая активность
                    </li>
                    <li>
                        Творческие<br/>
                        мастер-классы
                    </li>
                    <li>
                        Экскурсии
                    </li>
                    <li>
                        Знакомство и обмен опытом со сверстниками
                    </li>
                </ul>
            </div>

            <div className={cx(styles.box, styles.boxFestival, styles.festivalSchedule2)}>
                <ul>
                    <li>
                        Выступление творческих коллективов
                    </li>
                    <li>
                        Концертная программа на сцене
                    </li>
                </ul>
            </div>

            <div className={cx(styles.box, styles.boxFestival, styles.show)}>
                Шоу программа<br />от киностудии <br />Союзмультфильм <br />на сцене
            </div>

            <div className={cx(styles.box, styles.boxTitle, styles.festival)}>
                Фестивальная программа
            </div>

            <div className={cx(styles.line, styles.line1)} />
            <div className={cx(styles.line, styles.line2)} />
            <div className={cx(styles.line, styles.line3)} />
            <div className={cx(styles.line, styles.line4)} />
            <div className={cx(styles.line, styles.line5)} />


            <div className={cx(styles.mobileDay, styles.mobileDayFirst)}>
                <div className={cx(styles.mobileDate)}>
                    24 августа
                </div>
                <div className={cx(styles.mobileWeekDay)}>
                    среда
                </div>
                <div className={cx(styles.mobileElement)}>
                    Приезд участников из субъектов РФ, заселение в гостиницу
                </div>
            </div>

            <div className={cx(styles.mobileDay)}>
                <div className={cx(styles.mobileDate)}>
                    25 августа
                </div>
                <div className={cx(styles.mobileWeekDay)}>
                    четверг
                </div>
                <div className={cx(styles.mobileElement, styles.mobileLearningHeader)}>
                    Просветительская программа
                </div>

                <div className={cx(styles.mobileElement, styles.mobileLearningElements)}>
                    <ul>
                        <li>
                            Открывающая сессия
                        </li>
                        <li>
                            Творческие встречи
                        </li>
                        <li>
                            Мастер-классы
                        </li>
                        <li>
                            Диалоги с деятелями культуры
                        </li>
                        <li>
                            Работа Детского пресс-центра
                        </li>
                    </ul>
                </div>

                <div className={cx(styles.mobileElement, styles.mobileLearningHeader, styles.mobileFestivalHeader)}>
                    Фестивальная программа
                </div>

                <div className={cx(styles.mobileElement, styles.mobileLearningElements, styles.mobileFestivalElements)}>
                    <ul>
                        <li>
                            Арт-школы по творческим направлениям
                        </li>
                        <li>
                            Творческие мастер-классы
                        </li>
                        <li>
                            Знакомство и обмен опытом с сверстниками
                        </li>
                        <li>
                            Объединяющая активность
                        </li>
                        <li>
                            Экскурсии
                        </li>
                        <li>
                            Выступление творческих коллективов
                        </li>
                        <li>
                            Концертная программа на сцене
                        </li>
                    </ul>
                </div>
            </div>

            <div className={cx(styles.mobileDay)}>
                <div className={cx(styles.mobileDate)}>
                    26 августа
                </div>
                <div className={cx(styles.mobileWeekDay)}>
                    пятница
                </div>
                <div className={cx(styles.mobileElement, styles.mobileLearningHeader)}>
                    Просветительская программа
                </div>

                <div className={cx(styles.mobileElement, styles.mobileLearningElements)}>
                    <ul>
                        <li>
                            Открытый диалог с Министром культуры РФ
                        </li>
                        <li>
                            Творческие встречи
                        </li>
                        <li>
                            Мастер-классы
                        </li>
                        <li>
                            Диалоги с деятелями культуры
                        </li>
                        <li>
                            Работа Детского пресс-центра
                        </li>
                    </ul>
                </div>

                <div className={cx(styles.mobileElement, styles.mobileLearningHeader, styles.mobileFestivalHeader)}>
                    Фестивальная программа
                </div>

                <div className={cx(styles.mobileElement, styles.mobileLearningElements, styles.mobileFestivalElements)}>
                    <ul>
                        <li>
                            Арт-школы по творческим направлениям
                        </li>
                        <li>
                            Творческие мастер-классы
                        </li>
                        <li>
                            Знакомство и обмен опытом с сверстниками
                        </li>
                        <li>
                            Объединяющая активность
                        </li>
                        <li>
                            Экскурсии
                        </li>
                        <li>
                            Выступление творческих коллективов
                        </li>
                        <li>
                            Концертная программа на сцене
                        </li>
                    </ul>
                </div>
            </div>

            <div className={cx(styles.mobileDay)}>
                <div className={cx(styles.mobileDate)}>
                    27 августа
                </div>
                <div className={cx(styles.mobileWeekDay)}>
                    суббота
                </div>
                <div className={cx(styles.mobileElement, styles.mobileLearningHeader)}>
                    Просветительская программа
                </div>

                <div className={cx(styles.mobileElement, styles.mobileLearningElements)}>
                    <ul>
                        <li>
                            Открытый диалог с Министром культуры РФ
                        </li>
                        <li>
                            Творческие встречи
                        </li>
                        <li>
                            Мастер-классы
                        </li>
                        <li>
                            Диалоги с деятелями культуры
                        </li>
                        <li>
                            Работа Детского пресс-центра
                        </li>
                    </ul>
                </div>

                <div className={cx(styles.mobileElement, styles.mobileLearningHeader, styles.mobileFestivalHeader)}>
                    Фестивальная программа
                </div>

                <div className={cx(styles.mobileElement, styles.mobileLearningElements, styles.mobileFestivalElements)}>
                    <ul>
                        <li>
                            Арт-школы по творческим направлениям
                        </li>
                        <li>
                            Творческие мастер-классы
                        </li>
                        <li>
                            Знакомство и обмен опытом с сверстниками
                        </li>
                        <li>
                            Объединяющая активность
                        </li>
                        <li>
                            Экскурсии
                        </li>
                        <li>
                            Выступление творческих коллективов
                        </li>
                        <li>
                            Концертная программа на сцене
                        </li>
                        <li>
                            Шоу программа  от киностудии Союзмультфильм на сцене
                        </li>
                    </ul>
                </div>
            </div>

            <div className={cx(styles.mobileDay)}>
                <div className={cx(styles.mobileDate)}>
                    28 августа
                </div>
                <div className={cx(styles.mobileWeekDay)}>
                    воскресенье
                </div>
                <div className={cx(styles.mobileElement)}>
                    Отъезд участников
                </div>
            </div>
        </div>
    );
};
