import {useState, useEffect, useRef, useCallback} from "react";
import {useTranslation} from "react-i18next";
import ReactPlayer from 'react-player/file'

import styles from "./Video.module.scss";
import poster from "./spoiler_hor.jpg";
import posterV from "./spoiler_ver.jpg";

export const Video = () => {
    const { t, i18n } = useTranslation();
    const {width} = useWindowSize();

    const isRu = i18n.language == 'ru';
    const isDesktop = width && width >= 720;

    let videoUrl;

    if (isRu) {
        if (isDesktop) {
            videoUrl = "https://storage.yandexcloud.net/dkf/video/%D0%94%D0%B5%D1%82%D1%81%D0%BA%D0%B8%D0%B9%20%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D0%BD%D1%8B%D0%B9%20%D1%84%D0%BE%D1%80%D1%83%D0%BC%20(2).mp4";
        } else {
            videoUrl = "https://storage.yandexcloud.net/dkf/video/DKF%20(vertical)%20(no%20subtitles).mp4";
        }
    } else {
        if (isDesktop) {
            videoUrl = "https://storage.yandexcloud.net/dkf/video/DKF%20(horizontal)%20(subtitles).mp4";
        } else {
            videoUrl = "https://storage.yandexcloud.net/dkf/video/DKF%20(vertical)%20(subtitles).mp4";
        }
    }

    return (
        <div className={styles.block}>
            <div className={styles.header}>
                {t('video.header')}
            </div>
            <div className={styles.box}>
                <ReactPlayer
                    url={videoUrl}
                    controls
                    width="100%"
                    height="auto"
                    config={{
                        attributes: {
                            poster: isDesktop ? poster : posterV,
                        }
                    }}
                />

                <div className={styles.text}>
                    {t('video.text')}
                </div>
            </div>
        </div>
    );
}

interface Size {
    width: number | undefined;
    height: number | undefined;
}

function useWindowSize(): Size {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState<Size>({
        width: undefined,
        height: undefined,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
}