import {useTranslation} from "react-i18next";

import styles from './Commitee.module.scss';
import back1 from './back1.svg'
import back2 from './back2.svg'

export const Commitee = () => {
    const { t } = useTranslation();
    const items = t('committee.items', { returnObjects: true });

    return (
        <div className={styles.block}>
            <div className={styles.title}>
                {t('committee.title')}
            </div>

            <div className={styles.cards}>
                {/*@ts-ignore*/}
                {items.map((item, i) => {
                    return (
                        <div key={i} className={styles.card}>
                            <div className={styles.cardInner}>
                                <div className={styles.cardImageWrapper}>
                                    <img className={styles.cardImage} src={item.photo} alt="" />
                                </div>
                                <div className={styles.cardText}>
                                    <div className={styles.cardTitle}>
                                        {item.title}
                                    </div>
                                    <div className={styles.cardDescription}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>

            <img src={back1} className={styles.back1} />
            <img src={back2} className={styles.back2} />
        </div>
    );
}