import {FC} from "react";

import {Header} from "components/Header/Header";
import {Footer} from "components/Footer/Footer";

import styles from './Layout.module.scss';
import toTop from './button_top.svg';

export const Layout: FC = ({children}) => {
    return (
        <>
            <Header/>
            {children}
            <Footer />
            <img
                src={toTop}
                className={styles.toTop}
                onClick={() => window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                })}
                alt="to top"
            />
        </>
    );
}