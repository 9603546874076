import React, {useCallback, useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import { useDispatch } from 'react-redux'
import {Button, Container, Form, Row, Col} from "react-bootstrap";

import {Content} from "components/Content/Content";
import {RootState} from "containers/Wrappers";
import {useGetUserData} from "hooks/useGetUserData";
import config from "../../config";

export const Login = () => {
    const getUserInfo = useGetUserData();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');

    const onChangeEmail = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
        setEmailError('');
    }, []);

    const onChangePassword = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
        setPasswordError('');
    }, []);

    useEffect(() => {
        getUserInfo();
    }, []);

    const onLogin = useCallback(async (e) => {
        e.preventDefault();

        try {
            const resp = await fetch(config.api + 'auth/login/', {
                method: 'POST',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    password,
                }),
            });
            const data = await resp.json();

            if (resp.status !== 200) {
                if (data?.email) {
                    setEmailError(data.email.toString());
                }

                if (data?.password) {
                    setPasswordError(
                        data?.password?.toString()
                    );
                }

                if (data?.non_field_errors) {
                    setPasswordError(
                        data?.non_field_errors?.toString()
                    );
                }
            } else {
                await getUserInfo()
            }
        } catch (error) {
            console.log(error);
        }
    }, [email, password, dispatch]);

    const user = useSelector(
        (state: RootState) => state.user
    );

    if (user.loaded) {
        navigate('/form/', {replace: true});
    }

    return (
        <Content>
            <Form onSubmit={onLogin}>
                <Form.Group className="mb-3">
                    <Form.Label>
                        E-mail
                    </Form.Label>
                    <Form.Control
                        required
                        value={email}
                        onChange={onChangeEmail}
                        type="email"
                        isInvalid={Boolean(emailError)}
                    />
                    {Boolean(emailError) && (
                        <Form.Text className="invalid-feedback">
                            {emailError}
                        </Form.Text>
                    )}
                </Form.Group>

                <Form.Group className="mb-3">
                    <Form.Control
                        required
                        type="password"
                        placeholder="Пароль"
                        value={password}
                        onChange={onChangePassword}
                        isInvalid={Boolean(passwordError)}
                    />
                    {Boolean(passwordError) && (
                        <Form.Text className="invalid-feedback">
                            {passwordError}
                        </Form.Text>
                    )}
                </Form.Group>

                <div>
                    <Button
                        variant="primary"
                        type="submit"
                    >
                        Войти
                    </Button>
                </div>
            </Form>
        </Content>
    );
}