import cx from "classnames";
import {useTranslation} from "react-i18next";

import styles from './Organizer.module.scss'
import logo from './logo.svg'
import logoSmall from './logo2.svg'

export const Organizer = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.block}>
            <div className={styles.title}>
                {t('organizer.title')}
            </div>

            <div className={styles.paper}>
                <div className={cx(styles.cols, styles.top)}>
                    <img
                        src={logo}
                        className={styles.logo}
                    />
                    <div
                        className={styles.logoSmall}
                    >
                        <img
                            className={styles.logoSmallImage}
                            src={logoSmall}
                        />
                    </div>
                    <div className={styles.col}>
                        {t('organizer.text')}
                    </div>
                </div>
                <div className={cx(styles.cols, styles.bottom)}>
                    <div className={styles.col}>
                        <div className={styles.header}>
                            {t('organizer.header1')}
                        </div>
                        <div>
                            {t('organizer.text1')}
                        </div>
                    </div>
                    <div className={styles.col}>
                        <div className={styles.header}>
                            {t('organizer.header2')}
                        </div>
                        <div>
                            {t('organizer.text2')}
                        </div>

                        <a
                            className={styles.link}
                            href="https://culture.gov.ru">
                            culture.gov.ru
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
