import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {createAction} from "@reduxjs/toolkit";

import config from "config";
import {UserState} from "reducers/user";

const setUser = createAction<UserState>('user/set')

export const useGetUserData = () => {
    const dispatch = useDispatch();

    return useCallback(async () => {
        try {
            const resp = await fetch(config.api + 'me/', {
                credentials: 'include',
            });

            const data = await resp.json();

            if (resp.status === 200) {
                dispatch(setUser({
                    admin: Boolean(data.is_staff),
                    loaded: true,
                    fio: `${data.surname} ${data.name}`,
                    id: data.id,
                    error: false,
                }));
            } else {
                dispatch(setUser({
                    loaded: false,
                    admin: false,
                    error: true,
                    fio: '',
                    id: 0,
                }));
            }
        } catch (e) {
            console.log(e);
        }
    }, []);
};
