export const data = [
    {
        date: '25 августа',
        items: [
            {
                time: '11:00',
                text: 'Урок 1. История анимации. Создание оптической игрушки Тауматроп.',
                link: 'https://disk.yandex.ru/d/tjItk3oWjKUVfQ/МК%20СМФ%2025.08%2011.00.mp4',
                prepare: `
* клей-карандаш или ПВА 
* ленточка, толстая нитка или палочка 
* бумага акварельная или плотная, цветная бумага (по желанию)
* краски/кисти 
* клей/скотч бумажный или обычный (широкий)
* баночки для воды, салфетки
* ластик, карандаши
* ножницы, ножик канцелярский
* монетки (денежные) или железные грузики, клеющие подушечки (патафикс) если их нет подойдёт пластилин
* смартфон
* лего-конструктор
* лампа для света (можно обычные настольные лампы)
* подставка для книг или сами книги, для подставки под фон
                `
            },
            {
                time: '13:00',
                text: 'Урок 2. Принципы анимации. Создание мультстудии дома из подручных средств.',
                link: 'https://disk.yandex.ru/d/tjItk3oWjKUVfQ/МК%20СМФ%2025.08%2013.00.mp4',
                prepare: `
* клей-карандаш или ПВА 
* ленточка, толстая нитка или палочка 
* бумага акварельная или плотная, цветная бумага (по желанию)
* краски/кисти 
* клей/скотч бумажный или обычный (широкий)
* баночки для воды, салфетки
* ластик, карандаши
* ножницы, ножик канцелярский
* монетки (денежные) или железные грузики, клеющие подушечки (патафикс) если их нет подойдёт пластилин
* смартфон
* лего-конструктор
* лампа для света (можно обычные настольные лампы)
* подставка для книг или сами книги, для подставки под фон
                `
            },
            {
                time: '15:00',
                text: 'Урок 3. Биомеханика. Подробный разбор движения человека и собаки.',
                link: 'https://disk.yandex.ru/d/tjItk3oWjKUVfQ/МК%20СМФ%2025.08%2015.00.mp4',
                prepare: `
* клей-карандаш или ПВА 
* ленточка, толстая нитка или палочка 
* бумага акварельная или плотная, цветная бумага (по желанию)
* краски/кисти 
* клей/скотч бумажный или обычный (широкий)
* баночки для воды, салфетки
* ластик, карандаши
* ножницы, ножик канцелярский
* монетки (денежные) или железные грузики, клеющие подушечки (патафикс) если их нет подойдёт пластилин
* смартфон
* лего-конструктор
* лампа для света (можно обычные настольные лампы)
* подставка для книг или сами книги, для подставки под фон
                `
            },
        ],
    },
    {
        date: '26 августа',
        items: [
            {
                time: '11:00',
                text: 'Урок 4. Создаем фоны из ватмана, цветной бумаги, красок. Пластилиновая анимация.',
                link: 'https://disk.yandex.ru/d/tjItk3oWjKUVfQ/МК%20СМФ%2026.08%2011.00.mp4',
                prepare: `
* клей-карандаш или ПВА 
* ленточка, толстая нитка или палочка 
* бумага акварельная или плотная, цветная бумага (по желанию)
* краски/кисти 
* клей/скотч бумажный или обычный (широкий)
* баночки для воды, салфетки
* ластик, карандаши
* ножницы, ножик канцелярский
* монетки (денежные) или железные грузики, клеющие подушечки (патафикс) если их нет подойдёт пластилин
* смартфон
* лего-конструктор
* лампа для света (можно обычные настольные лампы)
* подставка для книг или сами книги, для подставки под фон
                `
            },
            {
                time: '13:00',
                text: 'Урок 5. Предметная анимация из всего, что есть дома. Азы сценария.',
                link: '',
                prepare: `
* клей-карандаш или ПВА 
* ленточка, толстая нитка или палочка 
* бумага акварельная или плотная, цветная бумага (по желанию)
* краски/кисти 
* клей/скотч бумажный или обычный (широкий)
* баночки для воды, салфетки
* ластик, карандаши
* ножницы, ножик канцелярский
* монетки (денежные) или железные грузики, клеющие подушечки (патафикс) если их нет подойдёт пластилин
* смартфон
* лего-конструктор
* лампа для света (можно обычные настольные лампы)
* подставка для книг или сами книги, для подставки под фон
                `
            },
            {
                time: '15:00',
                text: 'Урок 6. Песочная анимация и рисованная анимация на кальке.',
                link: 'https://disk.yandex.ru/d/tjItk3oWjKUVfQ/МК%20СМФ%2026.08%2015.00.mp4',
                prepare: `
* песочный стол с подсветкой или световой планшет. Альтернатива – коробка под размер А4, белый полиэтиленовый пакет, оргстекло или обычное стекло тоже размером А4 (можно больше), светодиодная лента или гирлянда для подсветки стекла
* нарезанная калька размер  А4 (можно меньше)
* цветные карандаши
* песок, или любой сыпучий материал (гречка, рис и т.д.).
                `
            },
        ],
    },    {
        date: '27 августа',
        items: [
            {
                time: '11:00',
                text: 'Урок 7. Морфинг. Трансформация из пластилина в бумагу.',
                link: 'https://disk.yandex.ru/i/Za9baP0Z21u9cQ',
                prepare: `
* бумага (для оригами)
* плотная бумага
* фольга
* ножницы
* скотч
* двусторонний скотч
* линейка
* карандаш
* пластилин.
                `
            },
            {
                time: '13:00',
                text: 'Урок 8. Что такое раскадровка и зачем она нужна. Создание мультфильма по мотивам стихотворения. Эффект перемещения персонажа за счёт движения декораций.',
                link: 'https://disk.yandex.ru/i/yTeh1dvCxAW83w ',
                prepare: `
* бумага А4
* ватман
* цветная бумага или цветной картон
* гуашь или акварель
* цветные карандаши/фломастеры
* обычный карандаш или ручка
* ножницы
* клей
* гамстик или пластилин
* и любые дополнительные материалы для реализации творческих амбиций .
                `
            },
            {
                time: '15:00',
                text: 'Урок 9. Анимация в смешанной технике. Пластилиновая анимация. Саунд дизайн экранного произведения.',
                link: 'https://disk.yandex.ru/d/b-NRWiISz4zcDQ',
                prepare: `
* цветная бумага
* пластилин
* ножницы
* тарелка
* чашка
* ветка, или любая палка
* гуашь
* плотный лист бумаги А3 (или А2)
* кисти.
                `
            },
        ],
    },
];