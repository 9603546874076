export const en = {
    menu: {
        main: 'Home',
        news: 'News',
        auditory: "Participants",
        workshops: "Workshops",
        stream: "Stream",
        themes: 'Topics and Creative Directions',
        schedule: 'Program',
        organizer: 'Under support',
    },
    home: {
        line1: 'Discover the world –',
        line2: 'open yourself ',
        header: 'The Children`s Cultural Forum ',
        text: 'is a place of gathering for young talents full of fresh ideas, energy and great desire for personal growth, for meeting like-minded people and making new friends, eager to create and bring to the world something brand new.',
    },
    timer: {
        title: 'Countdown',
        d: 'd',
        h: 'h',
        m: 'min',
        s: 'sec',
    },
    video: {
        header: 'Greetings from the Organizing Committee',
        text: 'Greetings from the Minister of Culture of the Russian Federation, Lyubimova O.B.',
    },
    footer: {
        links: 'Chapters',
        contacts: 'Contacts',
        tags: 'Tags',
        conditions: ' the general rules of the Children\'s Cultural forum',
        conditionsLink: 'Read',
        confidential: 'Read the data privacy ',
        confidentialLink: 'document',
        time: 'from 9:00 to 18:00 MSK',
        copyright: 'Charitable Foundation for Animation Support',
        tagItems: [
            {
                title: '#childrensculturalforum',
                link: '#',
            },
            {
                title: '#ccf',
                link: '#',
            },
            {
                title: '#vdnkh',
                link: '#',
            },
            {
                title: '#МинкультурыРоссии',
                link: '#',
            },
            {
                title: '#МинистерствоКультурыРФ',
                link: '#',
            },
        ],
    },
    about: {
        title: 'About Forum',
        text: 'Children participants of the festival program will perform with the concerts, take part in workshops of choice and major interest, learn about the opportunities on professional educational institutions, network with their peers from other places and regions, exchange creative experience and certainly will get acquainted with the stunning Moscow sights.',
        place: 'The Venue Location',
        place1: 'VDNkH',
        place2: 'Pavilion 57',
        date: 'Dates',
        date1: '24-28',
        date2: 'August',
    },
    program: {
        header: 'Program',
        items: [
            {
                title: 'Arrival',
                text: 'in environment of like-minded people, networking',
            },
            {
                title: 'Excursions',
                text: 'Immersion in cultural space of Moscow, routes along the Pushkin map',
            },
            {
                title: 'Motivation',
                text: 'Dialogues with cultural leaders',
            },
            {
                title: 'Education',
                text: 'Gaining knowledge from cultural professionals',
            },
            {
                title: 'Inspiration',
                text: 'Creative development at  forum site in synergy of  program and environment',
            },
            {
                title: 'Collaboration',
                text: 'Creation of  the art object, development of cultural leadership, formation of new creative groups',
            },
            {
                title: 'Contribution to culture',
                text: 'Feedback on events that took place during the day, analysis of the knowledge gained in discussion clubs',
            },
            {
                title: 'A new level of own creativity',
                text: 'Bringing knowledge and inspiration to life',
            },
        ],
    },
    participants: {
        title: 'Participants',
        text: 'The Forum is conceived as a unique space where talented children may meet each other, teachers, parents, cultural leaders, state and public figures in order to share their vision on cultural development and of their own needs for successful creative accomplishments.',
        line1: 'Age of children',
        line2: '12-17',
        line3: 'y.o.',
        line4: '>1500',
        line5: 'participants - bright representatives of creative directions  from all regions',
        line6: '85',
        line7: 'subjects of Russian Federation',
    },
    topics: {
        title: 'Creative Directions',
        items: [
            'Ballet, dance, choreography',
            'Theatre',
            'Fine Art',
            'Traditional and new media',
            'Music',
            'Museums and exhibitions',
            'Films and animation'
        ]
    },
    organizer: {
        title: 'Under support',
        text: 'The Ministry of Culture of the Russian Federation is a federal executive brunch authorized to deal with and support the issues of culture and art.',
        header1: 'The main task ',
        text1: 'of the Ministry is implementation of the Strategy of State Cultural Policy until 2030 approved by the Order of the Russian Federation Government of 29 February 2016. We work to ensure Russia strengthens its status as a great cultural power and each of its citizens feels being involved in sharing the national cultural values of our country.',
        header2: 'Today,',
        text2: 'the structure of the Ministry of Culture is a branched system covering absolutely all the areas of culture. Each area under the jurisdiction of the Ministry is distributed among 59 divisions that are part of 11 specialized departments',
    },
    committee: {
        title: 'Organizing Committee',
        items: [
            {
                title: 'Olga Lyubimova',
                description: 'Minister of Culture of the Russian Federation',
                photo: 'https://storage.yandexcloud.net/dkf/images/lubimova.jpg',
            },
            {
                title: 'Alla Manilova',
                description: 'Secretary of State - Deputy Minister of Culture of the Russian Federation',
                photo: 'https://storage.yandexcloud.net/dkf/images/manilova.jpg',
            },
            {
                title: 'Olga Yarilova',
                description: 'Deputy Minister of Culture of the Russian Federation',
                photo: 'https://storage.yandexcloud.net/dkf/images/yarilova.jpg',
            },
            {
                title: 'Mikhail Bryzgalov',
                description: 'General Director of the Russian National Museum of Music',
                photo: 'https://storage.yandexcloud.net/dkf/images/bryzgalov.jpg',
            },
            {
                title: 'Denis Gribov',
                description: 'Deputy Minister of Education of the Russian Federation',
                photo: 'https://storage.yandexcloud.net/dkf/images/gribov.jpg',
            },
            {
                title: 'Gulnaz Kadyrova',
                description: 'Deputy Minister of Industry and Trade of the Russian Federation',
                photo: 'https://storage.yandexcloud.net/dkf/images/kadyrova.jpg',
            },
            {
                title: 'Alexander Kibovsky',
                description: 'Minister of the Moscow Government, Head of the Department of Culture of the City of Moscow',
                photo: 'https://storage.yandexcloud.net/dkf/images/kibovski.jpg',
            },
            {
                title: 'Evgeny Primakov',
                description: 'Head of the Federal Agency for the Commonwealth of Independent States Affairs, Compatriots Living Abroad and International Humanitarian Cooperation',
                photo: 'https://storage.yandexcloud.net/dkf/images/primakov.jpg',
            },
            {
                title: 'Ekaterina Pronicheva',
                description: 'Chairwoman of the Moscow Government Tourism Committee',
                photo: 'https://storage.yandexcloud.net/dkf/images/pronicheva.jpg',
            },
            {
                title: 'Yuliana Slashcheva',
                description: 'Chairwoman of the Board of the Soyuzmultfilm Animation Studio, General Director of the Gorky Film Studio',
                photo: 'https://storage.yandexcloud.net/dkf/images/slascheva.jpg',
            },
            {
                title: 'Anna Usacheva',
                description: 'Press secretary of the Minister of Culture of the Russian Federation',
                photo: 'https://storage.yandexcloud.net/dkf/images/usacheva.jpg',
            },
            {
                title: 'Sergey Shogurov',
                description: 'General Director of VDNKh (Exhibition of Achievements of National Economy)',
                photo: 'https://storage.yandexcloud.net/dkf/images/shogurov.jpg',
            },
            {
                title: 'Olga Yurkova',
                description: 'Deputy Director of the Department of Regional Policy, Education and Project Management of the Ministry of Culture of Russia',
                photo: 'https://storage.yandexcloud.net/dkf/images/urkova.jpg',
            },
        ]
    },
    partners: {
        title: 'Organizer and partners',
        organizer: 'Organizer',
        coorganizer: 'Co-Organizer',
        partners: 'Partners',
        infopartners: 'Infopartners',
        finepartners: 'Partner of the direction "Fine Arts"',
    }
};