import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import cx from "classnames";

import {useMenu} from "hooks/useMenu";
import styles from "./Footer.module.scss";
import logo from "./logo.svg";
import logoEng from "./logo_eng.svg";
import logo2 from "./logo2.svg";
import logoS from "./logo_s.svg";
import logoSEng from "./logo_s_eng.svg";
import logo2S from "./logo2_s.svg";


export const Footer = () => {
    const { t, i18n } = useTranslation();

    const isRu = i18n.language == 'ru';

    const menu = useMenu();

    const tags = t('footer.tagItems', { returnObjects: true });

    return (
        <div className={styles.block}>
            <div className={cx(styles.inner, styles.contacts)}>
                <div className={styles.logos}>
                    <Link to="/">
                        {isRu ?
                            <>
                                <img src={logo} className={styles.logo1} />
                                <img src={logoS} className={styles.logo1S} />
                            </> :
                            <>
                                <img src={logoEng} className={styles.logo1} />
                                <img src={logoSEng} className={styles.logo1S} />
                            </>
                        }
                    </Link>
                    <a href="https://culture.gov.ru/" target="_blank">
                        <img src={logo2} className={styles.logo2} />
                        <img src={logo2S} className={styles.logo2S} />
                    </a>
                </div>
                <div className={styles.menu}>
                    <div className={styles.bold}>
                        <span>{t('footer.links')}</span>
                    </div>

                    <ul className={styles.links}>
                        {menu.map((item, i) => {
                            return (
                                <li key={i}>
                                    <Link key={i} to={item.link} className={styles.item}>
                                        {item.title}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div>
                    <div className={styles.bold}>
                        {t('footer.contacts')}
                    </div>
                    <br />
                    {t('footer.time')}<br /><br />

                    <a href="mailto:info@kids-forum.ru" target="_blank" className={styles.item}>
                        info@kids-forum.ru
                    </a>

                </div>
                <div className={styles.tags}>
                    <div className={styles.bold}>
                        {t('footer.tags')}
                    </div>
                    {/*@ts-ignore*/}
                    {tags.map((item, i) => {
                        return (
                            <div key={i}>
                                {item.title}
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={cx(styles.inner, styles.copyright)}>
                <div>
                    © {t('footer.copyright')}, 2022
                </div>
                <div>
                    {isRu ? (
                        <>
                            {t('footer.conditions')} <a target="_blank" href={'https://storage.yandexcloud.net/dkf/docs/%D0%9F%D0%9E%D0%9B%D0%9E%D0%96%D0%95%D0%9D%D0%98%D0%95_%D0%9E%20%D0%94%D0%9A%D0%A4%202022.pdf'} className={styles.bold}>{t('footer.conditionsLink')}</a>
                        </>
                        )
                        : (
                            <>
                                <a target="_blank" href={'https://storage.yandexcloud.net/dkf/docs/%D0%9F%D0%9E%D0%9B%D0%9E%D0%96%D0%95%D0%9D%D0%98%D0%95_%D0%9E%20%D0%94%D0%9A%D0%A4%202022.pdf'} className={styles.bold}>{t('footer.conditionsLink')}</a> {t('footer.conditions')}
                        </>
                        )
                    }
                    <br />{t('footer.confidential')} <a target="_blank" href={isRu ? 'https://storage.yandexcloud.net/dkf/docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8_%D0%94%D0%9A%D0%A4_2022.pdf' : 'https://storage.yandexcloud.net/dkf/docs/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0_%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8_%D0%94%D0%9A%D0%A4_2022.pdf'} className={styles.bold}>{t('footer.confidentialLink')}</a>
                </div>
            </div>
        </div>
    );
}