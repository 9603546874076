export const data = [
    {
        title: 'Первый международный детский культурный форум. Общий',
        date: '29.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-005v.jpg',
        link: 'https://disk.yandex.ru/i/ZcMzAofaem7OGw',
    },
    {
        title: 'Первый международный детский культурный форум 2022. День 3',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-004v.jpg',
        link: 'https://disk.yandex.ru/i/Zc14GPmipL955A',
    },
    {
        title: 'Концерт «Вдохновение»',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-003v.jpg',
        link: 'https://disk.yandex.ru/i/jbTC0Sfhu58P_w',
    },
    {
        title: 'Первый международный детский культурный форум 2022. День 2.',
        date: '25.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-002v.jpg',
        link: 'https://disk.yandex.ru/i/38EQ3Hq-keuKbg',
    },
    {
        title: 'Первый международный детский культурный форум 2022. День 1.',
        date: '24.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-001v.jpg',
        link: 'https://disk.yandex.ru/i/2EgyhEIhp02H0Q',
    },
];