import {useTranslation} from "react-i18next";

import {Highlight} from "../Highlight/Highlight";

import styles from './Participants.module.scss';
import back from './back.png';
import hint from './hint.svg';
import hint2 from './hint2.svg';

export const Participants = () => {
    const {t} = useTranslation();

    return (
        <div className={styles.block} id="auditory">
            <img src={back} className={styles.back}/>

            <div className={styles.title}>
                {t('participants.title')}
            </div>

            <div className={styles.wrapper}>
                <div className={styles.paper}>
                    <img src={hint} className={styles.hint}/>
                    <img src={hint2} className={styles.hint2}/>

                    {t('participants.text')}
                </div>
            </div>

            <div className={styles.info}>
                <Highlight>
                    {t('participants.line4')}
                </Highlight>

                <div>
                    {t('participants.line5')}
                </div>

                <div className={styles.spacer}/>

                <Highlight>
                    {t('participants.line6')}
                </Highlight>

                <div>
                    {t('participants.line7')}
                </div>

                <div className={styles.spacer}/>
                <div className={styles.spacer}/>

                <Highlight className={styles.narrow}>
                    {t('participants.line2')}
                </Highlight>

                <Highlight>
                    {t('participants.line3')}
                </Highlight>

                <div>
                    {t('participants.line1')}
                </div>
            </div>
        </div>
    );
}