export function getCookie(name: string) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts?.length === 2) {
        // @ts-ignore
        return parts.pop().split(';').shift();
    } else {
        return '';
    }
}

export const getCsrfToken = () => getCookie('csrftoken') || '';

// @ts-ignore
function buildFormData(formData, data, parentKey = '') {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;

        formData.append(parentKey, value);
    }
}

// @ts-ignore
export function jsonToFormData(data) {
    const formData = new FormData();

    buildFormData(formData, data);

    return formData;
}