export const data = [
    {
        finished: true,
        date: '25 августа',
        items: [
            {
                time: '11.30 – 13.00',
                title: 'Открывающая сессия\n' +
                    '«Ребенок и культура. Диалог поколений»',
                text: '',
                link: '',
            },
            {
                time: '15.30 – 17.00',
                title: 'Открытый диалог',
                text: 'Министра культуры Российской Федерации «Культурная инициатива»',
                link: '',
            },
            {
                time: '17.30 - 18.30',
                title: 'Музыкальное искусство',
                text: 'Интерактивный мастер-класс «Большое путешествие. Знакомство с русскими народными инструментами»',
                link: '',
            },
        ],
    },
    {
        finished: true,
        date: '26 августа',
        items: [
            {
                time: '9.30 – 10.30 ',
                title: 'Хореография',
                text: 'Диалог со знаковым деятелем культуры\n' +
                    ' «Сила народного танца. Традиции, устремленная в будущее»',
                link: '',
            },
            {
                time: '11.00 – 12.00',
                title: 'Театральное искусство',
                text: 'Диалог со знаковым деятелем культуры \n' +
                    '«Уроки добра: как творчество помогает нам стать лучше»',
                link: '',
            },
            {
                time: '12.30 – 13.30 ',
                title: 'Музыкальное искусство',
                text: 'Диалог со знаковым деятелем культуры «Традиции исполнительской школы как часть культурного наследия России. Путь в высокое искусство»',
                link: '',
            },
            {
                time: '14.00 – 15.00 ',
                title: 'Детская школа искусств',
                text: 'Формат для педагогов. Всероссийское совещание детских школ искусств',
                link: '',
            },
            {
                time: '15.30-16.30',
                title: 'Форсайт-сессия',
                text: '«Культурный манифест нового поколения»',
                link: '',
            },
        ],
    },

];
