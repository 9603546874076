import styles from './WorkshopSwitch.module.scss';

import souz from './souz.svg';
import gorki from './gorki.svg';

export const WorkshopSwitch = () => {
    return (
        <div className={styles.block}>
            <a
                href="/workshops#"
            >
                <img
                    alt=""
                    src={souz}
                    className={styles.souz}
                />
            </a>
            <a
                href="/workshops#gorki"
            >
                <img
                    alt=""
                    src={gorki}
                    className={styles.gorki}
                />
            </a>
        </div>
    );
}