import {useState} from "react";
import Masonry from 'react-masonry-css'

import styles from './NewsArticles.module.scss';
import {dataToShow} from './data';
import {Link} from "react-router-dom";

const threshold = 4;

export const NewsArticles = () => {
    const [opened, setOpened] = useState(false);

    if (!dataToShow || !dataToShow.length) {
        return null;
    }

    const croppedData = (opened || dataToShow.length < threshold ) ? dataToShow : dataToShow.slice(0, threshold);

    return (
        <div className={styles.block}>
            <div className={styles.header}>
                Новости
            </div>

            <Masonry
                breakpointCols={{
                    default: 2,
                    900: 1,
                }}
                className={styles.items}
                columnClassName={styles.column}
            >
                {croppedData.map((item, i, arr) => {
                    return (
                        <Link
                            key={i}
                            className={styles.item}
                            to={`/article/${(item.id)}`}
                        >
                            <div className={styles.title}>
                                {item.title}
                            </div>

                            <div className={styles.date}>
                                {item.date}
                            </div>

                            <div className={styles.text}>
                                {item.teaser}
                            </div>

                            {Boolean(item.photo) && (
                                <img
                                    className={styles.image}
                                    alt="image"
                                    src={item.photo}
                                />
                            )}
                        </Link>
                    );
                })}
            </Masonry>

            {dataToShow.length > threshold && (
                <div className={styles.button} onClick={() => setOpened(!opened)}>
                    {opened ? "Скрыть" : "Показать всё"}
                </div>
            )}
        </div>
    );
}