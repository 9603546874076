import {FC, useCallback, useState} from "react";

import {EventRegistrationSerializer, ProfileSerializer} from "../../types/api";
import {Button} from "react-bootstrap";

import styles from './Registrations.module.scss';

type Props = {
    managerId: number;
    registrationsByUser: Record<number, EventRegistrationSerializer>;
    userById: Record<number, ProfileSerializer>;
    userIds: number[];
}

export const Region: FC<Props> = ({managerId, registrationsByUser, userById, userIds}) => {
    const manager = userById[managerId];
    const managerRegistration = registrationsByUser[managerId];

    const [opened, setOpened] = useState(false);
    const toggle = useCallback(() => setOpened(!opened), [setOpened, opened]);

    const budgetPlaces = manager?.budget_places_override ?? manager?.organization?.budget_places ?? 0;

    const users = userIds.map((id) => userById[id]);
    const quotaUsers = users.filter((user) => {
        const registration = registrationsByUser[user.id || 0];

        return !registration.is_not_budget;
    });

    const nonQuotaUsers = users.filter((user) => {
        const registration = registrationsByUser[user.id || 0];

        return registration.is_not_budget;
    });

    return (
        <>
            <tr>
                <td>
                    {manager.organization?.external_id}
                </td>
                <td>
                    {manager.id}
                </td>
                <td>
                    {managerRegistration?.id}
                </td>
                <td>
                    {manager.position}
                </td>
                <td>
                    {budgetPlaces + 1}
                </td>
                <td>
                    {managerRegistration ? '1' : '0'}|{quotaUsers.length}|{nonQuotaUsers.length}
                </td>
                <td>
                    <Button onClick={toggle}>
                        {opened ? 'Свернуть' : 'Развернуть'}
                    </Button>
                </td>
            </tr>

            {opened && (
                <tr>
                    <td colSpan={7}>

                        <table className={styles.block}>
                            <thead>
                            <th>
                                userId
                            </th>
                            <th>
                                regId
                            </th>
                            <th>
                                Статус
                            </th>
                            <th>
                                ФИО
                            </th>
                            <th>
                                Телефон
                            </th>
                            <th>
                                Email
                            </th>
                            <th>
                                Пищевые
                            </th>
                            <th>
                                Проживание
                            </th>
                            <th colSpan={4}>
                                Прибытие
                            </th>
                            <th colSpan={4}>
                                Отбытие
                            </th>
                            </thead>

                            <tbody>

                            <User
                                user={manager}
                                registration={managerRegistration}
                            />

                            {quotaUsers.map((user) => {
                                return (
                                    <User
                                        key={user.id}
                                        user={user}
                                        registration={registrationsByUser[user.id || 0]}
                                    />
                                );
                            })}

                            {nonQuotaUsers.map((user) => {
                                return (
                                    <User
                                        key={user.id}
                                        user={user}
                                        registration={registrationsByUser[user.id || 0]}
                                    />
                                );
                            })}

                            </tbody>
                        </table>

                    </td>
                </tr>
            )}
        </>
    );
}

type UserProps = {
    registration?: EventRegistrationSerializer;
    user: ProfileSerializer;
}

const User: FC<UserProps> = ({registration, user}) => {

    const isManager = !user.supervisor;

    if (!registration) {
        return null;
    }

    return (
        <tr>
            <td>
                {user.id}
            </td>
            <td>
                {registration?.id}
            </td>
            <td>
                {isManager ? 'Руководитель' : (
                    registration?.is_not_budget ? 'Вне квоты' : 'По квоте'
                )}
            </td>
            <td>
                {user.surname} {user.name} {user.second_name}
            </td>
            <td>
                {isManager && user.phone}
            </td>
            <td>
                {isManager && user.additional_email}
            </td>
            <td>
                {registration?.food_preferences}
            </td>
            <td>
                {registration?.living_preferences}
            </td>
            <td>
                {registration?.arrival_ticket?.date}
            </td>
            <td>
                {registration?.arrival_ticket?.time}
            </td>
            <td>
                {registration?.arrival_ticket?.city}
            </td>
            <td>
                {registration?.arrival_ticket?.route}
            </td>

            <td>
                {registration?.departure_ticket?.date}
            </td>
            <td>
                {registration?.departure_ticket?.time}
            </td>
            <td>
                {registration?.departure_ticket?.city}
            </td>
            <td>
                {registration?.departure_ticket?.route}
            </td>
        </tr>
    )
}