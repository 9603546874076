import {FC} from "react";
import cx from "classnames";

import styles from './Highlight.module.css'

type Props = {
    className?: string;
}

export const Highlight: FC<Props> = ({children,  className}) => {
    return (
        <div className={cx(styles.block, className)}>
            <div className={styles.text}>
                {children}
            </div>
            <div className={styles.stroke}>
                {children}
            </div>
        </div>
    );
}