export const data = [
    {
        date: '25 августа',
        items: [
            {
                time: '11.50  –  13.30 ',
                title: 'Мастерство актёра',
                text: 'Урок 1',
                link: '',
            },
            {
                time: '14.50 – 15.50 ',
                title: 'Мастерство актёра',
                text: 'Урок 2',
                link: '',
            },
            {
                time: '16.00 – 17.30',
                title: 'Мастер-класс от звезды',
                text: 'Известные стендаперы Виталий Щанников и Амбарцум Симонянц',
                link: '',
            },
            {
                time: '16.00 – 17.30',
                title: 'Сценическая речь',
                text: 'Урок 1',
                link: '',
            },
        ],
    },
    {
        date: '26 августа',
        items: [
            {
                time: '10.50  –  11.50 ',
                title: 'Мастерство актёра',
                text: 'Урок 3',
                link: '',
            },
            {
                time: '12.00 – 13.30 ',
                title: 'Сценическая речь',
                text: 'Урок 2',
                link: '',
            },
            {
                time: '14.50 – 15.50',
                title: 'Мастерство актёра',
                text: 'Урок 4 ',
                link: '',
            },
            {
                time: '15:00-16:30',
                title: 'Мастер-класс от звезды',
                text: 'Выступление известной певицы Мии Бойко',
                link: '',
            },
        ],
    },
    {
        date: '27 августа',
        items: [
            {
                time: '10.50  –  11.50 ',
                title: 'Мастерство актёра',
                text: 'Урок 4',
                link: '',
            },
            {
                time: '12.00 – 13.30 ',
                title: 'Сценическая речь',
                text: 'Урок 3',
                link: '',
            },
            {
                time: '14.50 – 15.50',
                title: 'Мастерство актёра',
                text: 'Урок 5',
                link: '',
            },
            {
                time: '16.00 – 17.30',
                title: 'Сценическая речь',
                text: 'Урок 4',
                link: '',
            },
            {
                time: '16.00 – 17.30',
                title: 'Мастер-класс от звезды',
                text: 'Актер и режиссер Егор Баринов: Как правильно готовиться к пробам.',
                link: '',
            },
        ],
    },
];