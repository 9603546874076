const reversedData = [
    {
        title: 'Лекции о виртуальной реальности, встречи с известными деятелями культуры, театральные и танцевальные арт-школы: что ожидает участников детского форума?',
        date: '10.08.2022',
        photo: '',
        teaser: 'Сформирована афиша Детского культурного форума, который пройдет впервые с 25 по 27 августа в Москве. Программа состоит из просветительской и фестивальной части – всего более 60 мероприятий.',
        full:
            `Сформирована [афиша Детского культурного форума](https://disk.yandex.ru/d/Nqgvqv0Qx1G0gA), который пройдет впервые с 25 по 27 августа в Москве. Программа состоит из просветительской и фестивальной части – всего более 60 мероприятий.

Просветительская программа начнется 25 августа с сессии «Ребенок и культура. Диалог поколений» при участии знаковых деятелей культуры: **Сергея Безрукова, Игоря Бутмана, Елены Гагариной, Юлианы Слащевой, Николая Цискаридзе.**

Они расскажут ребятам о своем профессиональном опыте, о секретах успеха и преодолении вызовов, а также ответят на вопросы. Итогом встречи станет творческий манифест: от наставников – юным талантам.

Ведущими мероприятия выступят **Тутта Ларсен** и ее дочь **Марфа Колоскова**.
Одним из знаковых мероприятий форума станет диалог с Министром культуры Российской Федерации **Ольгой Любимовой**. У детей будет возможность представить Министру свои проекты, направленные на поддержку отрасли культуры и детского творчества. Лучший проект сможет получить федеральное финансирование.

Кроме того, ребят ждут встречи с известными деятелями культуры, на которых они узнают многое о детском волонтерстве, о кастингах в кино, современных медиа, традициях исполнительской школы, интересные факты о театральном, исполнительском и музыкальном искусстве. На открытых лекциях юные участники форума смогут узнать о виртуальной реальности и интерактивных технологиях в популяризации культуры и искусства, трендах авторской анимации и о том, как пройти путь от художественной литературы до фэнтези, а также посетить мастер-класс по русскому жестовому языку.

Фестивальная часть представлена обширной программой. Участники смогут побывать на мастер-классах по мобильному фильммейкингу, анимации, народному танцу, а также по изобразительному искусству от Московского художественного института им. В. И. Сурикова и музыкальному искусству от **Юрия Башмета**, поучаствовать в киновикторине и танцевальном баттле, научиться многому в арт-школах «Зачем художнику выставки? Или как заявить/рассказать о себе», «Конструктор текста. Как придумать и рассказать захватывающую историю», «Актерское мастерство». 

*Справочно:*

*Организатор Детского культурного форума – Министерство культуры Российской Федерации, соорганизаторами Форума стали Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ. Среди партнеров – Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история», сообщество «Большая перемена», Лига юных журналистов. Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».*

*В Форуме примут участие более 1500 одаренных детей из всех регионов Российской Федерации. Также на мероприятии будут представители сообщества «Большая перемена». Кроме того, подтвердили свое участие представители из Республики Беларусь, Республики Южная Осетия, Республики Сербия, Республики Таджикистан, Республики Узбекистан, ДНР, ЛНР, Египта и Абхазии.*

Источник: Пресс-служба Министерства культуры Российской Федерации 
            `,
    },
    {
        title: 'Гости Детского культурного форума смогут принять участие в масштабном иммерсивном шоу по мотивам «Тайны третьей планеты»',
        date: '10.08.2022',
        photo: '',
        teaser: '«Союзмультфильм» и Киностудия им. М. Горького представят на площадке Детского культурного форума разноформатную партнерскую программу, самым масштабным мероприятием которой станет запланированное на 27 августа ретро-футуристическое иммерсивное шоу для детей и подростков по мотивам мультфильма «Тайна третьей планеты».',
        full: `
*«Союзмультфильм» и Киностудия им. М. Горького представят на площадке Детского культурного форума разноформатную партнерскую программу, самым масштабным мероприятием которой станет запланированное на 27 августа ретро-футуристическое иммерсивное шоу для детей и подростков по мотивам мультфильма «Тайна третьей планеты».*

«Детский культурный форум – уникальная площадка для диалога поколений, мероприятие, которое соединит в себе опыт, традиции с актуальными идеями его юных участников, – отметила председатель Совета директоров киностудии «Союзмультфильм», генеральный директор киностудии им. М. Горького Юлиана Слащева. – Такой подход неизменно дает новый импульс, вдохновение, смыслы, и именно поэтому мы представим здесь отчасти экспериментальную программу с необычными форматами как развлекательного, так и познавательного характера».    

Центральным персонажем шоу по мотивам «Тайны третьей планеты» станет DJ Громозека – двухметровая фигура с шестью управляемыми руками за диджейским пультом. Гостей мероприятия ждет также зарядка от персонажей «Союзмультфильма», танцевальный мастер-класс от профессиональных хореографов, интерактивные номера с артистами, мастерская дизайна, где можно будет создать тематические предметы гардероба и многое другое. Завершится событие флешмобом и дискотекой.  

Еще одна необычная локация будет посвящена мультсериалу «Приключения Пети и волка», где посетителей ожидает квест по мотивам этого популярного проекта. Кроме того, в дни проведения форума 25 - 27 августа все желающие смогут сделать фото в специальных фотозонах «Союзмультфильма», получить памятные подарки, принять участие в мастер-классах по созданию анимации, комиксов, а также росписи футболок и изготовлению аксессуаров. Одновременно в павильоне № 57 на ВДНХ и кинозале гостиницы «Космос» состоятся показы эпизодов мультсериалов и короткометражных работ.

В свою очередь Киностудия им. М. Горького организует интерактивную фотозону в виде большой камеры с вращающимися элементами – гости мероприятия смогут не только сделать снимок на память, но и получат навыки построения удачного кадра. Кроме того, в рамках специального кинокоучинга от Школы кино и медиа Киностудии им. М. Горького участники посетят выставку сказочного костюма, серию кинолекториев, практические интенсивы по озвучанию, дубляжу и кинопроизводству, мастер-классы по операторскому искусству, сценарной драматургии и актерскому мастерству. Все желающие смогут попробовать свои силы в киновикторине.

Источник: Пресс-служба Министерства культуры Российской Федерации
            `,
    },
    {
        title: 'Константин Хабенский, Нонна Гришаева, Александр Олешко, Пётр Дранга. Кто станет спикерами Детского культурного форума',
        date: '11.08.2022',
        photo: '',
        teaser: 'С 25 по 27 августа в Москве в павильоне № 57 ВДНХ (парк «Россия – моя история») пройдет Международный детский культурный форум. В афишу включено свыше 60 различных мероприятий, которые разделены на просветительские и фестивальные. Это мастер-классы, лекции, творческие мастерские, круглые столы и арт-школы. Кроме этого, юных участников Форума ожидают встречи со знаковыми деятелями культуры.',
        full:
            `С 25 по 27 августа в Москве в павильоне № 57 ВДНХ (парк «Россия – моя история») пройдет Международный детский культурный форум. В афишу включено свыше 60 различных мероприятий, которые разделены на просветительские и фестивальные. Это мастер-классы, лекции, творческие мастерские, круглые столы и арт-школы. Кроме этого, юных участников Форума ожидают встречи со знаковыми деятелями культуры.  
 
Просветительская и фестивальная программы стартуют 25 августа. Открытие просветительской программы пройдет в интерактивном формате. Сергей Безруков, Игорь Бутман, Елена Гагарина, Юлиана Слащева, Николай Цискаридзе по очереди расскажут свою творческую историю, а также поделятся личным опытом успеха и преодоления вызовов на сессии «Ребенок и культура. Диалог поколений».  Итогом сессии станет творческий манифест от наставников, который может послужить для юных талантов бесценным путеводителем по творческому пути. 
 
Также в день открытия Николай Цискаридзе проведет мотивационную встречу «Танец как стиль жизни» для участников направления «Хореографическое искусство», пианистка, солистка Московской государственной академической филармонии, заслуженная артистка Российской Федерации Екатерина Мечетина выступит с лекцией «Уроки фортепиано – уроки жизни. Мелодия сердца и звуки души».  
  
Ключевым мероприятием Форума станет диалог с Министром культуры Российской Федерации Ольгой Любимовой. Дети представят Министру свои проекты, направленные на развитие культуры и поддержку детского творчества. Лучший проект сможет получить федеральное финансирование.  
 
В этот же день Арт-школа познакомит юных участников Форума с культурой и ее особенностями в контексте современного мира. Блогер, основатель школы популярного искусства «OP POP ART» Анастасия Постригай станет гостем школы и расскажет о своем пути в работе в медиа. В финале участники смогут задать вопросы приглашенному гостю в интерактивной сессии и узнать все, что их интересовало ранее или заинтересует в ходе дискуссии.  
  
На следующий день в рамках направления «Музыкальное искусство» ребят ожидает диалог с художественным руководителем и главным дирижером Государственного симфонического оркестра «Новая Россия», народным артистом СССР Юрием Башметом на тему «Традиции исполнительской школы как часть культурного наследия России. Путь в высокое искусство», также участники смогут пообщаться с Константином Хабенским, который расскажет о том, как творчество помогает стать лучше. Аккордеонист-виртуоз, дирижер, композитор, режиссер, певец Петр Дранга проведет мастер-класс «Инструмент неограниченных возможностей. Новое звучание аккордеона» – ребята смогут узнать, как появился инструмент, чем он отличается от русского баяна и как стал одним из самых популярных инструментов в мире. Секретами своего мастерства поделятся Нонна Гришаева и Александр Олешко, также они расскажут, как устроен театр, какая роль самая сложная и любимая, какие испытания ждут на творческом пути.   
  
Кроме того, у юных участников будет возможность пообщаться с продюсером, основателем компаний «Централ Партнершип» и «Марс Медиа Энтертейнмент» (Пальма и Пальма 2) Рубеном Дишдишяном, генеральным продюсером студии Yellow, Black and White, сооснователем видеосервиса START Эдуардом Илояном и побывать на интерактивном мастер-классе заслуженного артиста Российской Федерации, директора Государственного академического русского народного ансамбля «Россия» им. Л. Г. Зыкиной Дмитрия Дмитриенко.   

Источник: Пресс-служба Министерства культуры Российской Федерации 
            `,
    },
    {
        title: 'Началась аккредитация представителей СМИ на Детский культурный форум',
        date: '16.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/kal-visuals-mji92-ALY3g-unsplash.jpg',
        teaser: 'Началась аккредитация представителей СМИ на Детский культурный форум, который пройдет 25-27 августа на ВДНХ (павильон №57). Мероприятие проводится Министерством культуры Российской Федерации совместно с Министерством просвещения, Министерством промышленности и торговли, Правительством Москвы и выставочным центром ВДНХ.',
        full:
            `Началась аккредитация представителей СМИ на Детский культурный форум, который пройдет 25-27 августа на ВДНХ (павильон №57). Мероприятие проводится Министерством культуры Российской Федерации совместно с Министерством просвещения, Министерством промышленности и торговли, Правительством Москвы и выставочным центром ВДНХ.

Участников форума ждет более 60 мероприятий в рамках просветительской и фестивальной программ: мастер-классы, лекции, творческие мастерские, круглые столы и арт-школы. Спикерами станут известные деятели культуры и искусства: **Сергей Безруков, Игорь Бутман, Елена Гагарина, Юлиана Слащева, Николай Цискаридзе, Юрий Башмет, Нонна Гришаева, Константин Хабенский, Александр Олешко, Петр Дранга** и др. Одно из знаковых событий форума — диалог с Министром культуры Российской Федерации **Ольгой Любимовой**.

В форуме примут участие более 1500 одаренных детей из всех регионов Российской Федерации. Кроме того, подтвердили свое участие представители Беларуси, Сербии, Египта, Узбекистана, Таджикистана, Туркменистана, Южной Осетии, Абхазии, ДНР и ЛНР. 

Для подачи заявки на аккредитацию, пожалуйста, обращайтесь в Пресс-службу форума: [press@kids-forum.ru](mailto:press@kids-forum.ru). Прием заявок на аккредитацию СМИ продлится **до 22 августа**. На форуме будет работать Пресс-центр. 

Ознакомиться с подробной программой мероприятия можно по [ссылке](https://disk.yandex.ru/d/Nqgvqv0Qx1G0gA).
            `,
    },
    {
        title: 'Юные таланты презентуют свои инициативы Министру культуры Российской Федерации',
        date: '17.08.2022',
        photo: '',
        teaser: 'Талантливые дети со всей страны получили уникальную возможность презентовать творческие инициативы и предложения напрямую Министру культуры Российской Федерации Ольге Любимовой на площадке Детского культурного форума. Министерство культуры РФ рассмотрит возможность реализации лучших проектов.',
        full:
            `*Талантливые дети со всей страны получили уникальную возможность презентовать творческие инициативы и предложения напрямую Министру культуры Российской Федерации Ольге Любимовой на площадке Детского культурного форума. Министерство культуры РФ рассмотрит возможность реализации лучших проектов.*

Всего Министерство культуры Российской Федерации получило 32 проекта из 8 федеральных округов от ребят в возрасте от 12 до 17 лет. Принимаемые инициативы и предложения могли относиться к любому направлению программы Форума: театральное, хореографическое, музыкальное, изобразительное искусство; кино, анимация; литература; школьные театры; музеи и выставки; инклюзия; популяризация «Пушкинской карты»; формирование у детей собственного мнения о культуре и искусстве по программе «Культура для школьников»; формирование патриотического мышления. Кроме этого, принимались проекты по развитию культурного сообщества в своем регионе, реализации новых творческих проектов и т. д.

Лучшие из лучших проектов ребята представят 25 августа во время Открытого диалога Министра культуры Российской Федерации «Культурная инициатива» в рамках Детского культурного форума. В конце сессии будут подведены итоги: какие предложения будут взяты на заметку Министерством культуры РФ и что можно реализовать в ближайший год.

Дополнительную информацию вы можете получить в пресс-центре Детского культурного форума: [press@kids-forum.ru](mailto:press@kids-forum.ru).
            `,
    },
    {
        title: 'Музей Победы примет участие в Детском культурном форуме',
        date: '17.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/mv.jpg',
        teaser: 'Музей Победы проведет нескольких форматов на Детском культурном форуме. В ходе просветительской программы, на круглом столе «Формируем патриотическое мышление», предполагается обсуждение вопроса воспитания интереса к истории России через школьные музеи. А в рамках фестивальной программы Музей Победы организует мастер-класс, где каждый получит набор готовых идей для привлечения аудитории в любой школьный музей.',
        full:
            `*Музей Победы проведет нескольких форматов на Детском культурном форуме. В ходе просветительской программы, на круглом столе «Формируем патриотическое мышление», предполагается обсуждение вопроса воспитания интереса к истории России через школьные музеи. А в рамках фестивальной программы Музей Победы организует мастер-класс, где каждый получит набор готовых идей для привлечения аудитории в любой школьный музей.*

25 августа с 16:30 до 17:30 на площадке Детского культурного форума состоится круглый стол «Формируем патриотическое мышление». Его участники обсудят вопросы формирования чувства патриотизма и воспитания интереса к истории России у подрастающего поколения через музейную деятельность. Модератор круглого стола – Елена Слесаренко, руководитель Детского центра Музея Победы, расскажет о долгосрочной программе развития «Школьный Музей Победы», а также о возможностях школ по обмену опытом и налаживанию партнерских отношений с образовательными организациями всей страны.

Спикерами круглого стола станут представители Музея Победы: заместитель председателя Детского Общественного Совета Иван Борисов, руководитель исторического депозитария «Лица Победы» Андрей Широков, руководитель клуба «Лето Побед» Мария Гуменюк, а также экскурсовод Борис Чельцов. 

27 августа с 12:00 до 13:30 пройдет мастер-класс «Музей будущего», в котором примут участие заведующий сектором образовательных проектов отдела «Детский центр» и руководитель проекта «Академия Музея Победы» Артем Шилин и методист Школьного Музея Победы Александр Букреев. Спикеры расскажут о школьных музеях: что представляют из себя, основные элементы и структура. 

Кроме того, в рамках мастер-класса пройдет нетворкинг, на котором участники разработают проект по вовлечению аудитории в повестку Великой Отечественной войны. Итогом мероприятия станет защита разработанного проекта. Представители Детского Общественного Совета Музея Победы: Матвей Медведенко, Петр Шеншин, Арина Дзекунова, Диана Галстян и Илья Алексеев выступят в качестве экспертов и оценят проекты участников «Музей будущего».

Дополнительную информацию вы можете получить в пресс-центре Детского культурного форума: [press@kids-forum.ru](mailto:press@kids-forum.ru).
            `,
    },
    {
        title: 'В Детском культурном форуме примут участие 1,5 тысячи детей со всей страны',
        date: '18.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/lyj.jpg',
        teaser: 'Детский культурный форум – уникальная площадка, которая соберет талантливых детей со всей страны. Участниками станут более 1,5 тысяч детей из всех субъектов Российской Федерации. Участие также подтвердили представители зарубежных стран.',
        full:
            `*Детский культурный форум – уникальная площадка, которая соберет талантливых детей со всей страны. Участниками станут более 1,5 тысяч детей из всех субъектов Российской Федерации. Участие также подтвердили представители зарубежных стран.*

24 августа для участия в Детском культурном форуме в Москву приедут более 1,5 тысяч талантливых детей из всех 85 регионов РФ и зарубежных стран. В том числе 500 ребят – участники проекта «Большая перемена». Более того, на форум приглашены 30 молодых медийщиков из [Лиги юных журналистов](https://m.vk.com/liga.press). Они будут освещать события форума: вести прямые трансляции в формате ток-шоу и видеодневников с помощью передвижной телевизионной станции, брать интервью у участников и спикеров форума, создавать контент для социальных сетей.

Просветительская и фестивальная программы форума, в рамках которых запланировано более 60 мероприятий, стартуют 25 августа. Ребята в возрасте от 12 до 17 лет встретятся друг с другом, педагогами, родителями, культурными, государственными и общественными деятелями для того, чтобы заявить о своем видении развития культуры и собственных потребностях для успешной творческой реализации.

Ребятам предстоит не только открыть мир, узнав много нового, но и раскрыть свои таланты, обрести новые знания, встретить новых друзей.

Дополнительную информацию вы можете получить в пресс-центре Детского культурного форума: [press@kids-forum.ru](mailto:press@kids-forum.ru).
            `,
    },
    {
        title: 'Первый Детский культурный форум – цели, задачи, особенности подготовки и проведения',
        date: '22.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-001.jpg',
        teaser: 'Уже в этот четверг, 25 августа, стартуют просветительская и фестивальная программы Детского культурного форума – уникальной площадки для обмена мнениями, идеями между детьми и взрослыми. В рамках насыщенной трехдневной программы запланированы активности одновременно в нескольких локациях по разным направлениям, что позволит охватить все интересы молодой аудитории. Всего состоится более 60 мероприятий.',
        full:
            `*Уже в этот четверг, 25 августа, стартуют просветительская и фестивальная программы Детского культурного форума – уникальной площадки для обмена мнениями, идеями между детьми и взрослыми. В рамках насыщенной трехдневной программы запланированы активности одновременно в нескольких локациях по разным направлениям, что позволит охватить все интересы молодой аудитории. Всего состоится более 60 мероприятий*.

Детский культурный форум – это место встречи юных талантов, полных идей, энергии и стремления расти и развиваться, находить новых друзей и единомышленников, творить и создавать новое. В рамках просветительской программы пройдут лекции, открытые диалоги, дискуссии с участием выдающихся деятелей искусств, которые поделятся своими профессиональными секретами, вдохновят молодую аудиторию на будущие успехи. Мероприятия фестивальной программы несут более прикладной характер, запланированы мастер-классы, творческие мастерские, где ребята смогут продемонстрировать свои достижения и поделиться творческим опытом со сверстниками.

В программу Детского культурного форума включены встречи, мастер-классы, экскурсии, артшколы и лекции. Основные направления мероприятия: музыкальное, хореографическое, театральное и изобразительное виды искусств, кино и анимация, музеи и выставки, традиционные и новые медиа. В повестке также будут вопросы, связанные с концепцией дополнительного образования, инклюзией, культурной средой, школами креативных индустрий, Пушкинской картой и духовно-нравственным развитием детей. Отдельно будут представлены лучшие практики работы учреждений культуры с детской аудиторией.

При формировании программы особый акцент делался на привлечение талантливых ребят в качестве спикеров и со-ведущих. У зрителей в зале будет возможность задавать вопросы наставникам, в рамках некоторых форматов пройдут онлайн-голосования, что позволит вовлечь аудиторию. Следить за онлайн-трансляцией можно будет на официальном [сайте](https://kids-forum.ru/stream) события и на портале [Культура.рф](https://www.culture.ru/).

Организатор Международного детского культурного форума – Министерство культуры Российской Федерации. Соорганизаторы форума: Правительство Москвы и ВДНХ. Партнеры: Министерство просвещения и Министерство промышленности и торговли Российской Федерации, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история», сообщество «Большая перемена», Лига юных журналистов. Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».

Ознакомиться с подробной программой форума можно по [ссылке](https://disk.yandex.ru/d/Nqgvqv0Qx1G0gA).
            `,
    },
    {
        title: 'До 3 миллионов рублей господдержки получит лучший проект Детского культурного форума',
        date: '22.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-003.jpg',
        teaser: 'Об этом Министр культуры Российской Федерации Ольга Любимова сообщила в ходе итогового заседания организационного комитета Детского культурного форума.',
        full:
            `*Об этом Министр культуры Российской Федерации Ольга Любимова сообщила в ходе итогового заседания организационного комитета Детского культурного форума*.

Первый Детский культурный форум пройдет в Москве уже на этой неделе, с 25 по 27 августа 2022 года на ВДНХ. Свыше полутора тысяч гостей ждёт насыщенная программа из более чем 60 мероприятий просветительского и фестивального формата. Это мотивационные встречи с ведущими деятелями культуры, мастер-классы, экскурсии, арт-школы и лекции. Форум станет уникальной площадкой, где одаренные дети встретятся друг с другом, педагогами, родителями, культурными, государственными и общественными деятелями для того, чтобы заявить о своем видении развития культуры и собственных потребностях для успешной творческой реализации.

Одним из знаковых мероприятий форума станет презентация проектов, направленных на поддержку отрасли культуры и детского творчества. Как сообщила Ольга Любимова, лучший проект сможет получить государственную поддержку: «Дети со всех регионов России активно готовятся к форуму. Регионы присылали работы своих претендентов. Оргкомитет форума отобрал 6 самых ярких проектов, которые мы все сможем увидеть и оценить на форуме. Самый яркий проект получит финансовую поддержку. Мы определили, что поддержка составит до 3 миллионов рублей, в зависимости от стоимости реализации того проекта, который будет признан лучшим».

В заседании оргкомитета также приняли участие пианистка, солистка Московской государственной академической филармонии, заслуженная артистка Российской Федерации Екатерина Мечетина, актер, ректор Театрального института им. Б. Щукина Евгений Князев, юная ведущая мероприятий Детского культурного форума Мария Попова.

«Мне кажется, что такой форум будет одним из поворотных моментов для некоторых ребят. И без всякого преувеличения можно сказать, что для кого-то определится судьба», – отметила спикер форума Екатерина Мечетина.

В свою очередь Евгений Князев отметил, что в России есть очень много талантливых детей во всех областях.

«Какое счастье, что детьми сейчас занимаются на серьезном уровне», – сказал он. По его словам, форум может стать площадкой, на которой будут зарождаться творческие кадры.

Юная ведущая форума Мария Попова призналась, что надеется на форуме обрести команду единомышленников.

«Культурный форум – это не только новые идеи, свежие проекты и стартапы, это еще и сохранение нашего богатого культурно-исторического наследия. Ребята предложат облечь те или иные виды искусства в новую интересную форму, которая будет современной, а уже взрослые профессионалы помогут грамотно наполнить это содержанием», – сказала она.

Просветительская и фестивальная программы стартуют 25 августа. Открытие просветительской программы пройдет в интерактивном формате. Сергей Безруков, Игорь Бутман, Елена Гагарина, Юлиана Слащева, Николай Цискаридзе по очереди расскажут свои творческие истории, а также поделятся личным опытом успеха и преодоления вызовов на сессии «Ребенок и культура. Диалог поколений». Итогом встречи станет творческий манифест: от наставников – юным талантам.

*Источник: Пресс-служба Министерства культуры Российской Федерации*.
            `,
    },
    {
        title: 'Официальное приветствие участникам и гостям Форума передали Сергей Собянин и Вячеслав Володин',
        date: '23.08.2022',
        photo: '',
        teaser: 'Официальные письма-приветствия в  адрес участников Форума направили мэр Москвы Сергей Собянин и Председатель Государственной Думы Вячеслав Володин.',
        full:
            `*Официальные письма-приветствия в адрес участников Форума направили мэр Москвы [Сергей Собянин](https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FE9LyBHuXYdS%2B86JDFBvwIWIAaYpBYyPZH9s2COPC5rV0geVkyCtqzbVuhbGGgLwD%2FCH%2B%2BsnE5duAiqM%2FEjDILQ%3D%3D&name=С.С.Собянин_участникам%20ДКФ.pdf&nosw=1) и Председатель Государственной Думы [Вячеслав Володин](https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FE9LyBHuXYdS%2B86JDFBvwIeTfi8YM2KjwL9HfmjlUVvpldsn0BZfJ6TkQV4XnUQ65lsg4VIaLrfRKbQetv4dueQ%3D%3D&name=В.В.Володин_участникам%20ДКФ.pdf&nosw=1)*. 

Мэр Москвы Сергей Семенович Собянин официально поприветствовал организаторов, участников и гостей Детского культурного форума. «Москва рада встрече с юными талантами из разных стран. Ярко проявляя себя в искусстве, вы привносите свежие идеи, создаете удивительные произведения, дарите поклонникам настроения праздника,» — говорится в официальном письме. 

Мэр заверил гостей и участников, что Форум в российской столице даст новый импульс детскому творчеству и добавил, что участников ждут увлекательные мастер-классы, интересные дискуссии, познавательные лекции, незабываемые встречи с выдающимися деятелями искусства, а также экскурсии по столице и знакомство с ее культурой и традициями. 

Сергей Семенович Собянин в своем обращении пожелал участникам, чтобы Форум  надолго запомнился искренними эмоциями и душевной атмосферой, расширил горизонты дружбы и сотрудничества. В заключение мэр также пожелал безграничного общения, ярких впечатлений, больших творческих успехов и новых встреч на гостеприимной московской земле всем участникам Форума.  

Также с приветственным письмом организаторам, участникам и гостям Первого международного детского культурного форума обратился Председатель государственной думы Вячеслав Викторович Володин. В своем письме он подчеркнул новые возможности для поддержки одаренных детей и совершенствования системы образования, которые открывает Форум. Вячеслав Викторович Володин также особое внимание уделил насыщенной программе, которая ждет участников Форума. «Интересы и приоритеты молодежи, ее видение настоящего и будущего очень важны. Необходимо, учитывая их, помогать в творческом развитии и выборе жизненного пути,» — отметил Председатель Государственной думы. В заключение В. В. Володин также пожелал участникам реализовать свою мечту, успехов и всего наилучшего.
            `,
    },
    {
        title: 'Делегатов Форума приветствуют М.В.Мишустин, В.И.Матвиенко, С.В.Кириенко и Патриарх Московский и всея Руси Кирилл',
        date: '24.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-004.jpg',
        teaser: 'С приветствием к организаторам, участникам и гостям Первого международного Детского культурного форума обратились Председатель Правительства Российской Федерации Михаил Владимирович Мишустин, Председатель Совета Федерального собрания Российской Федерации Валентина Ивановна Матвиенко, Первый заместитель Руководителя Администрации Президента Российской Федерации Сергей Владиленович Кириенко и Патриарх Московский и всея Руси Кирилл.',
        full:
            `*С приветствием к организаторам, участникам и гостям Первого международного Детского культурного форума обратились Председатель Правительства Российской Федерации [Михаил Владимирович Мишустин](https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FE9LyBHuXYdS%2B86JDFBvwISBmFD3as4dXOQ5pnUuMaVL688TUPtHR6PvWHpkFXBkblsg4VIaLrfRKbQetv4dueQ%3D%3D&name=М.В.Мишустин_участникам%20ДКФ.pdf&nosw=1), Председатель Совета Федерального собрания Российской Федерации [Валентина Ивановна Матвиенко](https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FE9LyBHuXYdS%2B86JDFBvwIQLatEUmoUnSRKSOHh9in5SJqN3URg4EB41c9%2FYDee%2BL%2Bb3nCKLCVTJ%2BSInaOUvvHQ%3D%3D&name=В.И.Матвиенко_участникам%20ДКФ.pdf&nosw=1), Первый заместитель Руководителя Администрации Президента Российской Федерации [Сергей Владиленович Кириенко](https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FE9LyBHuXYdS%2B86JDFBvwId%2BecEWM3WjML1sKVvZhWDcr0KvRRd5yqSLHqYNbe1lMG7pWpVQYsubyrcajHimPFw%3D%3D&name=С.В.Кириенко_участникам%20ДКФ.pdf&nosw=1) и [Патриарх Московский и всея Руси Кирилл](https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FE9LyBHuXYdS%2B86JDFBvwIRv0KfZ9SBEzh4em0gu4tCPq2zcyh%2FRVoNAzGqyN4927EkI0e0it%2FP53JjBKdrjFug%3D%3D&name=Патриарх%20Кирилл_участникам%20ДКФ.pdf&nosw=1)*.
          
Они подчеркнули важность мероприятия для каждого участника и всей страны. М. В. Мишустин отметил, что Форум — это уникальное пространство, объединяющее тысячи инициативных и одаренных юношей и девушек из всех регионов России и других стран. 

«Главное — Форум даст возможность для интеллектуального общения и живого диалога. Дети и взрослые смогут вместе найти вопросы на интересующие ответы. Обсудить будущее страны в сфере культуры,» — говорится в обращении Председателя Правительства Российской Федерации.

«Сегодня, когда мир меняется необратимо, мы можем и должны предложить не только для себя, но и для всех, вместо культуры отмены, культуру уважения, культуру справедливости, традиций, воспитания, культуру открытий и движений вперёд. 

На примере национального чемпионата творческих компетенций «ArtMasters», всероссийского проекта «Школьная классика» Российского движения школьников, конкурса «Большая перемена» и целого ряда других имеющихся в нашей стране полезных межотраслевых площадок мы видим, как талантливые юные ребята имеют возможность весьма успешно реализовывать свой творческий потенциал,» — отмечает в своем обращении С. В. Кириенко. 

Валентина Ивановна Матвиенко отметила, что Форум объединил более 1500 ребят из России и других государств, в том числе из Донецкой Народной Республики и Луганской Народной Республики, что доказывает, что культура была и остается важным каналом взаимодействия, открывает возможности для укрепления мира и согласия между народами. 

«Каждый человек на протяжении своей жизни чему-то учится и в чем-то совершенствуется. Но основной интеллектуальный и творческий импульс мы получаем в детстве и юности. Именно в это время закладывается тот фундамент базовых понятий и духовных ценностей, на котором впоследствии строится вся наша жизнь. Знания и навыки, полученные в период учебы и становления личности, формируют человека и во многом определяют то место, которое он будет занимать в обществе,» – говорится в обращении Патриарха Московского и всея Руси Кирилла. 

В своих документах они также выразили уверенность в том, что Форум будет полезен как каждому участнику, так и всей стране в целом и поспособствует созданию и претворению в жизнь новых интересных проектов, а также отметили насыщенную программу Форума и пожелали участникам и гостям творческой удачи, вдохновения, радости общения друг с другом и незабываемых впечатлений от Форума.
            `,
    },
    {
        title: 'В Москву съезжаются делегации на Детский культурный форум',
        date: '24.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-005.jpg',
        teaser: 'Уже в этот четверг, 25 августа, стартуют просветительская и фестивальная программы Детского культурного форума – уникальной площадки для обмена мнениями, идеями между детьми и взрослыми. Форум будет проходить в течение трех дней на ВДНХ.',
        full:
            `*Уже в этот четверг, 25 августа, стартуют просветительская и фестивальная программы Детского культурного форума – уникальной площадки для обмена мнениями, идеями между детьми и взрослыми. Форум будет проходить в течение трех дней на ВДНХ.*.

Молодые ребята в возрасте от 12 до 17 лет со всех регионов России и из-за рубежа смогут найти новых друзей, раскрыть свои таланты, познакомиться с деятелями культуры с мировым именем. Их ждут более 60 различных активностей: лекции, открытые диалоги, дискуссии с участием выдающихся деятелей искусств, мастер-классы и творческие мастерские, где ребята смогут продемонстрировать свои достижения и поделиться творческим опытом со сверстниками. Основные направления мероприятия: музыкальное, хореографическое, театральное и изобразительное виды искусств, кино и анимация, музеи и выставки, традиционные и новые медиа. В числе спикеров кумиры миллионов россиян: Сергей Безруков, Игорь Бутман, Елена Гагарина, Юлиана Слащева, Николай Цискаридзе, Нонна Гришаева, Константин Хабенский, Александр Олешко, Петр Дранга и др. 

А пока Москва встречает делегации со всей России и из-за рубежа, которые приехали на форум. Всего в мероприятии примут участие более 1,5 тысяч детей из всех 85 субъектов Российской Федерации и 12 зарубежных стран. Среди них 500 ребят – участников Всероссийского конкурса для школьников «Большая перемена», а также 30 молодых медийщиков из Лиги юных журналистов. Они будут освещать события форума: вести прямые трансляции в формате ток-шоу и видеодневников, брать интервью у участников и спикеров форума, создавать контент для социальных сетей. 

*Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра»*.

    `,
    },
    {
        title: 'Детский культурный форум открылся в Москве',
        date: '25.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-006.jpg',
        teaser: '25 августа на ВДНХ в павильоне № 57 «Россия – моя история» стартовал первый Детский культурный форум – новое уникальное мероприятие, где встретятся юные таланты, полные идей, энергии и стремления развиваться, творить и созидать, и педагоги, родители, культурные, государственные и общественные деятели.',
        full:
            `*Церемония открытия прошла на главной сцене, установленной возле фонтана «Дружба народов».  Участие в церемонии приняла Министр культуры Российской Федерации Ольга Любимова, ведущими стали Тутта Ларсен и ее дочь Марфа Колоскова.*

Выступая на открытии, Ольга Любимова зачитала приветствие участникам от Президента Российской Федерации Владимира Путина. В нем Президент высказал уверенность, что нынешние школьники непременно добьются успеха.

*«Их отличает неиссякаемая энергия, целеустремленность, умение мыслить нестандартно, находить смелые, интересные решения», – говорилось в приветствии.* 

По словам Министра, участниками Форума стали почти 2000 юных участников из всех регионов страны. Также в Москву приехали ребята из 12 стран: Беларуси, Южной Осетии, Сербии, Таджикистана, Узбекистана, Туркменистана, ДНР, ЛНР, Бахрейна, Ирана, Египта и Абхазии.

*«Сегодня у вас есть возможность встретиться на легендарной московской площадке, найти новых друзей, коллег, пообщаться, а в будущем, возможно, создать свои интересные, яркие проекты, чего я вам искренне, от всего сердца, желаю. А мы со своей стороны постараемся помогать вам в этом», – сказала Ольга Любимова.* 

Затем юные делегаты-именинники одновременно нажали кнопку и запустили Колесо Творчества, что символизировало собой открытие мероприятия, после чего ведущие объявили, что Детский культурный форум начинает свою работу и пригласили участников шествием пройти до павильона. Колонна во главе с почетными гостями, флагоносцами и делегатами форума проследовала к павильону № 57, где их встретили персонажи Союзмультфильма и волонтеры. Длина колонны составила 300 метров, во время шествия участники сделали 3 млн 360 тысяч шагов. 

Общая площадь зоны фестиваля составила 10 тысяч квадратных метров. Здесь в течение трех дней детей ждет более 60 разных активностей: лекции, открытые диалоги, дискуссии с участием выдающихся деятелей искусств, мастер-классы и творческие мастерские, где ребята смогут продемонстрировать свои достижения и поделиться творческим опытом со сверстниками. Ключевые направления форума этого года: музыкальное, хореографическое, театральное и изобразительное виды искусств, кино и анимация, музеи и выставки, традиционные и новые медиа. 

Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».

    `,
    },
    {
        title: 'Открывающая сессия «Ребенок и культура. Диалог поколений»: 5 деятелей культуры рассказали о своем пути к успеху',
        date: '25.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-007.jpg',
        teaser: 'В Москве открылся Детский культурный форум. Первым мероприятием просветительской программы стала открывающая сессия «Ребенок и культура. Диалог поколений». Пять знаковых деятелей культуры по очереди рассказали детям свою творческую историю, поделились личным опытом успеха и дали совет-наставление участникам форума. Ведущими сессии стали Тутта Ларсен и ее дочь Марфа Колоскова.',
        full:
            `*В Москве открылся Детский культурный форум. Первым мероприятием просветительской программы стала открывающая сессия «Ребенок и культура. Диалог поколений». Пять знаковых деятелей культуры по очереди рассказали детям свою творческую историю, поделились личным опытом успеха и дали совет-наставление участникам форума. Ведущими сессии стали Тутта Ларсен и ее дочь Марфа Колоскова.*

Первым выступил актер, художественный руководитель Московского губернского театра Сергей Безруков. 

«Главное –  найти своего мастера и учителя», – таково его главное наставление.  

Игорь Бутман, художественный руководитель Московского джазового оркестра под управлением Игоря Бутмана, озвучил свое напутствие юным творцам культуры: «Пишите, играйте, смотрите, изучайте. Будьте смелыми и заявляйте о себе. Не стесняйтесь просить у нас совета и помощи. Мы вас обязательно поддержим!»

«Основной мотив моей жизни – сделать что-нибудь полезное для людей, не прожить даром жизнь, продвинуть человечество хоть немного вперед», – озвучила свою формулу успеха генеральный директор Музеев Московского Кремля Елена Гагарина. 

По словам Председателя Совета директоров киностудии «Союзмультфильм», генерального директора Центральной киностудии детских и юношеских фильмов им. М. Горького Юлианы Слащевой, важно верить в себя, свой опыт и идею, которая придает смысл каждому вашему шагу. 

«Конечно же, необходима поддерживающая среда, команда, люди на пути, которые также, как и вы разделяют эту идею. Все это дает нужную опору и позволяет смотреть в неизвестность не со страхом, а с неподдельным интересом, ровно как герои знаменитого мультфильма «38 попугаев», которым все «ужасно интересно», – отметила она.

В завершение Юлиана Слащева подытожила тему своего выступления призывом: «Не бойтесь менять и меняться!». 

Последним на сессии выступил Николай Цискаридзе, ректор Академии русского балета 
им. А. Я. Вагановой. «Элита – это люди, которых уважают все!», – так звучит его личный манифест. 
После всех выступлений зрители могли задать вопросы спикерам, взять автографы, а также проголосовать за ту идею наставничества, которую они считают наиболее важной. Победителем стала идея Игоря Бутмана: «Пишите, играйте, смотрите, изучайте. Будьте смелыми и заявляйте о себе. Не стесняйтесь просить у нас совета и помощи. Мы вас обязательно поддержим!» – она получила более 30% голосов. 

*Справочно:  
В течение трех дней участников ждет более 60 различных активностей просветительского и фестивального формата по 7 ключевым направлениям: музыкальное, хореографическое, театральное и изобразительное виды искусств, кино и анимация, музеи и выставки, традиционные и новые медиа.
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».*

    `,
    },
    {
        title: 'Приветствие от президента РФ Владимира Путина прозвучало в рамках церемонии открытия Форума',
        date: '25.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-008.jpg',
        teaser: 'Президент Российской Федерации Владимир Владимирович Путин поприветствовал участников, организаторов и гостей Первого международного детского культурного форума. Обращение зачитала министр культуры Российской Федерации Ольга Борисовна Любимова  в рамках торжественной церемонии открытия.',
        full:
            `*Президент Российской Федерации [Владимир Владимирович Путин](https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FE9LyBHuXYdS%2B86JDFBvwITtXik3I68t6jCMeqIgqj6%2FqgeeqKm4ujcKeNVOXuSVWLnwuG65u%2BCaUWt4uUumLKA%3D%3D&name=В.В.Путин_участникам%20%20ДКФ.pdf&nosw=1) поприветствовал участников, организаторов и гостей Первого международного детского культурного форума.*

Президент отметил, что в рамках Форума на ВДНХ была создана уникальная площадка, где каждый сможет в полной мере раскрыть свои таланты и возможности, обрести новые знания и приблизиться к осуществлению заветной мечты. 

«Отрадно, что форум охватывает самые разные творческие жанры и направления — музыку и живопись, хореографическое и театральное искусство, кинематограф и анимацию, и каждый из вас — обязательно найдёт себе дело по душе и призванию. Ведь именно вам, нынешним школьникам, предстоит определять будущее культуры, бережно хранить и развивать ее великое наследие.» — говорится в обращении Президента. 

Владимир Владимирович Путин выразил уверенность, что благодаря неиссякаемой энергии, целеустремленности, умению мыслить нестандартно, находить смелые, интересные решения участники Форума и их сверстники непременно добьются успеха. Он также добавил, что где бы они ни трудились в дальнейшем, важным неиссякаемым источником вдохновения для них всегда будут служить вечные традиционные ценности — любовь, милосердие, патриотизм. 

В заключение Президент пожелал участникам форума удачи, ярких и дерзновенных идей и замыслов.

    `,
    },
    {
        title: 'Ребёнок из Башкортостана стал победителем Детского культурного форума',
        date: '25.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-009.jpg',
        teaser: 'Завершается первый день работы Детского культурного форума. Одним из ключевых мероприятий дня стал Открытый диалог с Министром культуры Российской Федерации Ольгой Любимовой «Культурная инициатива».',
        full:
            `*Завершается первый день работы Детского культурного форума. Одним из ключевых мероприятий дня стал Открытый диалог с Министром культуры Российской Федерации Ольгой Любимовой «Культурная инициатива».*

Талантливые ребята из разных городов России представили на конкурс проекты по направлениям просветительской программы Форума, а также по развитию культурного сообщества в своих регионах. Из 32 проектов в шорт-лист вошли 12. Они были дополнительно проработаны авторами по комментариям Оргкомитета форума, после чего по результатам собеседований и совместной работы были отобраны 7 авторов для участия в Открытом диалоге. Они представили Ольге Любимовой свои проекты. 

Проект «100 вещей, которые нужно сделать в Приволжье» от Арсения Гайнуллина из Уфы предполагает создание цикла выпусков подростковых видеоблогов о том, что нужно узнать, попробовать, испытать, увидеть в регионе проживания автора. Автор убежден: «Проект станет успешным и сможет объединить ребят со всей страны, пробудить интерес к изучению наших корней, истории страны и культуры». 

Тимур Рысаев из Казани в своем проекте предлагает разработать аудио-тактильный комплекс макета архитектурного ансамбля Казанского кремля. Незрячим будет выдаваться специальная метка, представляющая собой кольцо или браслет, а также наушники. При ознакомлении с объектом посетителю будет воспроизводиться аудиоконтент именно того объекта, который он в данный момент ощупывает.

Проект Софии Афанасенко из Иркутска предполагает организацию киноплощадки Международного Байкальского фестиваля детских фильмов «Чистый взгляд», на которой будут проходить показы лучших фильмов фестиваля, обсуждения с профильными специалистами и знаменитостями, киноэкспедиции по Иркутской области, мастер-классы и другие активности. 

Проект «Всероссийская Ассоциация юных композиторов» от Дарьи Божченко из Нового Уренгоя задуман, чтобы помочь молодым композиторам на начальном этапе, создать для них широкий круг возможностей для демонстрации своего творчества. 

*«Детское композиторское творчество в настоящее время очень актуально, но, к сожалению, не у каждого автора есть возможность получить экспертную помощь, представить свое творчество на престижные всероссийские конкурсы», – считает Дарья.* 

Анастасия Данилова из г. Иваново своим проектом «Всероссийский пилотный проект «Искусство жить» для людей с ОВЗ» хочет создать условия для гармоничного взаимодействия детей и молодежи с детьми с особыми потребностями при помощи современных форм инклюзивного творчества.

Суть проекта «ГАМЗАТОВ-КВЕСТ. К 100-летию Расула Гамзатова» Шамсият Алиевой из Каспийска в разработке и запуске в общий доступ мобильного приложения, где будут отражены культурные объекты, связанные с творчеством Р. Гамзатова, расположенные в разных частях света.

Последним финалистом стал проект «Амбассадоры Пушкинской карты» от Дарьи Подстреловой из г. Благовещенск. Она предлагает организовать и провести концерты в четырех муниципальных образованиях Амурской области для информирования молодежи о возможностях программы «Пушкинская карта», презентовать культурные учреждения Амурской области и рассказать, какие мероприятия можно посетить по карте.

По итогам очной защиты Ольга Любимова озвучила решение включить инклюзивное направление в программу следующего Детского культурного форума, а затем выбрала лучший проект, который получит господдержку до трех миллионов рублей. Им стал проект Арсения Гайнуллина «100 вещей, которые нужно сделать в Приволжье». Министр наградила победителя, вручив остальным финалистам грамоты, дающие возможность работать с Минкультуры России.

*«В Министерстве культуры на каждого найдется отклик, своя программа и своя команда, которая с удовольствием с вами потрудится. Это и молодые ребята с портала «Культура.РФ», и команда, которая занимается инклюзией, и ребята, которые занимаются регионами, путешествиями, экскурсиями, потому все проекты финалистов, безусловно, будут поддержаны», – подчеркнула Министр.* 

Справочно:  
В течение трех дней участников ждет более 60 различных активностей просветительского и фестивального формата по 7 ключевым направлениям: музыкальное, хореографическое, театральное и изобразительное виды искусств, кино и анимация, музеи и выставки, традиционные и новые медиа.

Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».

    `,
    },
    {
        title: 'Приветствие участникам Форума передал Министр просвещения Российской Федерации С.С. Кравцов',
        date: '25.08.2022',
        photo: '',
        teaser: 'Министр просвещения Российской Федерации Сергей Сергеевич Кравцов поприветствовал участников и организаторов Первого международного Детского культурного форума. ',
        full:
            `*Министр просвещения Российской Федерации [Сергей Сергеевич Кравцов](https://docs.yandex.ru/docs/view?url=ya-disk-public%3A%2F%2FE9LyBHuXYdS%2B86JDFBvwIWbMUeqctf2WJTi8%2FzLdgTKdD4YY1J2E8u6penZJuSATG7pWpVQYsubyrcajHimPFw%3D%3D&name=Кравцов%20С.С._участникам%20Форума.pdf&nosw=1) поприветствовал участников и организаторов Первого международного Детского культурного форума.*

«Отрадно, что это мероприятие объединяет на своих площадках столько талантливых, одаренных ребят из России и зарубежных государств, чтобы приобщить их к мировым достижениям в области культуры и искусства и, конечно, дать им возможность пообщаться, обрести новых друзей и товарищей,» — говорится в тексте документа. 
Сергей Сергеевич отметил, что контакты в культурной сфере играют огромную консолидирующую роль и способствуют развитию межкультурного диалога, помогают находить точки соприкосновения. 
Он пожелал участникам и организаторам, чтобы форум прошел на высоком уровне и оставил у всех самые яркие и добрые впечатления.

    `,
    },
    {
        title: 'Завершился первый день Детского культурного форума. Подводим итоги ',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-015.jpg',
        teaser: 'В Москве открылся первый Детский культурный форум. Павильон №57 «Россия – моя история» на ВДНХ на три дня стал площадкой, собравшей почти две тысячи детей из всех регионов Российской Федерации и 12 стран мира: Беларуси, Южной Осетии, Сербии, Таджикистана, Узбекистана, Туркменистана, ДНР, ЛНР, Бахрейна, Ирана, Египта и Абхазии.',
        full:
            `*В Москве открылся первый Детский культурный форум. Павильон №57 «Россия – моя история» на ВДНХ на три дня стал площадкой, собравшей почти две тысячи детей из всех регионов Российской Федерации и 12 стран мира: Беларуси, Южной Осетии, Сербии, Таджикистана, Узбекистана, Туркменистана, ДНР, ЛНР, Бахрейна, Ирана, Египта и Абхазии.*

Форум — новое, уникальное место встречи детей с педагогами, родителями, культурными, государственными и общественными деятелями. В течение трех дней его участников ждет более 60 различных активностей просветительского и фестивального формата, они обретут новые знания и заведут друзей.

Церемония открытия форума состоялась на главной сцене, установленной возле фонтана «Дружба народов». Участие в церемонии приняла Министр культуры Российской Федерации Ольга Любимова, а ведущими стали Тутта Ларсен и ее дочь Марфа Колоскова. 

После торжественного шествия к павильону №57 Ольга Любимова совершала обход выставочных стендов, представленных на площадке. Среди них был стенд «Волонтеров культуры», «Большой перемены», VK, «Школы креативных индустрий», «Музея военной истории РВИО», «Школьной классики», «Культуры для школьников» и российского общества «Знание». 

Первым мероприятием просветительской программы стала открывающая сессия «Ребенок и культура. Диалог поколений». Пять знаковых деятелей культуры: актер, художественный руководитель Московского губернского театра Сергей Безруков, художественный руководитель Московского джазового оркестра Игорь Бутман, генеральный директор Музеев Московского Кремля Елена Гагарина, председатель Совета директоров киностудии «Союзмультфильм», генеральный директор Центральной киностудии детских и юношеских фильмов им. М. Горького Юлиана Слащева и Николай Цискаридзе, ректор Академии русского балета им. А. Я. Вагановой рассказали детям свою творческую историю, поделились личным опытом успеха и дали совет-наставление участникам форума. 

Одним из ключевых мероприятий первого дня стал Открытый диалог с Ольгой Любимовой «Культурная инициатива». Юные таланты из разных городов России представили на конкурс проекты по развитию культурного сообщества в своих регионах. Министр выбрала лучший проект — им стал проект Арсения Гайнуллина «100 вещей, которые нужно сделать в Приволжье». Он получит господдержку до трех миллионов рублей. 

На лекции ректора Московского государственного академического института им. В. И. Сурикова Анатолия Любавина школьники смогли увидеть историю России через призму шедевров изобразительного искусства. 

Участники, посетившие Диалог со знаковым деятелем культуры «Анимания: сложно, но очень интересно», отправились в увлекательное путешествие в мир анимации с самым лучшим гидом — человеком, который знает про этот удивительный мир все: Юлианой Слащевой. 

Мастер-класс «Дизайн-мышление как способ решения проблем» был посвящен творческим подходам в решении любых задач. Дети узнали, как с помощью творческого подхода и техники скетчинг можно увидеть ситуацию под другим углом и найти нестандартное решение. Участники мастер-класса вместе с преподавателем придумывали, разработали и презентовали несколько дизайн-идей по решению задач.

В рамках интерактивной программы «Мифы о музыкальных инструментах» делегатам форума рассказали множество удивительных историй о народных музыкальных инструментах, преподав первые навыки игры на них. 

Всего в первый день состоялось около 30 различных активностей: лекций, мастер-классов, диалогов, мастерских, сессий, фестивалей и арт-школ. Дети узнали про ведение блогов, театр, музыку, современное искусство, актерское мастерство и многое другое. 

Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».

    `,
    },
    {
        title: 'Двусторонняя встреча с Ираном состоялась в рамках Детского культурного форума',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-017.jpg',
        teaser: '26 августа на площадке первого Детского культурного форума состоялась презентация творческого образования Исламской Республики Иран. Иран – одна из зарубежных стран, направивших делегацию для участия в форуме.',
        full:
            `*26 августа на площадке первого Детского культурного форума состоялась презентация творческого образования Исламской Республики Иран. Иран – одна из зарубежных стран, направивших делегацию для участия в форуме.*.

Встреча стала логичным продолжением активно развивающегося сотрудничества двух стран. Российскую Федерацию представила заместитель Министра культуры Российской Федерации Ольга Ярилова, региональные министры культуры, а также руководители крупных культурных институций. Со стороны Ирана в ней приняли участие глава Культурного представительства при Посольстве Ирана в Российской Федерации Масуд Ахмандванд и члены делегации страны во главе с руководителем Департамента по работе с международными организациями и культурной деятельности Министерства культуры и исламской ориентации Исламской Республики Иран Эсмаилом Джаналипуром. 

Приветствуя иранских гостей, Ольга Ярилова отметила: «В России с большим интересом относятся к культуре Ирана. Я думаю, что наша сегодняшняя встреча придаст новый импульс развитию отношений в сфере творческого образования детей и подростков».

Члены иранской делегации в ходе своих выступлений рассказали о культурных мероприятиях, проходящих в Иране, в частности,  
о развлекательных и образовательных проектах Центра «Светлый город», Фестивале детских анимационных фильмов, Международном фестивале короткометражных фильмов для учеников школ Madrese Student Short Film Festival, а также поделились опытом развития книгоиздания для детей 
и подростков, разработки инновационных игр и организации специальных детских культурных центров. 

Директор и основатель детского инновационного центра «Ала», иранский детский писатель Маджид Фаязи так прокомментировал работу своей организации: «Культурные инновации гораздо сложнее, чем промышленные, и люди, которые стремятся развивать проекты, связанные с духовной сферой, обычно не ищут деньги и реализуют свои проекты просто так. И мы, в свою очередь, горячо их поддерживаем».

В ответ ректор Российской академии музыки имени Гнесиных Александр Рыжинский предложил организовать в Тегеране творческую школу для иранских коллег с проведением мастер-классов и открытых уроков.

Исполнительный директор Ассоциации анимационного кино Ирина Мастусова высоко оценила уровень организации кинофестивалей для детей и подростков в Иране и со своей стороны предложила иранским партнерам посетить киностудию «Союзмультфильм», чтобы ближе познакомиться с российским мультипликационным производством и обсудить совместные проекты. 

Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».
 
Культурные, социальные, экономические отношения между Ираном и Россией динамично развиваются, между странами идет активный культурный обмен. Так, в марте 2022 года во Всероссийской государственной библиотеке иностранной литературы им. М. И. Рудомино прошли Дни иранской культуры и искусства, приуроченные к празднику прихода весны «Навруз». В августе 2022 года состоялись переговоры между статс-секретарем – заместителем Министра культуры Российской Федерации Аллой Маниловой заместителем Министра культуры и исламской ориентации Исламской Республики Иран М. Хазаи, в рамках которых были обозначены основные приоритеты дальнейшего развития сотрудничества: поддержка проектов детско-юношеского кино, проведение обменных кинофестивалей и кинофорумов и совместное кинопроизводство. По итогам встречи был подписан межведомственный Меморандум о сотрудничестве в области кинематографии.   

    `,
    },
    {
        title: 'На форсайт-сессии Детского культурного форума приняли Культурный манифест нового поколения',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-018.jpg',
        teaser: 'Важнейшим форматом второго дня Детского культурного форума стала форсайт-сессия «Культурный манифест нового поколения». Ее участники разбирались, какой должна быть культура будущего и какие изменения необходимы и возможны уже сейчас.',
        full:
            `*Важнейшим форматом второго дня Детского культурного форума стала форсайт-сессия «Культурный манифест нового поколения». Ее участники разбирались, какой должна быть культура будущего и какие изменения необходимы и возможны уже сейчас.*.

Работа проходила в два этапа. Сначала 150 детей были поделены на команды. В рамках первой части сессии каждая команда разрабатывала план развития отдельного направления культуры, чтобы по итогам представить его Министру культуры Российской Федерации Ольге Любимовой и участникам Форума. 

Направлениями для проработки командами форсайт-сессии стали темы: литература, музеи и выставки, музыкальное искусство, традиционные и новые медиа, изобразительное искусство, кино и анимация, театральное искусство, хореографическое искусство, культура для школьников, школы креативных индустрий, Пушкинская карта, волонтеры культуры, инклюзия, детские школы искусств и школьные театры. 

Для презентации плана развития своего направления каждая из команд выбрала одну ключевую идею, которую и защищала уже в ходе минутного доклада перед Ольгой Любимовой и зрителями. 

Так, команда литературы своей идеей-манифестом сделала «Мы за культуру, в которой читать – это модно!», команда музеев и выставок – «Мы за культуру, в которой шедевры доступны каждому!», команда кино и анимации – «Мы за культуру, где наше кино круче Голливуда!», команда волонтеров культуры – «Мы за культуру, где волонтеры не менее важны, чем в медицине!», а команда хореографического искусства решила: «Мы за культуру, где в каждом доме живет танец!». 

После представления командами все идеи были выставлены на голосование зрителям. Ольга Любимова прокомментировала презентацию ребят: *«Совершенно блистательные идеи, которые нам стоит забрать в Министерство культуры, поработать в течение года и посмотреть, насколько поменяется ситуация, что нужно еще доделать, что станет более актуальным, что менее. Это будет нашей общей задачей на будущее».* 

По результатам зрительского голосования лучшей была выбрана идея команды хореографического искусства: «Мы за культуру, где в каждом доме живет танец!» – она набрала более 15% голосов. Все озвученные идеи команд вошли в «Культурный манифест нового поколения», принятый по итогам форсайт-сессии. 

**Культурный манифест нового поколения**  
 Мы за культуру, в которой читать – это модно!  
Мы за культуру, в которой шедевры доступны каждому!  
 Мы за культуру, в которой у каждого молодого творца есть наставник!  
 Мы за культуру, где людей не делят на «всех» и «особенных»!  
 Мы за культуру, где наше кино круче Голливуда!  
 Мы за культуру, где в каждом доме живет танец!  
 Мы за культуру, где у молодых есть творческие лифты!  
 Мы за культуру, где волонтеры не менее важны, чем в медицине!  
 Мы за культуру, где ДШИ – центры вселенной!  
 Мы за культуру, где у каждого школьника есть Пушкинская карта!  
 Мы за культуру, которая нас объединяет!


Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».

    `,
    },
    {
        title: 'На Детском культурном форуме показали работы финалистов Всероссийского проекта «Школьная классика»',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-019.jpg',
        teaser: 'На Детском культурном форуме состоялась встреча ребят с удивительным миром школьных театров. В рамках интерактивной лекции перед участниками форума выступил начальник отдела проектной деятельности по делам молодежи Театрального института имени Вахтангова, актер, режиссер, театральный педагог, писатель и главный режиссер Всероссийского проекта «Школьная классика» Валентин Стасюк.',
        full:
            `*На Детском культурном форуме состоялась встреча ребят с удивительным миром школьных театров. В рамках интерактивной лекции перед участниками форума выступил начальник отдела проектной деятельности по делам молодежи Театрального института имени Вахтангова, актер, режиссер, театральный педагог, писатель и главный режиссер Всероссийского проекта «Школьная классика» Валентин Стасюк.*

Ребята посмотрели видеофрагменты спектаклей школьных театров, которые вышли в финал первых сезонов проекта «Школьная классика», а также послушали лекцию о знаменитой Вахтанговской школе, по которой занимаются в Театральном институте имени Бориса Щукина при Государственном академическом театре имени Евгения Вахтангова. Кроме того, желающие приняли участие в специальном мастер-классе. Многим из них очень легко дались упражнения на первичные элементы актерской техники, внимание, воображение, фантазию, действие и взаимодействие. 

Развитые актерские способности обязательно пригодятся ребятам в будущем, вне зависимости от выбранной сферы деятельности, отметил Валентин Стасюк: *«Артист – это тот человек, который может максимально разумно управлять не только собой и своими эмоциями, но и при помощи этого держать под контролем ситуацию, аудиторию, настроение, а значит, и влиять на ход событий».* 

Среди участников форума оказалось немало ребят, которые уже сейчас успешно занимаются в школьных театральных кружках и студиях и хотят продолжить осваивать азы актерской профессии, чтобы в будущем покорить сцену. Именно на них рассчитан Всероссийский проект «Школьная классика», который призван развивать школьные театральные студии и вовлекать учащихся в их деятельность, а также поддерживать преподавательские инициативы и обучать педагогов современным театральным методикам. 

Это направление особенно востребовано в регионах, сказал Валентин Стасюк: *«В рамках нашего проекта «Школьная классика» мы выезжаем в регионы, разные города, чтобы собрать заинтересованных людей и с ними поработать, провести бесплатные мастер-классы для школьных театров. Мы были в Крыму, в Артеке, и на фестивале в Курске».*

География проекта с каждым годом расширяется, и организаторы уверены, что вместе с этим вырастет и число школьных театров в России.

Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».

    `,
    },
    {
        title: 'Каким будет кино для детей в России обсудили на Детском культурном форуме',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-020.jpg',
        teaser: 'Перспективы развития детского кинематографа и анимации в России обсудили в рамках Детского культурного форума. В круглом столе «Кино и дети: перезагрузка» приняли участие основатель благотворительного фонда «Маленькое бюро добрых дел», CEO кинокомпании «Фильмы без границ», директор студии «Рок» Анастасия Акопян, генеральный продюсер студии Yellow, Black and White, сооснователь видеосервиса START Эдуард Илоян, юная актриса Виталия Корниенко, а также председатель Совета директоров киностудии «Союзмультфильм», председатель правления Ассоциации организаций индустрии анимационного кино, генеральный директор Центральной киностудии детских и юношеских фильмов имени Максима Горького Юлиана Слащева. ',
        full:
            `*Перспективы развития детского кинематографа и анимации в России обсудили в рамках Детского культурного форума. В круглом столе «Кино и дети: перезагрузка» приняли участие основатель благотворительного фонда «Маленькое бюро добрых дел», CEO кинокомпании «Фильмы без границ», директор студии «Рок» Анастасия Акопян, генеральный продюсер студии Yellow, Black and White, сооснователь видеосервиса START Эдуард Илоян, юная актриса Виталия Корниенко, а также председатель Совета директоров киностудии «Союзмультфильм», председатель правления Ассоциации организаций индустрии анимационного кино, генеральный директор Центральной киностудии детских и юношеских фильмов имени Максима Горького Юлиана Слащева.*

Как отметили спикеры, сегодня производителям контента особенно важно учитывать запросы детской аудитории, которая в корне отличается от взрослой. Необходимо говорить с детьми на их же языке, уверена Юлиана Слащева: 

*«Детское кино — это всегда кино про детей, про их переживания, их проблемы, их желания, которые не всегда понимают взрослые. Я уверена, что дети и подростки хотят смотреть про себе подобных. Самое успешное кино — это то, где нет взрослых линий: «Приключения Электроника», «Приключения Петрова и Васечкина», «Гостья из будущего».*

Сегодня многие советские шедевры анимации получили свое современное продолжение и пришлись по вкусу публике. Яркий тому пример — многосерийный мультфильм «Простоквашино». Один из любимых советских мультфильмов «Летучий корабль» в ближайшее время тоже получит новую жизнь, но уже в кино, рассказала Анастасия Акопян:

*«Вместе с прекрасным режиссером Ильей Учителем, который снял фильм «Стрельцов», после которого все захотели играть в футбол, мы сейчас снимаем очень масштабное кино «Летучий корабль», мы делаем это вместе с «Союзмультфильмом». Это классная сказка, которую вы увидите на большом экране в следующем году на Новый год. Это семейное кино, на которое можно пойти всем вместе, послушать песни прекрасные, на которых мы выросли».* 

Юные участники форума признались, что сейчас своими любимыми фильмами они могут назвать картины голливудских студий, исключение — отечественный «Последний богатырь». Именно за такими зрелищными фильмами будущее, уверен Эдуард Илоян:

*«Кино для детей должно быть развлекательным, родители вместе с детьми должны развлекаться. Это большие аттракционы, это истории с неимоверной графикой, масштабными съемками. Это кино от возраста четыре и до бесконечности».*

Киноэксперты считают, что детское кино должны в том числе делать и сами дети. Спикеры признались, что ждут новых интересных идей именно от детской аудитории. Самая юная участница круглого стола 12-летняя Виталия Корниенко, известная широкой аудитории по своим ролям в фильмах «Лёд-2», «Вампиры средней полосы» и многим другим, поделилась с участниками впечатлениями о работе с кино. Особенно Виталия Корниенко отметила важность сплоченной работы:

*«Мне интересно изучать процесс кино изнутри, как оно создается. Работает огромное количество людей, сплоченная команда, но при этом все делают один проект, одно дело и хотят получить максимально крутой результат, чтобы он понравился зрителю».*

Говорили на круглом столе и том, что большое кино, хоть и для маленьких, но требует больших финансовых вложений. Эксперты отметили, что отбирать проекты для производства нужно тщательнее. Поэтому одним из вопросов дискуссии стало создание детского совета при Министерстве культуры Российской Федерации для оценки проектов в конкурсе детского кино. 

Справочно:
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра». 

    `,
    },
    {
        title: 'Завершился второй день Детского культурного форума в Москве',
        date: '26.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-021.jpg',
        teaser: '26 августа на ВДНХ в павильоне № 57 «Россия – моя история» прошел второй день Детского культурного форума. Насыщенные просветительская и фестивальная программы мероприятия объединили около двух тысяч человек: учащихся, педагогов, культурных, государственных и общественных деятелей. Участниками форума стали не только юные россияне из всех регионов нашей страны, но и ребята еще из 12 стран: Беларуси, Южной Осетии, Сербии, Таджикистана, Узбекистана, Туркменистана, ДНР, ЛНР, Бахрейна, Ирана, Египта и Абхазии.',
        full:
            `*26 августа на ВДНХ в павильоне № 57 «Россия – моя история» прошел второй день Детского культурного форума. Насыщенные просветительская и фестивальная программы мероприятия объединили около двух тысяч человек: учащихся, педагогов, культурных, государственных и общественных деятелей. Участниками форума стали не только юные россияне из всех регионов нашей страны, но и ребята еще из 12 стран: Беларуси, Южной Осетии, Сербии, Таджикистана, Узбекистана, Туркменистана, ДНР, ЛНР, Бахрейна, Ирана, Египта и Абхазии.*

Ключевым событием дня на форуме стала форсайт-сессия, в рамках которой участники вместе с Министром культуры Российской Федерации Ольгой Любимовой разработали Культурный манифест нового поколения. Ребята проанализировали сегодняшние тренды по различным творческим направлениям и сформировали видение будущего в культуре через 15-25 лет. Стремление молодого поколения развиваться в культурной сфере и любовь ко всем формам искусства и творчества нашли отражение в лозунгах манифеста: «Мы за культуру, в которой читать – это модно!», «Мы за культуру, в которой шедевры доступны каждому!», «Мы за культуру, в которой у каждого молодого творца есть наставник!», «Мы за культуру, где людей не делят на «всех» и «особенных»!», «Мы за культуру, где наше кино круче Голливуда!», «Мы за культуру, где в каждом доме живет танец!», «Мы за культуру, где у молодых есть творческие лифты!», «Мы за культуру, где волонтеры не менее важны, чем в медицине!», «Мы за культуру, где ДШИ – центры вселенной!», «Мы за культуру, где у каждого школьника есть Пушкинская карта!», «Мы за культуру, которая нас объединяет!».

Подводя итог форсайт-сессии и оценивая услышанные идеи участников форума, Ольга Любимова отметила: *«Совершенно блистательные идеи, которые нам стоит забрать в Министерство культуры, поработать в течение года и посмотреть, насколько поменяется ситуация, что нужно еще доделать, что станет более актуальным, что менее. Это будет нашей общей задачей на будущее».* 

В рамках форума Министерство культуры впервые провело установочное совещание для руководителей и преподавателей детских школ искусств в преддверии начала нового учебного года. В ходе совещания были затронуты вопросы государственной поддержки начальной ступени творческого образования, реализации Концепции развития дополнительного образования и национального проекта «Культура» в субъектах Российской Федерации, а также планируемые масштабные общероссийские события и конкурсы с участием детских школ искусств, их учащихся и преподавателей в наступающем учебном году и другие актуальные вопросы развития системы художественного образования. В совещании приняли участие заместитель Министра Ольга Ярилова, заместитель директора Департамента региональной политики, образования и проектного управления Министерства культуры Ольга Юркова, министр культуры Калужской области Павел Суслов, ректор Российской академии музыки имени Гнесиных Александр Рыжинский.

Заметными событиями второго дня форума стали встречи участников с деятелями театра и кино. Одна из них прошла в рамках интерактивной лекции «Школьная классика» – так называется Всероссийский проект, созданный для поддержки и развития театральной деятельности в школах. Валентин Стасюк, начальник отдела проектной деятельности по делам молодежи Театрального института имени Вахтангова, рассказал ребятам о проекте, представил лучшие работы школьных театров, а также провел мастер-класс по актерскому мастерству. 

Деятели российской киноиндустрии собрались сегодня в рамках форума за круглым столом «Кино и дети: перезагрузка». Одним из вопросов встречи стало создание детского совета при Министерстве культуры Российской Федерации для оценки проектов в конкурсе детского кино. В дискуссии приняли участие основатель благотворительного фонда «Маленькое бюро добрых дел», CEO кинокомпании «Фильмы без границ», директор студии «Рок» Анастасия Акопян, генеральный продюсер студии Yellow, Black and White, сооснователь видеосервиса START Эдуард Илоян, юная актриса Виталия Корниенко, а также председатель Совета директоров киностудии «Союзмультфильм», председатель правления Ассоциации организаций индустрии анимационного кино, генеральный директор Центральной киностудии детских и юношеских фильмов имени Максима Горького Юлиана Слащева. 

Завершился второй день Детского культурного форума концертом «Вдохновение». Открыла концерт Ольга Любимова, она зачитала Культурный манифест нового поколения, поприветствовала участников и поблагодарила их за активную работу на форуме. На главную сцену вышли творческие коллективы и артисты со всей России: аккордеонист-виртуоз Пётр Дранга, хореографический ансамбль «Атлантика» из Севастополя, детский ансамбль гусляров из республики Марий Эл, фольклорный ансамбль «ЗАРЯНА» из Калининграда, ансамбль русских народных духовых инструментов «Вишенка» из Приморья и ансамбль классического танца «Вдохновение» из города Тамбов. Перед участниками форума выступила и гостья из Сербии – юная косовская певица Павлина Радованович.

Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».

    `,
    },
    {
        title: 'Участников Детского культурного форума познакомили с навыками инклюзивного общения',
        date: '27.08.22',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-022.jpg',
        teaser: 'В рамках Детского культурного форума состоялся урок-мастер-класс русского жестового языка «Как это сказать…». Его провела преподаватель русского жестового языка, руководитель отдела русского жестового языка Российской государственной специализированной академии искусств, переводчик русского жестового языка Варвара Ромашкина.',
        full:
            `*В рамках Детского культурного форума состоялся урок-мастер-класс русского жестового языка «Как это сказать…». Его провела преподаватель русского жестового языка, руководитель отдела русского жестового языка Российской государственной специализированной академии искусств, переводчик русского жестового языка Варвара Ромашкина.*

Сегодня инклюзивную культуру невозможно представить без жестового языка, который производится руками в сочетании с мимикой, формой или движением рта и губ. Овладеть этими навыками участники форума попробовали в ходе практического занятия с элементами интерактива. Для большинства ребят этот урок стал настоящим открытием и заставил задуматься о том, какой интересной может быть форма общения без звука. Участники отметили, что жестовая речь очень выразительная, эмоциональная и интересная. 

Жестовый язык необходимо развивать больше, его востребованность в нашей стране огромна, сказала Варвара Ромашкина: *«У нас 4 миллиона глухих, в том числе и детей, это много. Поэтому надо заниматься развитием жестового языка. Государство старается, но много зависит от людей на местах, руководителей и преподавателей тех школ, где учатся неслышащие дети».* 

В школе очень важно общаться на понятном языке с детьми с нарушением слуха или их глухими родителями. Это необходимое условие для выстраивания доверительных отношений между учителем и учеником, его семьей. Кроме того, от этого зависит успеваемость ребенка, отметила Варвара Ромашкина: *«Жестовым языком педагог может лучше донести до детей свой предмет, потому что посредством артикуляции он не сможет донести свой предмет до учащихся. А делать это их проблемой, а не своей – это неправильно».* 

Изучение жестового языка очень полезно людям без нарушения слуха, ведь в процессе задействована мелкая моторика рук, которая положительно влияет на когнитивные способности мозга, добавила эксперт.

Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».


    `,
    },
    {
        title: 'На Детском культурном форуме обсудили будущее анимации',
        date: '27.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-023.jpg',
        teaser: 'В рамках Детского культурного форума обсудили перспективу развития анимации в России и мире. В панельной дискуссии «Тренды в анимации» приняли участие генеральный директор студии Magic Factory Сергей Демчев, директор киностудии «Союзмультфильм» Борис Машковцев, главный редактор канала «Карусель», генеральный продюсер канала «Мульт» — А Татьяна Цыварева, режиссер Борис Чертков и генеральный директор студии визуальных эффектов Main Road Post Арман Яхин.',
        full:
            `*В рамках Детского культурного форума обсудили перспективу развития анимации в России и мире. В панельной дискуссии «Тренды в анимации» приняли участие генеральный директор студии Magic Factory Сергей Демчев, директор киностудии «Союзмультфильм» Борис Машковцев, главный редактор канала «Карусель», генеральный продюсер канала «Мульт» — А Татьяна Цыварева, режиссер Борис Чертков и генеральный директор студии визуальных эффектов Main Road Post Арман Яхин. *

Анимация как направление визуальной культуры особенно интересует подрастающее поколение, которое очень восприимчиво к современным технологиям. Производители кино и мультфильмов всё чаще берут на вооружение самые новые разработки. Хотя среди отечественных аниматоров пока еще остаются приверженцы классической манеры. Так, режиссер Борис Чертков рассказал о создании мультфильма «Суворов: Великое путешествие»: *«Мы всеми силами делали наш проект в классической манере. Наша главная задача была рассказать максимально крепкую, классную, задевающую людей историю о нашем выдающемся соотечественнике, герое многих войн Александре Васильевиче Суворове. Мы хотели, чтобы ему за нас не было бы стыдно, ведь когда ты берешься за объемный материал, самое главное, чтобы у тебя совесть была чиста».*

На смену классике уже пришла система производства анимационного кино, которая базируется на игровых движках. Анимационные студии успешно пользуются ими, создавая совершенно удивительных персонажей и их собственный «живой мир». Об том рассказал генеральный директор студии Magic Factory Сергей Демчев: *«Игровая индустрия и анимация за счет своих технологических возможностей сильно пересекаются. По сути весь материал, который сейчас делается у нас для анимационного проекта, легко используется в игровом продукте. Все составляющие фильма абсолютно адаптивны, потому что исходный материал мы делаем под игровой движок».*

Участникам форума рассказали, что по оценкам экспертов в ближайшие десять лет рынок анимации удвоится. Уже сейчас современные технологии широко используют не только при создании мультфильмов, но и в самых разных сферах: от рекламы до медицины. Генеральный директор студии визуальных эффектов Main Road Post Арман Яхин, обращаясь к юной аудитории, заявил, что наше общее будущее за нейросетями и технологиями на их основе: *«Нейросети полностью вытеснят ручной труд. Мир, в котором вы растете, – это мир креативности, это мир абстрактного мышления, это мир философии, это то, что вам надо изучать. Нужно тренировать мозги все время, поэтому важно читать книги – это то, что помогает тренировать мышление».*

Чтобы использовать новые анимационные технологии в полном объеме студиям постоянно нужны обученные специалисты, но их пока не хватает. Об этом рассказал директор киностудии «Союзмультфильм» Борис Машковцев: *«Технология – это только инструмент в руках специалиста. Но пока технологически мы скорее не ведущие, а ведомые. Студии не могут быть выключены из процесса образования, потому что в какой-то момент нужно переходить от теории к практике. Но даже если мы на «Союзмультфильм» возьмем всех, кого выпускают имеющиеся вузы, все равно будет кадровый голод».*

Желающим связать свое будущее с анимацией главный редактор канала «Карусель», генеральный продюсер канала «Мульт» — А Татьяна Цыварева посоветовала уже сейчас перенимать правильные привычки настоящих художников: *«Будущему аниматору и любому медиапрофессионалу важно смотреть художественные и фотоальбомы. Смотрите, листайте, чтобы у вас это откладывалось в подсознании. Дальше это вам поможет сформировать свою идею, свой образ, свой фильм».*


Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».

    `,
    },
    {
        title: 'Об уникальных возможностях «Пушкинской карты» поговорили на Детском культурном форуме',
        date: '27.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-024.jpg',
        teaser: 'На Детском культурном форуме прошел мастер-класс, посвященный одному из самых современных решений для молодежи — «Пушкинской карте». «Пушкинская карта» — совместный проект Министерства культуры Российской Федерации, Министерства цифрового развития связи и массовых коммуникаций Российской Федерации и «Почта Банка». Карта доступна молодым людям от 14 до 22 лет включительно. Она позволяет бесплатно посещать участвующие в программе театры, кинотеатры, музеи, выставки, филармонии и другие учреждения культуры за счёт федерального бюджета. Номинал карты в 2022 году — 5000₽, из них до 2000₽ можно потратить на билеты в кино на фильмы, включённые в программу.',
        full:
            `*На Детском культурном форуме прошел мастер-класс, посвященный одному из самых   современных решений для молодежи — «Пушкинской карте». «Пушкинская карта» — совместный проект Министерства культуры Российской Федерации, Министерства цифрового развития связи и массовых коммуникаций Российской Федерации и «Почта Банка». Карта доступна молодым людям от 14 до 22 лет включительно. Она позволяет бесплатно посещать участвующие в программе театры, кинотеатры, музеи, выставки, филармонии и другие учреждения культуры за счёт федерального бюджета. Номинал карты в 2022 году — 5000₽, из них до 2000₽ можно потратить на билеты в кино на фильмы, включённые в программу.*

Участникам форума рассказали, как оформить карту с помощью сайта Госуслуг или мобильного приложения, а также где смотреть афишу мероприятий. 

Технический руководитель программы «Пушкинская карта», директор платформы «Культура.РФ» Александр Минаев сообщил, что половина россиян в возрасте от 14 до 22 лет уже оформили «Пушкинскую карту», это 6 миллионов человек. С сентября 2021 года по этим картам было куплено 13,5 миллионов билетов, каждый третий из них это билет в кино.  По словам менеджера программы «Пушкинская карта» от Министерства цифрового развития Светланы Павловой для отечественного кино это стало большой поддержкой: *«Вы поддержали, ходили и смотрели новинки, наши отечественные картины, классику и всё, что кинотеатры начали вам предлагать. И благодаря тому, что вы ходите, наши производители будут работать и снимать фильмы лучше».*

Сейчас в программу «Пушкинская карта» входят около 4000 организаций культуры. В топе учреждений по посещаемости: Третьяковская галерея, смотровая площадка башни «Федерация» в Москве Panorama 360, Государственный Эрмитаж, Исаакиевский собор и Петергоф; среди театров: мюзикл «Бродвей-Москва» в театре МДМ, Мариинский театр, Новосибирский академический театр оперы и балета, Чеченский государственный драматический театр им. Х. Нурадилова и Государственный республиканский русский драматический театр им. М. Горького в Махачкале. 

Также технический руководитель программы «Пушкинская карта», директор платформы «Культура.РФ» Александр Минаев отметил, что уже появляются мероприятия, которые можно посетить только по «Пушкинской карте»: *«В Нижнем Новгороде музей сделал экскурсию по подземелью только для держателей «Пушкинской карты». Туда стояла бы очередь и просто за рубли, но это доступно только по «ПушКе». И ещё отмечу, что становится всё больше форматов интерактивных с использованием виртуальной реальности».*

Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».

    `,
    },
    {
        title: 'Для участников Детского культурного форума прошел урок гостеприимства',
        date: '28.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-025.jpg',
        teaser: '150 школьникам со всей России рассказали про ценность гостеприимства среди москвичей и гостей города.',
        full:
            `*150 школьникам со всей России рассказали про ценность гостеприимства среди москвичей и гостей города.*

27 августа заместитель генерального директора по региональному взаимодействию АНО «Проектный офис по развитию туризма и гостеприимства Москвы» Мария Киселева провела Урок гостеприимства в рамках Детского культурного форума на ВДНХ.

Проведение Урока направлено на привлечение внимания к вопросам создания комфортной туристической среды, к изучению культурного разнообразия стран, сохранения традиций и обычаев гостеприимства и воспитания подрастающего поколения как активных городских жителей, обладающих развитыми навыками гостеприимства.

Цель урока — формирование ценности мультикультурного общества посредством гостеприимства, воспитание молодого поколения через межнациональный диалог. Детям рассказали о ценностях развития навыка гостеприимства, уважении традиций других стран, про востребованное профессиональное направление в туристической отрасли и умение продвигать свой регион. 

Урок гостеприимства прошел в интерактивном формате с использованием цифровых инструментов, кинопоказа и элементами живого общения с аудиторией. Специально к мероприятию был создан мультипликационный короткометражный фильм, рассказывающий 6 разных историй, раскрывающих понятие «современный гостеприимный человек».  

Детский культурный форум состоялся 24-28 августа, его цель — предоставление детям возможностей и инструментов, развитие навыков для решения творческих задач, независимо от их места жительства и окружающих условий. В мероприятии приняли участие более 1,5 тысяч детей из всех субъектов Российской Федерации и некоторых стран зарубежья.

Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».
    `,
    },
    {
        title: 'В Москве завершился Детский культурный форум',
        date: '29.08.2022',
        photo: 'https://storage.yandexcloud.net/dkf/images/dkf-026.jpg',
        teaser: '25-27 августа в столице, в павильоне № 57 «Россия – моя история» на ВДНХ, проходил первый Детский культурный форум. Яркая, насыщенная программа мероприятия, состоящая из двух частей – просветительской и фестивальной – собрала на одной площадке известных деятелей культуры, государственных и общественных деятелей, 1649 детей-участников из сех регионов Российской Федерации, а также из 12 иностранных делегаций: Беларуси, Южной Осетии, Сербии, Таджикистана, Узбекистана, Туркменистана, ДНР, ЛНР, Бахрейна, Ирана, Египта и Абхазии.',
        full:
            `*25-27 августа в столице, в павильоне № 57 «Россия – моя история» на ВДНХ, проходил первый Детский культурный форум. Яркая, насыщенная программа мероприятия, состоящая из двух частей – просветительской и фестивальной – собрала на одной площадке известных деятелей культуры, государственных и общественных деятелей, 1649 детей-участников из всех регионов Российской Федерации, а также из 12 иностранных делегаций: Беларуси, Южной Осетии, Сербии, Таджикистана, Узбекистана, Туркменистана, ДНР, ЛНР, Бахрейна, Ирана, Египта и Абхазии.*

В программу вошли 62 мероприятия: лекции, мастер-классы, диалоги, мастерские, сессии, фестивали, стенд-апы и арт-школы. Дети получили 3720 минут уникального контента, узнали про самые разные направления современной культуры и искусства: ведение блогов, театр, музыку, современное искусство, хореографию, актерское мастерство, музеи и многое другое. В работе форума приняли участие 450 волонтёров, мероприятие освещал 131 журналист из 40 СМИ, среди них 9 телеканалов. Более того, на площадке работали 45 молодых медийщиков из Лиги юных журналистов. Ребята освещали события форума: вели прямые трансляции в формате ток-шоу и видеодневников с помощью передвижной телевизионной станции, брали интервью у участников и спикеров форума, создавали контент для социальных сетей.

При формировании программы особый акцент делался на привлечение талантливых ребят в качестве спикеров и соведущих. Всего в течение 3 дней на сцене в качестве ведущих выступали 10 детей. У зрителей в зале была возможность задавать вопросы наставникам, в рамках некоторых форматов проходили онлайн-голосования, что позволило вовлечь аудиторию. Следить за онлайн-трансляцией можно было на официальном [сайте](https://kids-forum.ru/) события и на портале [Культура.рф.](https://www.culture.ru/)

В первый день форума на главной сцене, установленной возле фонтана «Дружба народов», состоялась церемония открытия. Участие в ней приняла Министр культуры Российской Федерации Ольга Любимова, а ведущими стали Тутта Ларсен и ее дочь Марфа Колоскова.

После торжественного шествия делегаций к павильону № 57 Ольга Любимова совершила обход выставочных стендов, представленных на площадке форума. Среди них был стенд «Волонтеров культуры», «Большой перемены», VK, «Школы креативных индустрий», «Музея военной истории РВИО», «Школьной классики», «Культуры для школьников» и российского общества «Знание».

Просветительская программа форума началась с открывающей сессии «Ребенок и культура. Диалог поколений». Пять знаковых деятелей культуры: актер, художественный руководитель Московского губернского театра Сергей Безруков, художественный руководитель Московского джазового оркестра Игорь Бутман, генеральный директор Музеев Московского Кремля Елена Гагарина, председатель Совета директоров киностудии «Союзмультфильм», генеральный директор Центральной киностудии детских и юношеских фильмов им. М. Горького Юлиана Слащева и ректор Академии русского балета им. А. Я. Вагановой Николай Цискаридзе рассказали детям свою творческую историю, поделились личным опытом успеха и дали совет-наставление участникам форума.

Другим важным событием первого дня стал Открытый диалог с Ольгой Любимовой «Культурная инициатива». Юные таланты из разных городов России представили на конкурс проекты по развитию культурного сообщества в своих регионах. В финал вышло 7 проектов, из которых Министр выбрала лучший – им стал проект Арсения Гайнуллина «100 вещей, которые нужно сделать в Приволжье». Он получит господдержку на реализацию до трех миллионов рублей.

Центральное событие второго дня форума – форсайт-сессия, в рамках которой участники вместе с Министром культуры Российской Федерации Ольгой Любимовой разработали Культурный манифест нового поколения. Ребята проанализировали сегодняшние тренды по различным творческим направлениям и сформировали видение будущего культуры через 15-25 лет. Стремление молодого поколения развиваться в культурной сфере и любовь ко всем формам искусства и творчества нашли отражение в лозунгах манифеста: «Мы за культуру, в которой читать – это модно!», «Мы за культуру, в которой шедевры доступны каждому!», «Мы за культуру, в которой у каждого молодого творца есть наставник!», «Мы за культуру, где людей не делят на «всех» и «особенных»!», «Мы за культуру, где наше кино круче Голливуда!», «Мы за культуру, где в каждом доме живет танец!», «Мы за культуру, где у молодых есть творческие лифты!», «Мы за культуру, где волонтеры не менее важны, чем в медицине!», «Мы за культуру, где ДШИ – центры вселенной!», «Мы за культуру, где у каждого школьника есть Пушкинская карта!», «Мы за культуру, которая нас объединяет!»

Также в рамках второго дня форума Министерство культуры провело установочное совещание для руководителей и преподавателей детских школ искусств в преддверии начала нового учебного года. На совещании были затронуты вопросы государственной поддержки начальной ступени творческого образования, реализации Концепции развития дополнительного образования и национального проекта «Культура». В совещании приняли участие заместитель Министра культуры Российской Федерации Ольга Ярилова, заместитель директора Департамента региональной политики, образования и проектного управления Минкультуры России Ольга Юркова, министр культуры Калужской области Павел Суслов и ректор Российской академии музыки имени Гнесиных Александр Рыжинский.

Другими заметными событиями второго дня стала презентация детского творческого образования Исламской Республики Иран, интерактивная лекция «Школьная классика» – так называется Всероссийский проект, созданный для поддержки и развития театральной деятельности в школах и круглый стол «Кино и дети: перезагрузка».

О будущем анимации, уникальных возможностях Пушкинской карты и жестовом языке говорили в третий день форума. Также в третий день прошла читка пьесы «Трудный экзамен», победившей в конкурсе «Знание.Театр» на лучшее просветительское произведение для молодежи. Проект реализуется партнером форума – Российским обществом «Знание». Пьеса на примере школьного экзамена показывает, как важно расширять кругозор и стремиться к знаниям не только в определенной области, но и в самых разных сферах. По ролям пьесу читали: известный актер, телеведущий и шоумен Михаил Башкатов (роль отца), восходящая звезда российского кино Марк Эйдельштейн (роль сына), актриса театра и кино, лауреат премии «Ника» Дарья Екамасова (роль матери).  

Премьера спектакля «Трудный экзамен» состоится 2 сентября 2022 года на московской сцене и откроет масштабный театральный фестиваль «Знание.Театр» в регионах. Спектакль будет рекомендован к постановке в школьных и студенческих театрах, для чего общество «Знание» разработает специальную онлайн-инструкцию, которая поможет молодежным студиям самостоятельно поставить произведение на своих площадках. Кроме того, школьные и студенческие коллективы смогут поучаствовать в конкурсе на лучшую постановку спектакля «Трудный экзамен» и получить шанс показать свою работу широкой публике на театральной сцене в Москве.

Детский культурный форум 2022 года стал ярким культурным событием для молодежи. Ребята остались довольны разноплановостью проходящих форматов и обилием оригинального контента. Организаторы надеются, что мероприятие будет проходить ежегодно и дарить детям еще больше новых, позитивных впечатлений. 

Справочно:  
Организатор Детского культурного форума – Министерство культуры Российской Федерации. Партнеры Форума: Министерство просвещения Российской Федерации, Министерство промышленности и торговли Российской Федерации, Правительство Москвы и ВДНХ, Союзмультфильм, центральная киностудия детских и юношеских фильмов имени Максима Горького, исторический парк «Россия – Моя история» на ВДНХ, сообщество «Большая перемена», Лига юных журналистов, Российское общество «Знание». Партнер направления «Изобразительное искусство» – завод художественных красок «Невская палитра».
Все фото и видео можно посмотреть на [сайте форума](https://kids-forum.ru/news#photo).

    `,
    },
];

const dataWithId = reversedData.map((item, i) => {
    return {
        ...item,
        id: i,
    };
});

export const data = dataWithId;
export const dataToShow = dataWithId.slice(0).reverse();