import {Stream as StreamComponent} from "components/Stream/Stream";
import {StreamSchedule} from "components/StreamSchedule/StreamSchedule";

export const Stream = () => {
    return (
        <div>
            <StreamComponent />
            <StreamSchedule />
        </div>
    );
}