import {useTranslation} from "react-i18next";
import cx from "classnames";

import styles from './Partners.module.scss';

import minkultH from './minkultH.svg';
import minkultV from './minkultV.svg';
import coorg1 from './coorg1.svg';
import coorg2 from './coorg2.svg';
import coorg3 from './coorg3.svg';
import coorg4 from './coorg4.svg';
import partner1 from './partner1.svg';
import partner2 from './partner2.svg';
import partner3 from './partner3.svg';
import partner4 from './partner4.svg';
import partner5 from './partner5.svg';
import partner6 from './partner6.svg';
import partner7 from './partner7.svg';
import infopartner1 from './infopartner1.svg';
import infopartner2 from './infopartner2_2.svg';
import infopartner3 from './infopartner3.svg';
import infopartner4 from './infopartner4.svg';
import infopartner5 from './infopartner5.svg';
import palitraH from './palitraH.svg';
import palitraV from './palitraV.svg';
import back1 from './back1.svg'
import back2 from './back2.svg'

export const Partners = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.block}>
            <div className={styles.title}>
                {t('partners.title')}
            </div>

            <div className={styles.row}>
                <div className={cx(styles.paper)}>
                    <div>
                        {t('partners.organizer')}
                    </div>
                    <div>
                        <a href="https://culture.gov.ru/" target="_blank">
                            <img src={minkultV} className={styles.minkultV} alt='' />
                            <img src={minkultH} className={styles.minkultH} alt='' />
                        </a>
                    </div>
                </div>

                <div className={cx(styles.paper, styles.paperWide)}>
                    <div>
                        {t('partners.coorganizer')}
                    </div>
                    <div className={cx(styles.logos, styles.coorganizer)}>
                        <a href="https://www.mos.ru/" target="_blank">
                            <img src={coorg1} alt='' />
                        </a>
                        <a href="https://rcfoundation.ru/" target="_blank">
                            <img src={partner7} alt='' />
                        </a>
                        <a href="https://vdnh.ru/" target="_blank">
                            <img src={coorg4} alt='' />
                        </a>
                        <a href="https://myhistorypark.ru/?city=msk" target="_blank">
                            <img src={partner3} alt='' />
                        </a>

                    </div>
                </div>
            </div>

            <div className={styles.row}>
                <div className={cx(styles.paper, styles.paperFull)}>
                    <div>
                        {t('partners.partners')}
                    </div>
                    <div className={cx(styles.logos, styles.partner)}>
                        <a href="https://edu.gov.ru/" target="_blank">
                            <img src={coorg2} alt='' />
                        </a>
                        <a href="https://minpromtorg.gov.ru/" target="_blank">
                            <img src={coorg3} alt='' />
                        </a>
                        <a href="https://souzmult.ru/" target="_blank">
                            <img src={partner1} alt='' />
                        </a>
                        <a href="https://bolshayaperemena.online/" target="_blank">
                            <img src={partner4} alt='' />
                        </a>
                        <a href="https://gorkyfilm.ru/" target="_blank">
                            <img src={partner2} alt='' />
                        </a>
                        <a href="https://znanierussia.ru/" target="_blank">
                            <img src={partner6} alt='' />
                        </a>
                        <a href="https://liga.press/" target="_blank">
                            <img src={partner5} alt='' />
                        </a>
                    </div>
                </div>
            </div>

            <div className={styles.row}>
                <div className={cx(styles.paper, styles.paperWide)}>
                    <div>
                        {t('partners.infopartners')}
                    </div>
                    <div className={cx(styles.logos, styles.infopartners)}>
                        <a href="https://www.culture.ru/" target="_blank">
                            <img src={infopartner1} alt='' />
                        </a>
                        <a href="https://www.karusel-tv.ru/" target="_blank">
                            <img src={infopartner2} alt='' />
                        </a>
                        <a href="https://newm-project.ru/" target="_blank">
                            <img src={infopartner5} alt='' />
                        </a>
                        <a href="https://detifm.ru/" target="_blank">
                            <img src={infopartner3} alt='' />
                        </a>
                        <a href="https://klassnoeradio.ru/" target="_blank">
                            <img src={infopartner4} alt='' />
                        </a>
                    </div>
                </div>

                <div className={cx(styles.paper)}>
                    <div>
                        {t('partners.finepartners')}
                    </div>
                    <div>
                        <a href="https://www.nevskayapalitra.ru/" target="_blank">
                            <img src={palitraV} className={styles.palitraV} alt='' />
                            <img src={palitraH} className={styles.palitraH} alt='' />
                        </a>
                    </div>
                </div>
            </div>

            <img src={back1} className={styles.back1} />
            <img src={back2} className={styles.back2} />
        </div>
    );
}