import cx from "classnames";
import React, {useEffect} from "react";
import {Link, useLocation, Routes, Route} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {useMenu} from "hooks/useMenu";
import {LangSwitcher} from "./LangSwitcher";
import {MobileMenu} from "./MobileMenu";
import styles from "./Header.module.scss";
import logoRu from "./ru.svg";
import logoEn from "./en.svg";

export const Header = () => {
    const { i18n } = useTranslation();

    const menu = useMenu();

    const { pathname, hash, key } = useLocation();

    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0);
        }
        // else scroll to id
        else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]); // do this on route change

    const isRu = i18n.language == 'ru';

    return (
        <div className={styles.block}>
            <div className={styles.inner}>
                <div className={styles.row1}>
                    <Link to="/">
                        <img
                            src={
                                isRu ?
                                    logoRu:
                                    logoEn
                            }
                            alt="logo"
                            className={cx(styles.logo, {
                                [styles.logoEn]: !isRu,
                                [styles.logoRu]: isRu,
                            })}
                        />
                    </Link>

                    <div className={styles.flex}>
                        <Routes>
                            <Route path="/login" element={<></>}/>
                            <Route path="/form" element={<></>}/>
                            <Route path="*" element={<LangSwitcher />}/>
                        </Routes>

                        <MobileMenu />
                    </div>

                </div>

                <div className={styles.menu}>
                    {menu.map((item, i) => {
                        return (
                            <Link key={i} to={item.link} className={styles.item}>
                                {item.title}
                            </Link>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}