import React, {} from 'react';
import {Routes, Route} from "react-router-dom";

import {Wrappers} from "containers/Wrappers";
import {Layout} from "components/Layout/Layout";
import {Login} from "pages/Login/Login";
import {Landing} from "pages/Landing/Landing";
import {EventRegistration} from "pages/EventRegistration/EventRegistration";
import {LandingOrganizer} from "pages/LandingOrganizer/LandingOrganizer";
import {Registrations} from "pages/Registrations/Registrations";
import {News} from "pages/News/News";
import {Stream} from "pages/Stream/Stream";
import {Workshops} from "pages/Workshops/Workshops";
import {Article} from "pages/Article/Article";
import {NotFound} from "pages/NotFound/NotFound";

import 'bootstrap/dist/css/bootstrap.min.css';
import './global.css';

export const App = () => (
    <Wrappers>
        <Layout>
            <Routes>
                <Route path="/" element={<Landing />}/>
                <Route path="/organizer" element={<LandingOrganizer />}/>
                <Route path="/login/" element={<Login />}/>
                <Route path="/form/" element={<EventRegistration />}/>
                <Route path="/registrations" element={<Registrations />}/>
                <Route path="/news" element={<News />}/>
                <Route path="/stream" element={<Stream />}/>
                <Route path="/workshops" element={<Workshops />}/>
                <Route path="/article/:id" element={<Article />}/>.ts
                <Route path="*" element={<NotFound />}/>
            </Routes>
        </Layout>
    </Wrappers>
);