import cx from "classnames";
import Masonry from "react-masonry-css";

import {Star} from "components/Star/Star";

import styles from './WorkshopGorki.module.scss';
import logo from "./logo.svg";
import back from "./back.svg";
import back2 from "./back2.svg";
import {data} from './data';

export const WorkshopGorki = () => {
    return (
        <div className={styles.block}>
            <div id="gorki" className={styles.anchor} />

            <div className={styles.header}>
                Мастер-классы по актерскому мастерству и сценической речи
            </div>

            <div className={styles.subtitle}>
                от партнера форума
            </div>

            <img
                src={logo}
                className={styles.logo}
                alt=""
            />

            <div className={styles.stars}>
                <div className={cx(styles.col, styles.col1)}>
                    <div className={styles.colTitle}>
                        Сценическая речь и риторика
                    </div>
                    Чему мы научим и что продемонстрируем детям на нашем мастер-классе по актерскому искусству?<br /><br />
                    Данный мастер-класс посвящен основным методам и психологии актерской игры. Мы проработаем коммуникабельность, поборемся с неуверенностью и страхом публичных выступлений, поможем с поиском и правильным применением творческих способностей в жизни. <br /><br />
                    Кроме того, в рамках тренинга дети «прокачают» следующие навыки:<br /><br />

                    <ul>
                        <li>
                            Опыт концентрации внимания на конкретном объекте,
                        </li>
                        <li>
                            Раскрепощение, работа с телом, избавление от внутренних зажимов,
                        </li>
                        <li>
                            Работа с предлагаемыми обстоятельствами,
                        </li>
                        <li>
                            Искусство импровизации,
                        </li>
                        <li>
                            Умение привлекать внимание посредством игровых ситуаций.
                        </li>
                    </ul>

                    <Star
                        header="90"
                        title="минут"
                        subtitle="каждый урок"
                        className={styles.star1}
                        type="normal"
                    />
                </div>

                <div className={cx(styles.col, styles.col2)}>
                    <div className={styles.colTitle}>
                        Мастерство актера
                    </div>

                    Какие навыки получит ребенок от мастер-класса по риторике?<br /><br />
                    Данный мастер-класс посвящен развитию речевого аппаратом и умению самопрезентации. Мы поможем устранить дикционные ошибки, поставим четкую артикуляцию, научим правильно произносить звуки и их сочетания. Расскажем о системе дыхательной гимнастики А. Стрельниковой и научим по ней работать самостоятельно.<br /><br />
                    Каждый прошедший мастер-класс начнет правильно и говорить, четко формулировать смысли, ясно мыслить.<br /><br />

                    <Star
                        header="60"
                        title="минут"
                        subtitle="каждый урок"
                        className={styles.star2}
                        type="normal"
                    />
                </div>
            </div>

            <img
                src={back}
                className={styles.back}
                alt=""
            />

            <div className={styles.days}>
                {data.map((day, i) => {
                    return (
                        <div key={i} className={styles.day}>
                            <div className={styles.date}>
                                {day.date}
                            </div>

                            <Masonry
                                breakpointCols={{
                                    default: 5,
                                    1299: 2,
                                    750: 1,
                                }}
                                className={styles.events}
                                columnClassName={styles.column}
                            >
                                {day.items.map((event, j) => {
                                    return (
                                        <div
                                            className={styles.event}
                                            key={j}
                                        >
                                            <div className={styles.time}>
                                                {event.time}
                                            </div>
                                            <div className={styles.title}>
                                                {event.title}
                                            </div>
                                            <div className={styles.text}>
                                                {event.text}
                                            </div>

                                            {Boolean(event.link) && (
                                                <a
                                                    target="_blank"
                                                    href={event.link}
                                                    className={styles.link}
                                                >
                                                    Смотреть урок
                                                </a>
                                            )}
                                        </div>
                                    );
                                })}
                            </Masonry>
                        </div>
                    );
                })}
            </div>

            <img
                src={back2}
                className={styles.back2}
                alt=""
            />
        </div>
    );
}