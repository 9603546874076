import {useState} from "react";

import styles from "./SMI.module.scss";
import { data } from './data';

const threshold = 10;

export const SMI = () => {
    const [opened, setOpened] = useState(false);

    if (!data || !data.length) {
        return null;
    }

    const dataToShow = (opened || data.length < threshold ) ? data : data.slice(0, threshold);

    return (
        <div className={styles.block}>
            <div className={styles.header} id="smi">
                СМИ о нас
            </div>

            <div className={styles.table}>
                {dataToShow.map((item, i) => {
                    return (
                        <a
                            key={i}
                            className={styles.item}
                            href={item.link}
                            target="_blank"
                        >
                            <div className={styles.flex}>
                                {Boolean(item.logo) && (
                                    <img
                                        src={item.logo}
                                        alt="logo"
                                        className={styles.logo}
                                    />
                                )}
                                <div className={styles.smiTitle}>
                                    {item.title}
                                    <div className={styles.datetime}>
                                        {item.date}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.text}>
                                {item.text}
                            </div>
                        </a>
                    );
                })}
            </div>

            {data.length >= threshold && (
                <div className={styles.button} onClick={() => setOpened(!opened)}>
                    {opened ? "Скрыть" : "Показать всё"}
                </div>
            )}
        </div>
    );
}