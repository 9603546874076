import {WorkshopSouzMult} from "components/WorkshopSouzMult/WorkshopSouzMult";
import {WorkshopGorki} from "components/WorkshopGorki/WorkshopGorki";
import {WorkshopSwitch} from "components/WorkshopSwitch/WorkshopSwitch";

export const Workshops = () => {
    return (
        <div>
            <WorkshopSwitch />
            <WorkshopSouzMult />
            <WorkshopGorki />
        </div>
    );
}