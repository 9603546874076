import {useTranslation} from "react-i18next";

type MenuItem = {
    title: string;
    link: string;
}

type Menu = MenuItem[];

export const useMenu = () => {
    const { t } = useTranslation();

    const menu: Menu = [
        {
            title: t('menu.main'),
            link: '/#home',
        },
        {
            title: t('menu.news'),
            link: '/news',
        },
        {
            title: t('menu.stream'),
            link: '/stream',
        },
        {
            title: t('menu.workshops'),
            link: '/workshops',
        },
        {
            title: t('menu.schedule'),
            link: '/#schedule',
        },
        {
            title: t('menu.organizer'),
            link: '/organizer/',
        },
    ];

    return menu;
};