import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {createAction} from "@reduxjs/toolkit";
import { batch } from 'react-redux'

import config from "config";

import {UsersStateSetPayload} from "reducers/users";
import {EventRegistrationsSetPayload} from "reducers/eventRegistrations";

const setUsers = createAction<UsersStateSetPayload>('users/set')
const setRegistrations = createAction<EventRegistrationsSetPayload>('eventRegistrations/set');


export const useGetUserRegistrations = () => {
    const dispatch = useDispatch();

    return useCallback(async () => {
        try {
            const resp = await fetch(config.api + 'my_registrations/', {
                credentials: 'include',
            });

            const data = await resp.json();

            if (resp.status === 200) {
                const {users, registrations} = data;

                batch(() => {
                    dispatch(setUsers({
                        id: 0,
                        data: users,
                    }));

                    dispatch(setRegistrations({
                        id: 0,
                        data: registrations,
                    }));
                })
            }
        } catch (e) {
            console.log(e);
        }
    }, []);
};
