export const ru = {
    menu: {
        main: 'Главная',
        news: 'Новости',
        auditory: "Аудитория",
        stream: "Трансляция",
        workshops: "Мастер-классы",
        themes: 'Жанры форума',
        schedule: 'Программа',
        organizer: 'При поддержке',
    },
    home: {
        line1: 'ОТКРОЙ МИР –',
        line2: 'ОТКРОЙ СЕБЯ',
        header: 'Детский культурный форум',
        text: 'это место встречи юных талантов, полных идей, энергии и стремления расти и развиваться, находить новых друзей и единомышленников, творить и создавать новое.',
    },
    timer: {
        title: 'До начала мероприятия',
        d: 'дн',
        h: 'ч',
        m: 'мин',
        s: 'сек',
    },
    video: {
        header: 'Участникам форума',
        text: 'Приветственное обращение Министра культуры Российской Федерации Любимовой О.Б.',
    },
    footer: {
        links: 'Разделы',
        contacts: 'Контакты',
        tags: 'Популярные теги',
        conditions: 'С общим Положением о проведении Детского культурного форума можно ознакомиться',
        conditionsLink: 'здесь',
        confidential: 'С положением о конфиденциальности персональных данных можно ознакомиться ',
        confidentialLink: 'здесь',
        time: 'с 9:00 до 18:00 МСК',
        copyright: 'Благотворительный фонд поддержки анимации',
        tagItems: [
            {
                title: '#Детскийкультурныйфорум',
                link: '#',
            },
            {
                title: '#ДКФ',
                link: '#',
            },
            {
                title: '#ВДНХ',
                link: '#',
            },
            {
                title: '#МинкультурыРоссии',
                link: '#',
            },
            {
                title: '#МинистерствоКультурыРФ',
                link: '#',
            },
        ],
    },
    about: {
        title: 'О форуме',
        text: 'Дети — участники фестивальной программы выступят в концертах, примут участие в мастер-классах по интересующим их темам, познакомятся с возможностями профессиональных учебных заведений, пообщаются со своими ровесниками из других регионах и обменяются творческим опытом и обязательно познакомятся с главными достопримечательностями Москвы.',
        place: 'Место проведения',
        place1: 'ВДНХ',
        place2: 'павильон 57',
        date: 'Дата проведения',
        date1: '24-28',
        date2: 'августа',
    },
    program: {
        header: 'Программа',
        items: [
            {
                title: 'Приезд',
                text: 'в среду единомышленников, нетворкинг',
            },
            {
                title: 'Экскурсии',
                text: 'погружение в культурное пространство Москвы, маршруты по Пушкинской карте',
            },
            {
                title: 'Мотивация',
                text: 'диалоги с лидерами культуры',
            },
            {
                title: 'Обучение',
                text: 'получение знаний от профессионалов сферы культуры',
            },
            {
                title: 'Вдохновение',
                text: 'творческое развитие на площадке форума в синергии программы и окружения',
            },
            {
                title: 'Совместная\n' +
                    'работа ',
                text: 'создание арт-объекта на площадке, развитие культурного лидерства, формирование новых творческих групп',
            },
            {
                title: 'Вклад в культуру ',
                text: 'обратная связь  по прошедшим за день мероприятиям взрослым, анализ полученных знаний в дискуссионных клубах, при медиа центре',
            },
            {
                title: 'Новая ступень собственного творчества',
                text: 'воплощение полученных знаний и вдохновения в жизнь',
            },
        ],
    },
    participants: {
        title: 'Аудитория',
        text: 'Форум задуман как уникальная площадка, где одаренные дети встречаются друг с другом, педагогами, родителями, лидерами культуры и государственными и общественными деятелями для того, чтобы заявить о своем видении развития культуры и собственных потребностях для успешной творческой реализации.',
        line1: 'Дети в возрасте',
        line2: '12-17',
        line3: 'лет',
        line4: '>1500',
        line5: 'участников — яркие представители по творческим направлениям со всех регионов',
        line6: '85',
        line7: 'Субъектов РФ',
    },
    topics: {
        title: 'Жанры форума',
        items: [
            'Балет, танец, хореография',
            'Театральное искусство',
            'Изобразительное искусство',
            'Традиционные и новые медиа',
            'Музыкальное искусство',
            'Музеи и выставки',
            'Кино и анимация'
        ]
    },
    organizer: {
        title: 'При поддержке',
        text: 'Министерство культуры Российской Федерации – это федеральный орган исполнительной власти, уполномоченный заниматься вопросами культуры и искусства',
        header1: 'Основная задача',
        text1: 'Министерства культуры Российской Федерации – реализация Стратегии государственной культурной политики на период до 2030 года, утверждённой Распоряжением Правительства РФ от 29 февраля 2016 г. Мы работаем для того, чтобы изо дня в день Россия укрепляла свой статус великой культурной державы, а каждый из её граждан ощущал свою причастность к нашим национальным культурным ценностям.',
        header2: 'Сегодня',
        text2: 'структура Министерства культуры представляет собой разветвлённую систему, охватывающую абсолютно все сферы культуры. Каждая из сфер, находящихся в ведении Министерства, распределена между 59 отделами, входящими в состав 11 профильных департаментов.',
    },
    committee: {
        title: 'Оргкомитет',
        items: [
            {
                title: 'Ольга Любимова',
                description: 'Министр культуры Российской Федерации',
                photo: 'https://storage.yandexcloud.net/dkf/images/lubimova.jpg',
            },
            {
                title: 'Алла Манилова',
                description: 'Статс-секретарь-заместитель Министра культуры Российской Федерации',
                photo: 'https://storage.yandexcloud.net/dkf/images/manilova.jpg',
            },
            {
                title: 'Ольга Ярилова',
                description: 'Заместитель Министра культуры Российской Федерации',
                photo: 'https://storage.yandexcloud.net/dkf/images/yarilova.jpg',
            },
            {
                title: 'Михаил Брызгалов',
                description: 'Генеральный директор Российского национального музея музыки',
                photo: 'https://storage.yandexcloud.net/dkf/images/bryzgalov.jpg',
            },
            {
                title: 'Денис Грибов',
                description: 'Заместитель Министра просвещения Российской Федерации',
                photo: 'https://storage.yandexcloud.net/dkf/images/gribov.jpg',
            },
            {
                title: 'Гульназ Кадырова',
                description: 'Заместитель Министра промышленности и торговли Российской Федерации',
                photo: 'https://storage.yandexcloud.net/dkf/images/kadyrova.jpg',
            },
            {
                title: 'Александр Кибовский',
                description: 'Министр Правительства Москвы, руководитель Департамента культуры города Москвы',
                photo: 'https://storage.yandexcloud.net/dkf/images/kibovski.jpg',
            },
            {
                title: 'Евгений Примаков',
                description: 'Руководитель Федерального агентства по делам Содружества Независимых государств, соотечественников, проживающих за рубежом, и по международному гуманитарному сотрудничеству',
                photo: 'https://storage.yandexcloud.net/dkf/images/primakov.jpg',
            },
            {
                title: 'Юлиана Слащёва',
                description: 'Председатель правления киностудии «Союзмультфильм», генеральный директор Киностудии им. М. Горького',
                photo: 'https://storage.yandexcloud.net/dkf/images/slascheva.jpg',
            },
            {
                title: 'Анна Усачева',
                description: 'Помощник Министра культуры Российской Федерации',
                photo: 'https://storage.yandexcloud.net/dkf/images/usacheva.jpg',
            },
            {
                title: 'Сергей Шогуров',
                description: 'Генеральный директор АО «ВДНХ»',
                photo: 'https://storage.yandexcloud.net/dkf/images/shogurov.jpg',
            },
            {
                title: 'Ольга Юркова',
                description: 'Заместитель директора Департамента региональной политики, образования и проектного управления Минкультуры России',
                photo: 'https://storage.yandexcloud.net/dkf/images/urkova.jpg',
            },
        ]
    },
    partners: {
        title: 'Организаторы и партнёры',
        organizer: 'Организатор',
        coorganizer: 'Соорганизаторы',
        partners: 'Партнеры',
        infopartners: 'Информационные партнёры',
        finepartners: 'Партнёр направления «Изобразительное искусство»',
    }
};
