import {useState} from "react";

import styles from './NewsPhotos.module.scss';
import {data} from "./data";

const threshold = 6;

export const NewsPhotos = () => {
    const [opened, setOpened] = useState(false);

    if (!data || !data.length) {
        return null;
    }

    const dataToShow = (opened || data.length < threshold ) ? data : data.slice(0, threshold);

    return (
        <div className={styles.block}>
            <div className={styles.header} id="photo2">
                Фото
            </div>

            <div className={styles.items}>
                {dataToShow.map((item, i) => {
                    return (
                        <a
                            key={i}
                            className={styles.item}
                            href={item.link}
                            target="_blank"
                        >
                            <div className={styles.title}>
                                {item.title}
                            </div>

                            <div className={styles.date}>
                                {item.date}
                            </div>

                            <img
                                className={styles.image}
                                alt="image"
                                src={item.photo}
                            />
                        </a>
                    );
                })}
            </div>

            {data.length >= threshold && (
                <div className={styles.button} onClick={() => setOpened(!opened)}>
                    {opened ? "Скрыть" : "Показать всё"}
                </div>
            )}
        </div>
    );
}