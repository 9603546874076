import ReactMarkdown from 'react-markdown'
import Masonry from 'react-masonry-css'

import {Star} from "components/Star/Star";

import styles from './WorkshopSouzMult.module.scss';
import logo from './logo.svg';
import back from './back.jpg';
import back2 from './back2.svg';
import {data} from './data';
import {FC, useState} from "react";
import {Highlight} from "../Highlight/Highlight";

export const WorkshopSouzMult = () => {
    return (
        <div
            id="souz"
            className={styles.block}
        >
            <div className={styles.header}>
                Мастер-классы по мультипликации
            </div>

            <div className={styles.subtitle}>
                от партнера форума
            </div>

            <div className={styles.stars}>
                <img
                    src={logo}
                    className={styles.logo}
                    alt=""
                />

                <Star
                    header="9"
                    title="уроков"
                    className={styles.star1}
                    type="small"
                />

                <Star
                    header="45"
                    title="Длительность"
                    subtitle="минут"
                    className={styles.star2}
                    type="mixed"
                />
            </div>

            <div className={styles.wrapper}>
                <div className={styles.paper}>
                    Во время занятий в формате видеоуроков участники познакомятся с техниками анимации и смогут сами попробовать сделать свой мультфильм.
                </div>

                <div className={styles.hint} />
            </div>

            <img
                src={back}
                alt=""
                className={styles.back}
            />

            <div className={styles.days}>
                {data.map((day, i) => {
                    return (
                        <div className={styles.day} key={i}>
                            <div className={styles.dayTitle}>
                                {day.date}
                            </div>

                            <Masonry
                                breakpointCols={{
                                    default: 2,
                                    900: 1,
                                }}
                                className={styles.events}
                                columnClassName={styles.column}
                            >
                                {day.items.map((item, j) => {
                                    return (
                                        <Event
                                            key={j}
                                            {...item}
                                        />
                                    );
                                })}
                            </Masonry>
                        </div>
                    );
                })}
            </div>

            <img
                src={back2}
                alt=""
                className={styles.back2}
            />
        </div>
    );
}

type Props = {
    time: string;
    text: string;
    prepare: string;
    link: string;
}

const Event: FC<Props> = ({time, text, prepare, link}) => {
    const [opened, setOpened] = useState(false);

    return (
        <div className={styles.event}>
            <Highlight>
                {time}
            </Highlight>

            <div className={styles.eventTitle}>
                {text}
            </div>

            {opened && (
                <ReactMarkdown className={styles.eventPrepare}>
                    {prepare}
                </ReactMarkdown>
            )}

            <div
                className={styles.eventShowPrepare}
                onClick={() => setOpened(!opened)}
            >
                {opened ? 'Свернуть' : 'Подготовка к уроку'}
            </div>

            {Boolean(link) && (
                <a className={styles.eventLink} href={link} target="_blank">
                    Смотреть урок
                </a>
            )}
        </div>
    );
}