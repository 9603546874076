import {useCallback, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";

import {Content} from "components/Content/Content";
import {useGetUserData} from "hooks/useGetUserData";
import {RootState} from "containers/Wrappers";

import {EventRegistrationForm} from "./EventRegistrationForm";
import styles from "./EventRegistration.module.scss"
import {usePrepareData} from "./helpers";
import {useGetUserRegistrations} from "../../hooks/useGetUserRegistarations";
import {Button} from "react-bootstrap";
import config from "../../config";
import {getCsrfToken} from "../../helpers";

export const EventRegistration = () => {
    const getUserInfo = useGetUserData();
    const loadData = useGetUserRegistrations();
    let navigate = useNavigate();

    useEffect(() => {
        getUserInfo();
        loadData();
    }, []);

    const user = useSelector(
        (state: RootState) => state.user
    );

    if (user.error) {
        navigate('/login/', {replace: true});
    }

    const users = useSelector(
        (state: RootState) => state.users[0]
    );

    const registrations = useSelector(
        (state: RootState) => state.eventRegistrations[0]
    );

    const preparedData = usePrepareData(user, users, registrations);

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        try {
            await fetch(config.api + 'me/', {
                credentials: 'include',
                method: "post",
                headers: {
                    'X-CSRFToken': getCsrfToken(),
                },
            });

            await loadData();
        } catch (e) {
            console.log(e);
        }
    }, [user]);

    if (!users || !registrations) {
        return null;
    }

    const me = users.find((test) => test.id == user.id);

    const budgetPlaces = me?.budget_places_override ?? me?.organization?.budget_places ?? 5;

    let budgetFilled = 0;
    let budgetBeyond = 0;

    let supervisorFilled = false;
    let submitted = false;

    users.forEach((userObject, i) => {
        if (userObject.id == user.id) {
            const registration = preparedData.registrationsMap[userObject.id || 0];

            if (!registration) {
                return;
            }

            if (!registration.arrival_ticket || !registration.departure_ticket) {
                return;
            }

            supervisorFilled = true;

            submitted = Boolean(registration.is_closed);

            return;
        }

        const registration = preparedData.registrationsMap[userObject.id || 0];

        if (registration.is_not_budget) {
            budgetBeyond++;
        } else {
            budgetFilled++;
        }
    });

    let canSubmit = true;

    let overflow = budgetPlaces < budgetFilled;

    if (overflow) {
        canSubmit = false;
    }

    if (!supervisorFilled) {
        canSubmit = false;
    }

    // if (submitted) {
    //     return (
    //         <Content>
    //             <div className={styles.info}>
    //                 <div className={styles.text}>
    //                     Здравствуйте! Вы находитесь в личном кабинете делегации от региона <b>{me?.position}</b>. Просьба заполнить данные на каждого участника группы.<br />
    //                     В случае возникновения вопросов обращайтесь на горячую линию Форума по телефону +7(495)780-58-98 или на почту <a href="mailto:info@kids-forum.ru" target="_blank">info@kids-forum.ru</a>
    //                 </div>
    //
    //                 <div className={styles.places}>
    //                     <Button
    //                         disabled
    //                         variant="primary"
    //                     >
    //                         Заявка отправлена
    //                     </Button>
    //                 </div>
    //             </div>
    //         </Content>
    //     );
    // }

    const noLogistics = me?.organization?.external_id === 3;

    return (
        <Content>
            <div className={styles.info}>
                <div className={styles.text}>
                    Здравствуйте! Вы находитесь в личном кабинете делегации от региона <b>{me?.position}</b>. Просьба заполнить данные на каждого участника группы.<br />
                    В случае возникновения вопросов обращайтесь на горячую линию Форума по телефону +7(495)780-58-98 или на почту <a href="mailto:info@kids-forum.ru" target="_blank">info@kids-forum.ru</a>
                </div>

                <div className={styles.places}>
                    Количество бюджетных мест в вашей группе: <strong>{budgetPlaces + 1}</strong> (с учётом руководителя),<br />
                    из них заполнено <strong>{budgetFilled}{Boolean(supervisorFilled) && (<> + руководитель</>)}</strong>,<br />
                    не по квоте: <strong>{budgetBeyond}</strong> <br />
                    <br />

                    {Boolean(me?.schedule_link) && (
                        <Button as="a" href={me?.schedule_link || ''} target="_blank" >
                            Регистрация на программу
                        </Button>
                    )}

                    {/*<Button*/}
                    {/*    disabled={!canSubmit}*/}
                    {/*    variant="primary"*/}
                    {/*    onClick={onSubmit}*/}
                    {/*>*/}
                    {/*    Отправить заявку*/}
                    {/*</Button> <br />*/}

                    {overflow && (<div><strong>
                        У вас превышена квота максимального количества участников. Удалите из списка участника или отметьте кого-то из участников категорией "сверх квоты"
                    </strong></div>)}

                    {!supervisorFilled && (<div><strong>
                        Требуется заполнить данные руководителя
                    </strong></div>)}
                </div>
            </div>

            {/*<EventRegistrationForm*/}
            {/*    user={preparedData.usersMap[user.id]}*/}
            {/*    registration={preparedData.registrationsMap[user.id]}*/}
            {/*    isSupervisor*/}
            {/*    reload={loadData}*/}
            {/*    noLogistics={noLogistics}*/}
            {/*/>*/}

            {/*{users.map((userObject, i) => {*/}
            {/*    if (userObject.id == user.id) {*/}
            {/*        return null;*/}
            {/*    }*/}

            {/*    return (*/}
            {/*        <EventRegistrationForm*/}
            {/*            key={i}*/}
            {/*            reload={loadData}*/}
            {/*            user={userObject}*/}
            {/*            registration={preparedData.registrationsMap[userObject.id || 0]}*/}
            {/*            noLogistics={noLogistics}*/}
            {/*        />*/}
            {/*    );*/}
            {/*})}*/}

            {/*<EventRegistrationForm*/}
            {/*    noLogistics={noLogistics}*/}
            {/*    reload={loadData}*/}
            {/*/>*/}
        </Content>
    );
}