import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useEffect, useMemo, useState} from "react";
import {intervalToDuration, differenceInMinutes} from "date-fns";

import {Highlight} from "components/Highlight/Highlight";
import {data} from "components/NewsArticles/data";
import styles from './Home.module.scss';
import hint from './hint.svg';
import back from './back.png';
import smi from './smi.svg';
import video from './photo.svg';

const end = new Date(2022, 7, 24, 0, 0, 0);

export const Home = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.outer}>
            <div className={styles.block} id="home">
                <img src={back} className={styles.back}/>
                <Highlight className={styles.slogan1}>
                    {t('home.line1')}
                </Highlight>
                <Highlight className={styles.slogan2}>
                    {t('home.line2')}
                </Highlight>

                <div className={styles.wrapper}>
                    <div className={styles.paper}>
                        <img src={hint} className={styles.hint}/>

                        <div className={styles.header}>
                            {t('home.header')}
                        </div>

                        <div>
                            {t('home.text')}
                        </div>
                    </div>
                </div>

                {<Teaser />}
            </div>
        </div>
    );
}

const Teaser = () => {
    const article = data[27];

    return (
        <div className={styles.teaser}>
            <div className={styles.teaserHeader}>
                Наши новости
            </div>

            <div className={styles.teaserLinkContainer}>
               <Link
                    to="/news#smi"
                    className={styles.teaserLink}
                >
                    <img
                        alt=""
                        src={smi}
                        className={styles.teaserLinkIcon}
                    />
                    <span className={styles.teaserLinkText}>
                        СМИ о нас
                    </span>
                </Link>
                <Link
                    to="/news#photo"
                    className={styles.teaserLink}
                >
                    <img
                        alt=""
                        src={video}
                        className={styles.teaserLinkIcon}
                    />
                    <span className={styles.teaserLinkText}>
                        Фото и видео
                    </span>
                </Link>

            </div>

            <div className={styles.teaserCard}>
                <Link
                    to={`/article/${article.id}`}
                >
                    <div className={styles.teaserCardTitle}>
                        {article.title}
                    </div>
                    <div className={styles.teaserCardDate}>
                        {article.date}
                    </div>
                    <div className={styles.teaserCardText}>
                        {article.teaser}
                    </div>
                </Link>

                <Link
                    className={styles.teaserCardButton}
                    to="news"
                >
                    Все новости
                </Link>
            </div>
        </div>
    );
}

const Timer = () => {
    const { t } = useTranslation();
    const [tick, setTick] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setTick(tick + 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [tick]);

    const [days, timer] = useMemo(() => {
        const days = Math.floor(differenceInMinutes(end, new Date()) / (60 * 24));
        const timer = intervalToDuration({
            start: new Date(),
            end
        });

        return [days, timer];
    }, [tick]);

    return (
        <div className={styles.paper2}>
            {t('timer.title')}

            <div className={styles.time}>
                <div className={styles.timeItem}>
                    {days} {t('timer.d')}
                </div>

                <div className={styles.timeItem}>
                    {timer.hours} {t('timer.h')}
                </div>

                <div className={styles.timeItem}>
                    {timer.minutes} {t('timer.m')}
                </div>

                <div className={styles.timeItem}>
                    {timer.seconds} {t('timer.s')}
                </div>
            </div>
        </div>
    );
}