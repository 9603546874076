import React, {FC, useCallback, useState} from "react";
import {createPortal} from "react-dom";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import cx from "classnames";

import {useMenu} from "hooks/useMenu";
import styles from './Header.module.scss';
import menu from './menu.svg';
import closeMenu from './close.svg';
import logoRu from "./ru.svg";
import logoEn from "./en.svg";

export const MobileMenu = () => {
    const [isOpened, setIsOpened] = useState(false);

    const open = useCallback(() => {
        setIsOpened(true);
    }, [])

    const close = useCallback(() => {
        setIsOpened(false);
    }, [])

    return (
        <>
            <img className={styles.mobileMenu} src={menu} alt="menu" onClick={open} />

            {isOpened && (
                <Opened close={close} />
            )}
        </>
    );
}

type Props = {
    close: () => void;
}

const Opened: FC<Props> = ({close}) => {
    const { i18n } = useTranslation();
    const menu = useMenu();
    const isRu = i18n.language == 'ru';

    const el = document.getElementById('root');

    if (!el) {
        return null;
    }

    return (
        createPortal(
            (
                <div className={styles.openedMenu}>
                    <div className={styles.openedMenuHeader}>
                        <Link to="/">
                            <img
                                src={
                                    isRu ?
                                        logoRu:
                                        logoEn
                                }
                                alt="logo"
                                className={cx(styles.logo, {
                                    [styles.logoEn]: !isRu,
                                    [styles.logoRu]: isRu,
                                })}
                            />
                        </Link>

                        <img
                            src={closeMenu}
                            onClick={close}
                            alt="close"
                        />
                    </div>
                    <div className={styles.openedMenuItems}>
                        {menu.map((item, i) => {
                            return (
                                <Link
                                    key={i}
                                    to={item.link}
                                    className={styles.openedMenuItem}
                                    onClick={close}
                                >
                                    {item.title}
                                </Link>
                            );
                        })}
                    </div>
                </div>
            ),
            el
        )
    );
}