import cx from "classnames";

import {Organizer} from "../../components/Organizer/Organizer";
import {Commitee} from "../../components/Commitee/Commitee";
import {Partners} from "../../components/Partners/Partners";

import styles from "./LandingOrganizer.module.scss";

export const LandingOrganizer = () => {
    return (
        <div className={styles.block}>
            <Organizer />

            <Commitee />

            <Partners />
        </div>
    );
}