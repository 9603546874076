import React, {FC, Suspense} from "react";
import {BrowserRouter} from "react-router-dom";
import { configureStore, createAction } from '@reduxjs/toolkit'
import { Provider } from 'react-redux'
import { useDispatch } from 'react-redux'

import usersReducer from 'reducers/users'
import userReducer from 'reducers/user'
import eventRegistrationsReducer from 'reducers/eventRegistrations'

import config from "config";

export const store = configureStore({
    reducer: {
        user: userReducer,
        eventRegistrations: eventRegistrationsReducer,
        users: usersReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const Wrappers: FC = ({children}) => {
    return (
        <Suspense fallback="Загрузка..." >
            <BrowserRouter>
                <Provider store={store}>
                    {children}
                </Provider>
            </BrowserRouter>
        </Suspense>
    )
};