import {useTranslation} from "react-i18next";
import {useCallback} from "react";
import cx from "classnames";

import styles from "./Header.module.scss";

export const LangSwitcher = () => {
    const { i18n } = useTranslation();

    const setRu = useCallback( () => {
        i18n.changeLanguage('ru');
    }, [] );

    const setEn = useCallback( () => {
        i18n.changeLanguage('en');
    }, [] );

    const isRu = i18n.language == 'ru';

    return (
        <div className={styles.languages}>
            <a onClick={setEn} className={
                cx(
                    styles.language,
                    styles.en,
                    {
                        [styles.active]: !isRu
                    }
                )
            }>
                EN
            </a>
            <a onClick={setRu} className={
                cx(
                    styles.language,
                    styles.ru,
                    {
                        [styles.active]: isRu
                    }
                )
            }>
                RU
            </a>
        </div>
    );
}