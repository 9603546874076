import {useTranslation} from "react-i18next";

import styles from './About.module.scss';
import place from './place.svg';
import date from './date.svg';
import back from './back.svg';

export const About = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.relative}>
            <img src={back} className={styles.back} />
            <div className={styles.block} id="about">

                <div className={styles.title}>
                    {t('about.title')}
                </div>

                <div className={styles.text}>
                    {t('about.text')}
                </div>

                <div className={styles.info}>
                    <a
                        className={styles.item}
                        href="https://yandex.ru/maps/213/moscow/?ll=37.626309%2C55.834375&mode=poi&poi%5Bpoint%5D=37.626096%2C55.834321&poi%5Buri%5D=ymapsbm1%3A%2F%2Forg%3Foid%3D1658599123&pt=37.63222222%2C55.82972222&source=entity_search&z=16"
                        target="_blank"
                    >
                        <img src={place} className={styles.icon}/>
                        <div>
                            <div className={styles.itemLine1}>
                                {t('about.place')}
                            </div>
                            <div className={styles.itemLine2}>
                                {t('about.place1')}
                            </div>
                            <div className={styles.itemLine3}>
                                {t('about.place2')}
                            </div>
                        </div>
                    </a>

                    <div className={styles.item}>
                        <img src={date} className={styles.icon}/>
                        <div>
                            <div className={styles.itemLine1}>
                                {t('about.date')}
                            </div>
                            <div className={styles.itemLine2}>
                                {t('about.date1')}
                            </div>
                            <div className={styles.itemLine3}>
                                {t('about.date2')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}