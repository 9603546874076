import cx from "classnames";
import {useTranslation} from "react-i18next";

import styles from './Topics.module.scss';

import back from './back.svg';

import i1 from './1.svg';
import i2 from './2.svg';
import i3 from './3.svg';
import i4 from './4.svg';
import i5 from './5.svg';
import i6 from './6.svg';
import i7 from './7.svg';

const images = [
    i1,
    i2,
    i3,
    i4,
    i5,
    i6,
    i7,
];

export const Topics = () => {
    const { t } = useTranslation();
    const items = t('topics.items', { returnObjects: true });

    return (
        <div className={styles.block} id="themes">
            <img src={back} className={styles.back} />

            <div className={styles.title}>
                {t('topics.title')}
            </div>

            {/*@ts-ignore*/}
            {items.map((item, i) => {
                return (
                    <div className={cx(styles.item, styles[`item${i + 1}`])} key={i}>
                        <div className={styles.header}>{item}</div>

                        <img src={images[i]} className={styles.logo} />
                    </div>
                );
            })}
        </div>
    );
}