import {FC} from "react";

import styles from "./Content.module.scss"

export const Content: FC = ({children}) => {
    return (
        <div className={styles.block}>
            {children}
        </div>
    );
}