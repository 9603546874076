import {Map} from "../../components/Map/Map";
import {Home} from "../../components/Home/Home";
import {About} from "../../components/About/About";
import {Topics} from "../../components/Topics/Topics";
import {Participants} from "../../components/Participants/Participants";
import {Schedule} from "../../components/Schedule/Schedule";
import {Video} from "../../components/Video/Video";

import styles from "./Landing.module.scss";

export const Landing = () => {
    return (
        <div className={styles.block}>
            <Home />

            <Video />

            <About />

            <Participants />

            <Topics />

            <Map />

            <Schedule />
        </div>
    );
}