import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type UserState = {
    loaded: boolean;
    admin: boolean;
    error: boolean;
    fio: string;
    id: number;
}

const initialState: UserState = {
    loaded: false,
    admin: false,
    error: false,
    fio: '',
    id: 0,
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        set: (state,  action: PayloadAction<UserState>) => {
            return action.payload;
        },
        logout: (state,  action: PayloadAction<{}>) => {
            return initialState;
        },
    },
})

// Action creators are generated for each case reducer function
export const { set, logout } = userSlice.actions

export default userSlice.reducer