import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {ProfileSerializer} from "types/api";

export type UsersState = Record<number, ProfileSerializer[]>

export type UsersStateSetPayload = {
    id: number;
    data: ProfileSerializer[];
}

const initialState: UsersState = {}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {
        set: (state,  action: PayloadAction<UsersStateSetPayload>) => {
            state[action.payload.id] = action.payload.data;

            return state;
        },
    },
})

// Action creators are generated for each case reducer function
export const { set } = usersSlice.actions

export default usersSlice.reducer