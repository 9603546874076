import {Star} from "components/Star/Star";

import styles from './Stream.module.scss';
import back from './back.svg';

export const Stream = () => {
    return (
        <div className={styles.block}>
            <div className={styles.header}>
                Онлайн-трансляции
            </div>
            <div className={styles.subtitle}>
                ключевых событий Детского культурного форума
            </div>

            <div className={styles.wrapper}>
                <Star
                    header="25-26"
                    title="августа"
                    className={styles.star}
                />

                <div className={styles.paper}>
                    Все желающие могут в режиме онлайн наблюдать за событиями в зале ключевых форматов Форума. Пожалуйста, выберите в расписании интересные для вас мероприятия и смотрите в активном окне трансляции в нужное время на этой странице.
                    <div className={styles.hint} />
                </div>
            </div>

                {/*<div className={styles.videoWrapper}>
                <iframe
                    className={styles.frame}
                    allowFullScreen
                    // @ts-ignore
                    mozallowfullscreen="true"
                    webkitallowfullscreen="true"
                    data-ss1660571720="1"
                    src="//videoplayer.streamr.ru/event/?RANDOMVALUE"
                />
            </div>*/}

            <img
                src={back}
                className={styles.back}
                alt="back"
            />
        </div>
    );
}