import {SMI} from "components/SMI/SMI";
import {NewsArticles} from "components/NewsArticles/NewsArticles";
import {NewsPhotos} from "components/NewsPhotos/NewsPhotos";
import {NewsVideos} from "components/NewsVideos/NewsVideos";

import styles from './News.module.scss';

export const News = () => {
    return (
        <div className={styles.block}>
            <NewsArticles />
            <SMI />
            <NewsPhotos />
            <NewsVideos />
        </div>
    );
};